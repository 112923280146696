import Number from '../../Number';
import styles from './Measures.module.scss';
import cn from 'classnames';
import format from 'date-fns/format';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

const Measures = ({ measures = [], year, id }) => {
  const currentYear = new Date().getFullYear();

  const data = useMemo(() => {
    const arr = [];

    for (let i = 0; i < measures.length; i++) {
      if (i % 2 === 0) {
        arr.push([]);
      }

      arr[arr.length - 1].push(measures[i]);
    }

    return arr;
  }, [measures]);

  if (measures.length < 1) {
    return null;
  }

  return (
    <>
      {data.map((block, index) => {
        const full = block[1] !== undefined;

        return (
          <div className={styles.measure} key={index}>
            <div className={cn(styles.left, !full && styles.wrong)}>
              <div className={styles.date}>{format(new Date(block[0].time), 'yyy-MM-dd')}</div>
              <div className={styles.separator} />
              <div className={styles.date}>{full ? format(new Date(block[1].time), 'yyy-MM-dd') : <span>&nbsp;</span>}</div>
            </div>
            <div className={styles.right}>
              <div className={styles.values}>
                <div className={cn(styles.value, !full && styles.wrong)}>
                  <Number value={block[0].value} decimalScale={3} /> m<sup>3</sup>
                </div>
                <div className={styles.separator}>
                  {full && (
                    <div>
                      +<Number value={block[1].value - block[0].value} decimalScale={3} />
                    </div>
                  )}
                </div>
                {full && (
                  <div className={styles.value}>
                    <Number value={block[1].value} decimalScale={3} /> m<sup>3</sup>
                  </div>
                )}
              </div>
              <div className={styles.summary}>
                {full && (
                  <div className={styles.value}>
                    <div className={styles.year}>{year}:</div>
                    <div className={cn(styles.delta, year !== currentYear && styles.old)}>
                      <Number value={block[1].value - block[0].value} decimalScale={3} /> m<sup>3</sup>
                    </div>
                  </div>
                )}
                {!full && year === currentYear && (
                  <Link to={`/watermeters/add/${id}`} className={styles.add}>
                    Dodaj odczyt
                  </Link>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Measures;
