import useAuth from '../utils/useAuth';
import styles from './Menu.module.scss';
import cn from 'classnames';
import { Salad } from 'lucide-react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

const Menu = ({ hideMenu }) => {
  const { block, account } = useAuth();

  const short = useMemo(() => {
    const split = account.name.split(' ');

    const l = Math.min(split.length, 2);
    let c = '';

    for (let i = 0; i < l; i++) {
      c += split[i][0];
    }

    return c;
  }, [account.name]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.closeWrapper} onClick={hideMenu}>
        <button className={styles.close} />
      </div>

      <div className={cn(styles.scroll, 'S')}>
        <div className={styles.user}>
          <div className={styles.userName}>
            <span>{short}</span>
          </div>

          <button className={styles.logout} onClick={() => block()}>
            Zablokuj
          </button>
        </div>

        <ul className={styles.links}>
          <li>
            <Link to="/">ROD im. 1 Maja</Link>
          </li>
          <li>
            <Link to="/users">Działkowcy</Link>
          </li>
        </ul>

        <div className={styles.title}>Komunikacja</div>

        <ul className={styles.links}>
          <li>
            <Link to="/news">Aktualności</Link>
          </li>
          <li>
            <Link to="/sms">SMS</Link>
          </li>
        </ul>

        <div className={styles.title}>Opłaty</div>

        <ul className={styles.links}>
          <li>
            <Link to="/fees/2023">Rachunki</Link>
          </li>

          <li>
            <Link to="/bank">Kasa</Link>
          </li>
        </ul>

        <div className={styles.title}>Zarządzanie</div>

        <ul className={styles.links}>
          <li>
            <Link to="/watermeters">Wodomierze</Link>
          </li>
          <li>
            <Link to="/checklists">Check-listy</Link>
          </li>
          <li>
            <Link to="/documents">Dokumenty</Link>
          </li>
          <li>
            <Link to="/files">Pliki</Link>
          </li>
        </ul>

        <div className={styles.title}>Konto użytkownika</div>

        <ul className={styles.links}>
          <li>
            <Link to="/account">Moje konto</Link>
          </li>
        </ul>

        <div className={styles.drive}>
          <a href={'https://drive.rod1maja.szczecin.pl'} target="_blank" rel="noreferrer">
            <Salad size={20} color="#1A1A1A" /> Drive
          </a>
        </div>

        <div className={styles.about}>
          <span>
            <a href="mailto: bartosz.maciurak@protonmail.com">Bartosz Maciurak</a>
          </span>
          <em>wersja 0.2.0</em>
        </div>
      </div>
    </div>
  );
};

export default Menu;
