import Page from '../../Page';
import { list } from '../../httpClient/news';
import { ButtonMenu } from '../../shared/ButtonMenu';
import styles from './List.module.scss';
import seenSvg from './assets/seen.svg';
import unseenSvg from './assets/unseen.svg';
import { format } from 'date-fns';
import { Newspaper } from 'lucide-react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

const List = () => {
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    const get = async () => {
      try {
        const response = await list();

        setDataList(response.data);
      } catch (e) {
        toast.error('Nie można pobrać danych');
      }
    };

    get();
  }, []);

  return (
    <Page title="Aktualności">
      <ButtonMenu>
        <Link to="add">
          <div>
            <Newspaper size={28} />
          </div>
          Dodaj newsa
        </Link>
      </ButtonMenu>

      <div className="rod-count">Ilość elementów: {dataList.length}</div>

      <div className={styles.list}>
        <table className="rod-table">
          <tbody>
            {dataList.map((item) => {
              return (
                <tr
                  key={item.id}
                  onClick={() => {
                    navigate(`edit/${item.id}`);
                  }}
                >
                  <td>
                    <img src={item.visible ? seenSvg : unseenSvg} className={styles.eye} alt="Czy opublikowano?" />
                  </td>
                  <td>
                    <span className={styles.date}> {format(new Date(item.created_at), 'dd.MM.yyyy')}</span>
                    <span className={styles.name}> {item.title}</span>{' '}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Page>
  );
};

export default List;
