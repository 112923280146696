import Error404 from '../Error404';
import Layout from '../Layout2';
import AccountDashboard from '../account/Dashboard';
import AccountEdit from '../account/Edit';
import AccountPassword from '../account/Password';
import AccountPin from '../account/Pin';
import Bank from '../bank';
import AccountList from '../bank/pages/accounts';
import ClientList from '../bank/pages/clients';
import HistoryList from '../bank/pages/history';
import ChecklistsAdd from '../checklists/Add';
import ChecklistsEdit from '../checklists/Edit';
import ChecklistsEntries from '../checklists/Entries';
import ChecklistsEntry from '../checklists/Entry';
import ChecklistsList from '../checklists/List';
import ChecklistsRemove from '../checklists/Remove';
import Dashboard from '../dashboard/Dashboard';
import DocumentList from '../documents/List';
import FeesList2022 from '../fees/2022/List';
import FeesList2023 from '../fees/2023/List';
import FilesAdd from '../files/Add';
import FilesList from '../files/List';
import FilesRemove from '../files/Remove';
import NewsAdd from '../news/Add';
import NewsEdit from '../news/Edit';
import NewsList from '../news/List';
import NewsRemove from '../news/Remove';
import PaymentsAdd from '../payments/Add';
import PaymentsEdit from '../payments/Edit';
import PaymentsList from '../payments/List';
import PaymentsRemove from '../payments/Remove';
import SettingsEdit from '../settings/Edit';
import SettingsList from '../settings/List';
import Sms from '../sms/Sms';
import UsersAdd from '../users/Add';
import UsersEdit from '../users/Edit';
import UsersList from '../users/List';
import UsersRemove from '../users/Remove';
import WaterMetersAdd from '../waterMeters/Add';
import WaterMetersDetails from '../waterMeters/Details';
import WaterMetersEdit from '../waterMeters/Edit';
import WaterMetersList from '../waterMeters/List';
import WaterMetersRemove from '../waterMeters/Remove';
import WaterMetersSize from '../waterMeters/Size';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Error404 />,
    children: [
      {
        path: '',
        element: <Dashboard />,
      },

      // users
      {
        path: 'users',
        element: <UsersList />,
      },
      {
        path: 'users/add',
        element: <UsersAdd />,
      },
      {
        path: 'users/remove/:id',
        element: <UsersRemove />,
      },
      {
        path: 'users/edit/:id',
        element: <UsersEdit />,
      },

      // news
      {
        path: 'news',
        element: <NewsList />,
      },
      {
        path: 'news/add',
        element: <NewsAdd />,
      },
      {
        path: 'news/remove/:id',
        element: <NewsRemove />,
      },
      {
        path: 'news/edit/:id',
        element: <NewsEdit />,
      },

      // files
      {
        path: 'files',
        element: <FilesList />,
      },
      {
        path: 'files/add',
        element: <FilesAdd />,
      },
      {
        path: 'files/remove',
        element: <FilesRemove />,
      },

      // checklists
      {
        path: 'checklists',
        element: <ChecklistsList />,
      },
      {
        path: 'checklists/add',
        element: <ChecklistsAdd />,
      },
      {
        path: 'checklists/edit/:id',
        element: <ChecklistsEdit />,
      },
      {
        path: 'checklists/remove/:id',
        element: <ChecklistsRemove />,
      },
      {
        path: 'checklists/entries/:id',
        element: <ChecklistsEntries />,
      },
      {
        path: 'checklists/entry/:id',
        element: <ChecklistsEntry />,
      },

      // fees
      {
        path: 'fees/2022',
        element: <FeesList2022 />,
      },
      {
        path: 'fees/2023',
        element: <FeesList2023 />,
      },

      // settings
      {
        path: 'settings',
        element: <SettingsList />,
      },
      {
        path: 'settings/edit',
        element: <SettingsEdit />,
      },

      // documents
      {
        path: 'documents',
        element: <DocumentList />,
      },

      // sms
      {
        path: 'sms',
        element: <Sms />,
      },

      // account
      {
        path: 'account',
        element: <AccountDashboard />,
      },
      {
        path: 'account/password',
        element: <AccountPassword />,
      },
      {
        path: 'account/pin',
        element: <AccountPin />,
      },
      {
        path: 'account/edit',
        element: <AccountEdit />,
      },

      // payments
      {
        path: 'payments',
        element: <PaymentsList />,
      },
      {
        path: 'payments/add',
        element: <PaymentsAdd />,
      },
      {
        path: 'payments/remove/:id',
        element: <PaymentsRemove />,
      },
      {
        path: 'payments/edit/:id',
        element: <PaymentsEdit />,
      },

      // watermeters
      {
        path: 'watermeters',
        element: <WaterMetersList />,
      },
      {
        path: 'watermeters/add',
        element: <WaterMetersAdd />,
      },
      {
        path: 'watermeters/add/:id',
        element: <WaterMetersAdd />,
      },
      {
        path: 'watermeters/details/:gardenId',
        element: <WaterMetersDetails />,
      },
      {
        path: 'watermeters/remove/:id',
        element: <WaterMetersRemove />,
      },
      {
        path: 'watermeters/size/:id',
        element: <WaterMetersSize />,
      },
      {
        path: 'watermeters/edit/:parent/:id',
        element: <WaterMetersEdit />,
      },

      // bank
      {
        path: 'bank',
        element: <Bank />,
        children: [
          {
            path: '',
            element: <HistoryList />,
            handle: {
              key: 'history',
            },
          },
          {
            path: 'history',
            element: <HistoryList />,
            handle: {
              key: 'history',
            },
          },
          {
            path: 'accounts',
            element: <AccountList />,
            handle: {
              key: 'accounts',
            },
          },
          {
            path: 'clients',
            element: <ClientList />,
            handle: {
              key: 'clients',
            },
          },
        ],
      },
    ],
  },
]);

const MyRouter = () => {
  return <RouterProvider router={router} />;
};

export default MyRouter;
