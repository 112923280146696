import Page from '../../Page';
import { changePassword } from '../../httpClient/account';
import { NOT_RE_PASSWORD, REQUIRED, TOO_SHORT } from '../../utils/formTexts';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import 'yup-phone';

const schema = yup
  .object({
    password: yup.string().min(3, TOO_SHORT).required(REQUIRED),
    newPassword: yup.string().min(8, TOO_SHORT).required(REQUIRED),
    rePassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], NOT_RE_PASSWORD)
      .required(REQUIRED),
  })
  .required();

const Edit = () => {
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      await changePassword(data);

      toast.success('Dane zostały zapisane');
    } catch (e) {
      toast.error('Nie udało się');
    }
  };

  return (
    <Page title={`Zmiana hasła`} back="/account" breadcrumbs={[['/account', 'Moje konto']]}>
      <div className="rod-form">
        <div className="rod-form-content">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="rod-form-row">
              <label>Aktualne hasło *</label>
              <input type="password" {...register('password')} />
              {errors.password && <div className="rod-form-error">{errors.password?.message}</div>}
            </div>
            <div className="rod-form-row">
              <label>Nowe hasło *</label>
              <input type="password" {...register('newPassword')} />
              {errors.newPassword && <div className="rod-form-error">{errors.newPassword?.message}</div>}
            </div>
            <div className="rod-form-row">
              <label>Powtórz nowe hasło *</label>
              <input type="password" {...register('rePassword')} />
              {errors.rePassword && <div className="rod-form-error">{errors.rePassword?.message}</div>}
            </div>

            <div className="rod-form-legend">
              <ul>
                <li>* - pole wymagane</li>
              </ul>
            </div>

            <div className="rod-form-submit">
              <input type="submit" className="button-primary" value="Zapisz" />
            </div>
          </form>
        </div>
      </div>
    </Page>
  );
};

export default Edit;
