import { send } from '../../../httpClient/mails';
import { details } from '../../../httpClient/users';
import styles from './SendByEmail.module.scss';
import { pdf } from '@react-pdf/renderer';
import cn from 'classnames';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

const cutBase64 = (txt) => {
  const index = txt.indexOf(',');

  return txt.substring(index + 1);
};

const chooseCode = () => {
  const a = Math.floor(Math.random() * 10);
  const b = Math.floor(Math.random() * 10);

  return [a, b];
};

const Modal = ({ onClose, email, onSend }) => {
  const ref = useRef();
  const [code, setCode] = useState('');
  const confirmCode = useMemo(() => chooseCode(), []);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const letters = code.split('');

  const tryDone = () => {
    if (code.toString() === confirmCode.join('')) {
      onClose();
      onSend();
    }
  };

  return (
    <div className="rod-modal">
      <div className="rod-modal-container">
        <div className={styles.confirm}>
          Aby wysłać mail do <b>{email}</b> kliknij wskazane cyfry ?
        </div>

        <div className={styles.values}>
          <div>
            {confirmCode.map((v, index) => (
              <span
                className={cn(
                  v.toString() === letters[index]?.toString() && styles.selected,
                  letters.length > index && v.toString() !== letters[index].toString() && styles.error
                )}
                key={index}
              >
                {v}
              </span>
            ))}
          </div>

          <input ref={ref} type="text" inputMode="numeric" maxLength="2" value={code} onChange={(e) => setCode(e.target.value)} />
        </div>

        <div className={styles.buttons}>
          <span onClick={tryDone} className="button-primary">
            Zatwierdź
          </span>
          <span onClick={onClose} className="button-secondary">
            Anuluj
          </span>
        </div>
      </div>
    </div>
  );
};

const SendByEmail = ({ emails, document, garden, names }) => {
  const [block, setBlock] = useState([false, false]);
  const [confirm, setConfirm] = useState();

  const sendTo = async (index) => {
    const blob = await pdf(document).toBlob();
    const txt = await blobToBase64(blob);
    const base64 = cutBase64(txt);

    try {
      await send(emails[index], names[index], garden, base64);

      toast.success(`Mail do ${emails[index]} wysłany.`);

      setBlock((b) => {
        const x = [...b];
        x[index] = true;

        return x;
      });
    } catch (e) {
      toast.error('Nie udało się wysłać');
    }
  };

  return (
    <>
      <div className={styles.emails}>
        {Boolean(emails[0]) && !block[0] && (
          <span className="button-primary rod-modal-button" onClick={() => setConfirm(1)}>
            Wyślij na {emails[0]}
          </span>
        )}
        {Boolean(emails[1]) && !block[1] && (
          <span className="button-primary rod-modal-button" onClick={() => setConfirm(2)}>
            Wyślij na {emails[1]}
          </span>
        )}
      </div>

      {confirm && (
        <Modal
          email={emails[confirm - 1]}
          onClose={() => setConfirm()}
          onSend={() => {
            sendTo(confirm - 1);
          }}
        />
      )}
    </>
  );
};

export default SendByEmail;
