const lumpUserPrice = 1444.39;
const garbagePrice = 18.57;
const waterInit = 0.1;

function roundToTwo(num) {
  return +(Math.round(num + 'e+2') + 'e-2');
}

export const calculatePricesForMedia = (settings, usage, data, summary) => {
  let part, total;

  if (!data.waterMeter) {
    part = {
      waterFee: roundToTwo(lumpUserPrice * (data.area / summary.ryczaltArea)),
      lumpParticipation: data.area / summary.ryczaltArea,
      lumpSum: lumpUserPrice,
      garbage: garbagePrice,
    };

    total = part.garbage + part.waterFee;
  } else {
    part = {
      garbage: garbagePrice,
      waterUsage: roundToTwo(settings.waterPriceForWatermeter * usage.usage),
      participation: 1 / 78,
      waterAbonamentFull: 62.33,
      waterAbonament: 0.8,
      waterNetwork: roundToTwo(settings.waterPriceForWatermeter * waterInit),
    };

    total = part.garbage + part.waterAbonament + part.waterUsage + part.waterNetwork;
  }

  total = total.toFixed(2);
  total = Number(total);

  return {
    ...part,
    total,
  };
};
