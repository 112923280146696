import Number from '../../../Number';
import styles from './Summary.module.scss';
import chevronUrl from './assets/chevron.svg';
import cn from 'classnames';
import { useMemo, useState } from 'react';

const Summary = ({ sums, usages, payments, mediaPayments, users }) => {
  const [open, setOpen] = useState(false);

  const howManyPayments = useMemo(() => {
    return users.reduce((accumulator, currentValue) => {
      if (payments[currentValue.id] > 0) {
        return accumulator + 1;
      }

      return accumulator;
    }, 0);
  }, [payments, users]);

  const howManyMediaPayments = useMemo(() => {
    return users.reduce((accumulator, currentValue) => {
      if (mediaPayments[currentValue.id] > 0) {
        return accumulator + 1;
      }

      return accumulator;
    }, 0);
  }, [mediaPayments, users]);

  return (
    <div className={styles.container}>
      <button
        className={cn(styles.button, open && styles.open)}
        onClick={() => {
          setOpen((o) => !o);
        }}
      >
        <div>Zestawienie</div>
        <div>
          <img src={chevronUrl} alt="Open" />
        </div>
      </button>

      <div className={cn(styles.uls, open && styles.open)}>
        <ul>
          <li>
            <em>Powierzchnia całkowita:</em>
            <Number value={sums.area} decimalScale={0} type="m2" />
          </li>
          <li>
            <em>Całkowita opłata działkowa:</em>
            <Number value={sums.total} type="pln" />
          </li>

          <li>
            <em>Opłata za media:</em>
            <Number value={sums.totalWater} type="pln" />
          </li>

          <li>
            <em>Zużycie wody (suma liczników):</em>
            <Number value={usages.sumUsage} type="m3" />
          </li>

          <li>
            <em>Powierzchnia ryczałtowców:</em>
            <Number value={usages.ryczaltArea} type="m2" />
          </li>

          <li>
            <em>Ilość opłat działkowych:</em>
            <Number value={howManyPayments} decimalScale={0} />/ {users.length}
            <strong>
              (<Number value={(howManyPayments / users.length) * 100} />
              %)
            </strong>
          </li>

          <li>
            <em>Ilość płatności za media:</em>
            <Number value={howManyMediaPayments} decimalScale={0} />/ {users.length}
            <strong>
              (<Number value={(howManyMediaPayments / users.length) * 100} />
              %)
            </strong>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Summary;
