const areas = [
  { id: 1, path: '1461.00,2560.00,1440.50,2428.00,1548.50,2417.00,1579.50,2687.00,1535.50,2696.00,1522.00,2632.50,1461.00,2560.00' },
  { id: 2, path: '1235.00,1940.50,1216.50,1803.50,1284.50,1798.00,1300.50,1916.00,1284.50,1937.00,1235.00,1940.50' },
  { id: 3, path: '1419.50,2299.00,1436.50,2406.50,1547.50,2388.50,1533.50,2278.50,1419.50,2299.00' },
  { id: 4, path: '1417.00,2275.50,1398.50,2164.50,1516.50,2143.50,1528.50,2258.50,1417.00,2275.50' },
  { id: 5, path: '1397.50,2141.00,1375.00,2028.00,1494.00,2012.50,1510.50,2123.50,1397.50,2141.00' },
  { id: 6, path: '1374.50,2007.50,1361.00,1910.00,1477.50,1895.50,1493.00,1987.50,1374.50,2007.50' },
  { id: 7, path: '1357.00,1886.50,1340.00,1787.00,1461.50,1774.50,1474.00,1875.00,1357.00,1886.50' },
  { id: 8, path: '1336.00,1760.00,1317.00,1640.00,1442.00,1629.50,1456.00,1753.00,1336.00,1760.00' },
  { id: 9, path: '1310.50,1618.00,1292.00,1476.50,1420.00,1462.00,1440.50,1603.50,1310.50,1618.00' },
  { id: 10, path: '1289.50,1455.00,1276.50,1343.50,1397.50,1317.50,1409.50,1437.00,1289.50,1455.00' },
  { id: 11, path: '1123.50,1297.00,1148.00,1421.50,1224.00,1409.00,1198.00,1285.50,1123.50,1297.00' },
  { id: 12, path: '1250.00,1229.50,1264.50,1311.50,1392.50,1293.50,1381.00,1207.00,1250.00,1229.50' },
  { id: 13, path: '1097.50,1146.50,1119.50,1276.00,1196.00,1262.50,1172.00,1135.50,1097.50,1146.50' },
  { id: 14, path: '1227.50,1128.50,1243.50,1208.50,1377.50,1183.00,1367.50,1112.00,1227.50,1128.50' },
  { id: 15, path: '1142.00,980.50,1167.50,1113.50,1093.50,1126.00,1068.50,994.50,1142.00,980.50' },
  { id: 16, path: '1208.50,1029.00,1224.50,1108.50,1364.50,1089.50,1351.50,1004.00,1208.50,1029.00' },
  { id: 17, path: '1033.00,847.50,1067.00,974.00,1141.50,957.50,1111.50,822.00,1033.00,847.50' },
  { id: 18, path: '1188.50,935.50,1203.00,1011.00,1350.00,979.00,1339.50,908.00,1188.50,935.50' },
  { id: 19, path: '1049.00,666.50,985.50,700.50,1027.50,827.50,1113.00,803.50,1049.00,666.50' },
  { id: 20, path: '1165.00,826.00,1186.00,911.50,1330.00,885.00,1294.00,789.00,1165.00,826.00' },
  {
    id: 21,
    path: '1255.50,632.50,1338.00,543.50,1362.50,556.50,1249.00,675.00,1285.50,769.00,1156.50,800.50,1128.00,706.00,1255.50,632.50',
  },
  { id: 22, path: '1246.00,475.00,1070.00,604.50,1114.50,683.50,1241.50,615.00,1325.00,531.50,1246.00,475.00' },
  { id: 23, path: '882.50,568.50,919.00,528.00,1037.50,649.50,972.50,684.00,882.50,568.50' },
  { id: 24, path: '1001.50,531.00,1143.00,404.00,1228.00,462.00,1053.50,588.00,1001.50,531.00' },
  { id: 25, path: '1073.50,358.50,939.00,482.00,985.50,516.50,1124.00,389.50,1073.50,358.50' },
  { id: 26, path: '964.00,312.50,881.00,444.50,925.50,469.50,1058.50,345.00,964.00,312.50' },
  { id: 27, path: '876.50,283.50,817.00,400.00,863.00,432.50,942.50,304.00,876.50,283.50' },
  { id: 28, path: '704.00,396.50,779.50,244.50,858.00,272.00,797.00,389.00,761.00,367.00,704.00,396.50' },
  { id: 29, path: '696.00,215.50,618.00,360.00,686.00,393.50,764.50,238.50,696.00,215.50' },
  { id: 30, path: '620.00,188.50,543.50,319.00,601.50,350.00,681.00,208.50,620.00,188.50' },
  { id: 31, path: '529.00,156.00,474.00,281.00,529.00,311.00,602.50,179.00,529.00,156.00' },
  { id: 32, path: '440.00,123.50,385.50,234.50,457.00,273.00,511.00,149.00,440.00,123.50' },
  { id: 33, path: '272.50,66.00,229.00,157.00,370.00,224.50,420.00,118.50,272.50,66.00' },
  { id: 34, path: '79.50,100.50,123.50,12.50,255.00,59.00,211.50,145.50,135.00,109.00,123.50,124.50,79.50,100.50' },
  { id: 35, path: '16.50,207.50,59.00,116.50,182.50,181.00,123.50,278.00,16.50,207.50' },
  { id: 36, path: '204.00,187.00,138.50,291.00,207.50,332.00,263.50,220.50,204.00,187.00' },
  { id: 37, path: '281.00,230.50,223.50,343.00,299.00,387.00,354.00,269.50,281.00,230.50' },
  { id: 38, path: '368.00,279.00,316.00,398.00,388.00,446.50,449.50,319.50,368.00,279.00' },
  { id: 39, path: '403.00,455.50,462.50,329.50,540.50,370.00,467.00,512.50,403.00,455.50' },
  { id: 40, path: '476.00,522.50,557.50,378.50,636.50,424.00,529.00,569.50,476.00,522.50' },
  { id: 41, path: '650.00,434.00,543.50,581.00,610.50,636.50,701.00,548.50,663.50,498.00,650.00,434.00' },
  { id: 42, path: '814.00,617.00,902.00,513.50,781.50,433.50,734.50,449.00,724.00,496.00,814.00,617.00' },
  { id: 43, path: '715.50,571.00,628.50,655.50,663.00,731.50,779.50,649.00,715.50,571.00' },
  { id: 44, path: '826.50,631.50,870.00,583.00,979.50,739.00,903.00,773.00,826.50,631.50' },
  { id: 45, path: '791.00,670.00,673.00,751.00,705.00,820.00,841.00,759.00,791.00,670.00' },
  { id: 46, path: '910.00,790.50,932.50,894.00,1020.50,871.50,987.00,760.50,910.00,790.50' },
  { id: 47, path: '715.00,839.00,731.50,922.00,884.50,887.50,853.00,778.00,715.00,839.00' },
  { id: 48, path: '939.00,913.00,954.50,993.50,1045.50,977.50,1023.50,893.50,939.00,913.00' },
  { id: 49, path: '734.00,941.50,891.00,906.50,905.00,1010.00,754.00,1042.50,734.00,941.50' },
  { id: 50, path: '959.50,1013.50,975.00,1094.00,1066.00,1075.00,1049.50,996.00,959.50,1013.50' },
  { id: 51, path: '763.50,1062.50,777.00,1141.50,926.00,1119.00,908.50,1029.00,763.50,1062.50' },
  { id: 52, path: '977.00,1113.50,998.50,1226.00,1085.50,1211.50,1065.00,1098.00,977.00,1113.50' },
  { id: 53, path: '930.00,1141.50,947.50,1226.00,800.50,1254.50,783.50,1165.50,930.00,1141.50' },
  { id: 54, path: '1383.00,2387.50,1359.50,2262.50,1274.00,2276.00,1293.50,2399.00,1383.00,2387.50' },
  { id: 55, path: '950.00,1248.50,967.50,1335.00,819.50,1357.50,807.50,1277.00,950.00,1248.50' },
  { id: 56, path: '969.00,1357.50,987.00,1439.50,841.00,1459.50,826.50,1381.50,969.00,1357.50' },
  { id: 57, path: '879.00,1623.50,848.00,1481.50,985.50,1462.00,1011.50,1605.50,879.00,1623.50' },
  { id: 58, path: '900.00,1767.00,884.00,1653.00,1022.50,1625.00,1039.00,1743.50,900.00,1767.00' },
  { id: 59, path: '1063.00,1746.00,1049.50,1650.50,1165.00,1632.00,1181.50,1724.50,1063.00,1746.00' },
  { id: 60, path: '1060.50,1546.50,1034.00,1406.50,1118.50,1394.50,1151.50,1580.00,1094.50,1589.50,1060.50,1546.50' },
  { id: 61, path: '1212.50,1781.50,1188.50,1627.50,1262.00,1621.50,1281.50,1769.00,1212.50,1781.50' },
  { id: 62, path: '1255.00,1558.50,1228.00,1432.50,1153.00,1445.50,1180.00,1576.00,1255.00,1558.50' },
  { id: 63, path: '1167.50,1948.50,1137.50,1755.00,1184.00,1749.50,1211.50,1942.50,1167.50,1948.50' },
  { id: 64, path: '1196.00,2079.50,1182.00,1993.00,1313.00,1977.00,1328.00,2057.00,1196.00,2079.50' },
  { id: 65, path: '1101.50,1957.00,1071.50,1767.00,1112.50,1757.00,1143.00,1951.50,1101.50,1957.00' },
  { id: 66, path: '1084.50,2100.00,1070.00,2008.50,1164.50,1995.00,1178.00,2083.50,1084.50,2100.00' },
  { id: 67, path: '1026.00,1960.00,999.50,1774.00,1049.50,1766.50,1076.00,1955.00,1026.00,1960.00' },
  { id: 68, path: '949.00,2125.50,933.00,2018.00,1047.00,2008.00,1063.50,2104.50,949.00,2125.50' },
  { id: 69, path: '929.50,1992.50,896.50,1793.00,978.50,1780.50,1000.50,1964.00,967.00,1969.50,960.00,1992.50,929.50,1992.50' },
  { id: 70, path: '1350.50,2210.00,1331.50,2075.50,1241.50,2093.50,1264.00,2228.50,1350.50,2210.00' },
  { id: 71, path: '1131.00,2251.00,1110.50,2116.00,1221.50,2094.50,1246.50,2231.50,1131.00,2251.00' },
  { id: 72, path: '974.50,2302.00,952.50,2143.00,1085.00,2119.00,1112.00,2281.50,974.50,2302.00' },
  { id: 73, path: '994.00,2446.50,977.50,2323.50,1111.00,2305.00,1132.50,2428.50,994.00,2446.50' },
  { id: 74, path: '1184.00,2417.00,1170.00,2295.00,1249.50,2279.00,1270.00,2403.00,1184.00,2417.00' },
  { id: 75, path: '1019.50,2595.00,1001.00,2468.50,1137.00,2452.00,1155.00,2573.00,1019.50,2595.00' },
  { id: 76, path: '1214.50,2563.50,1189.50,2439.00,1274.50,2426.50,1296.00,2552.00,1214.50,2563.50' },
  { id: 77, path: '1069.00,2767.50,1021.50,2620.50,1158.00,2595.00,1162.00,2610.50,1239.00,2615.00,1243.50,2737.50,1069.00,2767.50' },
  { id: 78, path: '1404.50,2536.00,1386.00,2407.50,1298.00,2423.50,1320.50,2550.50,1404.50,2536.00' },
];

export default areas;
