import { checkDeviceToken, checkPin, checkToken, login } from '../httpClient/account';
import { addLogoutCallback, addToken } from '../httpClient/instance';
import { LoggedAccount, Stage } from '../store/account';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

class Auth {
  stage = 0;
  initialized = false;

  constructor() {
    this.checkPin = this.checkPin.bind(this);
    this.updateAccountInfo = this.updateAccountInfo.bind(this);
    this.block = this.block.bind(this);
    this.logout = this.logout.bind(this);
    this.updateStageInfo = this.updateStageInfo.bind(this);

    const searchParams = new URLSearchParams(window.location.search);
    const authToken = searchParams.get('authToken');

    this.searchParamAuthToken = authToken;

    if (authToken) {
      const url = window.location;
      const urlObj = new URL(url);

      window.history.replaceState({}, '', urlObj.pathname);
    }
  }

  initialize(setStage, setAccount) {
    this.setStage = setStage;
    this.setAccount = setAccount;

    if (!this.initialized) {
      this.initialized = true;

      this.checkDeviceToken();
    }
  }

  async checkDeviceToken() {
    const token = localStorage.getItem('deviceToken');

    if (token) {
      try {
        const response = await checkDeviceToken(token);

        if (response.status === 200) {
          this.deviceToken = token;

          this.checkAuthToken();

          return;
        }
      } catch (e) {}
    }

    const authToken = this.searchParamAuthToken;

    if (authToken) {
      try {
        const resp = await login(authToken);

        const deviceToken = resp.data.token;

        localStorage.setItem('deviceToken', deviceToken);

        this.deviceToken = deviceToken;

        this.checkAuthToken();

        return;
      } catch (e) {}
    }

    this.stage = -1;

    this.updateStageInfo();
  }

  async checkAuthToken() {
    const token = localStorage.getItem('authToken');

    if (token) {
      try {
        const response = await checkToken(token);

        if (response.data) {
          addToken(token);
          addLogoutCallback(() => this.block());

          this.account = response.data;

          this.stage = 2;

          this.updateStageInfo();
          this.updateAccountInfo();

          return;
        }
      } catch (e) {}
    }

    this.stage = 1;

    this.updateStageInfo();
  }

  async checkPin(pin) {
    try {
      const response = await checkPin(this.deviceToken, pin);
      const { token } = response.data;

      if (token) {
        localStorage.setItem('authToken', token);

        this.checkAuthToken();

        return true;
      }
    } catch (e) {}

    return false;
  }

  block() {
    localStorage.removeItem('authToken');
    this.account = undefined;
    this.stage = 1;

    this.updateAccountInfo();
    this.checkAuthToken();
  }

  logout() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('deviceToken');

    this.account = undefined;
    this.stage = 3;

    this.updateAccountInfo();
    this.updateStageInfo();
  }

  updateStageInfo() {
    this.setStage(this.stage);
  }

  updateAccountInfo() {
    this.setAccount(this.account);
  }
}

const authInstance = new Auth();

const useAuth = () => {
  const [account, setAccount] = useRecoilState(LoggedAccount);
  const [stage, setStage] = useRecoilState(Stage);

  useEffect(() => {
    authInstance.initialize(setStage, setAccount);
  }, []);

  return {
    block: authInstance.block,
    logout: authInstance.logout,
    stage: stage,
    checkPin: authInstance.checkPin,
    account,
  };
};

export default useAuth;
