import styles from './Error404.module.scss';
import { Link } from 'react-router-dom';

const Error404 = () => {
  return (
    <div className={styles.container}>
      <div className={styles.number}>404</div>
      <div>
        <Link to={'/'}>Powrót na stronę główną</Link>
      </div>
    </div>
  );
};

export default Error404;
