import Page from '../../Page';
import Removing from '../../Removing';
import { remove } from '../../httpClient/waterMeters';
import { useParams } from 'react-router-dom';

const Remove = () => {
  const { id } = useParams();

  return (
    <Page back="/watermeters" breadcrumbs={[['/watermeters', 'Wodomierze']]} title={`Usuwanie odczytu wodomierza #${id}`}>
      <Removing title="Czy na pewno chcesz usunąć odczyt?" action={() => remove(id)} />
    </Page>
  );
};

export default Remove;
