import { add, details, edit } from '../../../httpClient/clients';
import FixedNumberInput from '../../../utils/FixedNumberInput';
import { NOT_DATE, NOT_INTEGER_NUMBER, NOT_NUMBER, NOT_POSITIVE_NUMBER, REQUIRED } from '../../../utils/formTexts';
import replaceNullByUndefined from '../../../utils/replaceNullByUndefined';
import arrowSvg from '../../assets/arrow.svg';
import closeSvg from '../../assets/close.svg';
import gardenSvg from '../../assets/garden.svg';
import innerSvg from '../../assets/inner.svg';
import invoiceSvg from '../../assets/invoice.svg';
import transferSvg from '../../assets/transfer.svg';
import historyStyles from './History.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useOutletContext } from 'react-router-dom';
import * as yup from 'yup';

const TIME_FORMAT = 'yyyy-MM-dd';

const users = [1, 2, 3, 4, 5, 6, 7, 8];

const types = [
  {
    icon: innerSvg,
    name: 'Oper. wewn.',
  },
  {
    icon: transferSvg,
    name: 'Transfer',
  },
  {
    icon: gardenSvg,
    name: 'Działkowcy',
  },
  {
    icon: invoiceSvg,
    name: 'Kontrahenci',
  },
];

const dates = [11, 12, 13, 14, 15];

const refNr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

const schema = yup
  .object({
    time: yup.date().typeError(NOT_DATE).required(REQUIRED),
    transactionInDay: yup.number().typeError(NOT_NUMBER).integer(NOT_INTEGER_NUMBER).positive(NOT_POSITIVE_NUMBER),
    refNr: yup.string().required(REQUIRED),
    value: yup.number().typeError(NOT_NUMBER).positive(NOT_POSITIVE_NUMBER).required(REQUIRED),
    inOut: yup.number().typeError(NOT_NUMBER).integer(NOT_INTEGER_NUMBER).required(REQUIRED),
    myAccountId: yup.number().typeError(NOT_NUMBER).integer(NOT_INTEGER_NUMBER).required(REQUIRED),
    innerAccountId: yup.number().typeError(NOT_NUMBER).integer(NOT_INTEGER_NUMBER).required(REQUIRED),
    otherAccountType: yup.number().typeError(NOT_NUMBER).integer(NOT_INTEGER_NUMBER).required(REQUIRED),
    otherAccountId: yup.number().typeError(NOT_NUMBER).integer(NOT_INTEGER_NUMBER).required(REQUIRED),
    description: yup.string(),
  })
  .required();

const Form = ({ isEdit = false, defaultValues = undefined, onDone }) => {
  const { bankAccountsList } = useOutletContext();
  const [code, setCode] = useState('');
  const nrRef = useRef();
  const [showCalendar, setShowCalendar] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    try {
      if (isEdit) {
        await edit(data.id, data);

        toast.success('Dane zostały zapisane');
      } else {
        await add(data);

        toast.success('Transakcja dodana');
      }

      onDone();
    } catch (e) {}
  };

  const addDescription = watch('addDescription');

  return (
    <div className="rod-form">
      <div className="rod-form-content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={historyStyles.menu}>
            <div className={historyStyles.actions}>
              <Controller
                control={control}
                name="myAccountId"
                defaultValue={bankAccountsList?.data?.[0].id}
                render={({ field }) => {
                  return (
                    <>
                      {(bankAccountsList.data || []).map((ba) => {
                        return (
                          <div
                            key={ba.id}
                            className={cn(historyStyles.account, ba.id === field.value && historyStyles.selected)}
                            onClick={() => field.onChange(ba.id)}
                          >
                            <div className={historyStyles.number}>{ba.number}</div>
                            <div className={historyStyles.name}>{ba.name}</div>
                          </div>
                        );
                      })}
                    </>
                  );
                }}
              />
            </div>
          </div>

          <div className={historyStyles.value}>
            <Controller
              control={control}
              name="value"
              render={({ field }) => <FixedNumberInput placeholder="0.00" field={field} fractionDigits={2} intDigits={6} />}
            />

            {errors.value && <div className="rod-form-error">{errors.value?.message}</div>}
          </div>

          <Controller
            control={control}
            name="inOut"
            defaultValue={1}
            render={({ field }) => {
              return (
                <div className={historyStyles.inOut}>
                  <div className={cn(historyStyles.item, field.value === 1 && historyStyles.selected)} onClick={() => field.onChange(1)}>
                    <div className={historyStyles.icon}>
                      <img src={arrowSvg} alt="" />
                    </div>
                    <div className={historyStyles.text}>wpływ</div>
                  </div>
                  <div className={cn(historyStyles.item, field.value === -1 && historyStyles.selected)} onClick={() => field.onChange(-1)}>
                    <div className={cn(historyStyles.icon, historyStyles.out)}>
                      <img src={arrowSvg} alt="" />
                    </div>
                    <div className={historyStyles.text}>wydatek</div>
                  </div>
                </div>
              );
            }}
          />

          <div className={historyStyles.types}>
            <Controller
              control={control}
              name="otherAccountType"
              defaultValue={0}
              render={({ field }) => {
                return (
                  <div className={historyStyles.left}>
                    {types.map((t, index) => (
                      <div
                        className={historyStyles.type}
                        onClick={() => {
                          field.onChange(index);
                        }}
                      >
                        <div className={historyStyles.icon}>
                          <img src={t.icon} alt={t.name} />
                        </div>
                        <div className={historyStyles.name}>{t.name}</div>
                      </div>
                    ))}

                    <div
                      className={historyStyles.indicator}
                      style={{
                        left: `${field.value * 25}%`,
                      }}
                    />
                  </div>
                );
              }}
            />

            <div className={historyStyles.right}>
              <div className={historyStyles.search} />

              <div className={historyStyles.users}>
                {users.map((u) => (
                  <div className={historyStyles.user}>
                    <div className={historyStyles.data}>
                      <div className={historyStyles.avatar}>
                        <span>AS</span>
                      </div>
                      <div className={historyStyles.info}>
                        <span>Joanna Smolarek</span>
                        <em>Działka nr 45</em>
                      </div>
                    </div>
                    <div className={historyStyles.checkbox} />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={historyStyles.detailsHeader}>Szczegóły transakcji</div>
          <div className={historyStyles.details}>
            <div className={historyStyles.ref}>
              <div className={historyStyles.title}>Numer referencyjny</div>

              <div className={historyStyles.inner}>
                <div className={historyStyles.list}>
                  {refNr.map((nr, index) => (
                    <div>{code[index]}</div>
                  ))}

                  <input
                    ref={nrRef}
                    type="text"
                    maxLength={16}
                    onChange={(e) => {
                      setCode(e.target.value.replace(/[^a-zA-Z0-9]/g, ''));
                    }}
                    spellCheck={false}
                  />
                </div>

                <img
                  className={historyStyles.close}
                  src={closeSvg}
                  onClick={() => {
                    setCode('');

                    nrRef.current.value = '';
                    nrRef.current.focus();
                  }}
                />
              </div>
            </div>

            <div className={historyStyles.dates}>
              <div className={historyStyles.list}>
                {dates.map((d) => (
                  <div className={cn(historyStyles.date, d === 13 && historyStyles.selected)}>
                    <div className={historyStyles.year}>WRZ 2023</div>
                    <div className={historyStyles.day}>{d}</div>
                  </div>
                ))}
              </div>

              {!showCalendar && (
                <div className={historyStyles.button}>
                  <span onClick={() => setShowCalendar(true)}>Pokaż więcej dat</span>
                </div>
              )}

              {showCalendar && (
                <div className={historyStyles.calendar}>
                  <Calendar
                    locale="pl-PL"
                    showFixedNumberOfWeeks={true}
                    onChange={() => {
                      setShowCalendar(false);
                    }}
                  />
                </div>
              )}
            </div>

            <div className={historyStyles.description}>
              <div className="rod-form-row-checkbox-line">
                <div className="rod-form-checkbox-wrapper">
                  <input type="checkbox" className="ikxBAC" {...register('addDescription')} />
                </div>
                <span>Opis transakcji</span>
              </div>

              {addDescription && (
                <div className={historyStyles.checkbox}>
                  <div className="rod-form-row">
                    <input type="text" {...register('description')} />
                    {errors.description && <div className="rod-form-error">{errors.description?.message}</div>}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="rod-form-submit">
            <input type="submit" className="button-primary" value="Zapisz" />
          </div>
        </form>
      </div>
    </div>
  );
};

const AddOrEdit = ({ id, onClose, refresh }) => {
  const [ready, setReady] = useState(false);
  const [defaultValues, setDefaultValues] = useState();

  useEffect(() => {
    const getData = async () => {
      if (id) {
        try {
          const response = await details(id);

          setDefaultValues(
            replaceNullByUndefined({
              ...response.data,
            })
          );

          setReady(true);
        } catch (e) {}
      }
    };

    if (id) {
      getData();
    } else {
      setReady(true);
    }
  }, [id]);

  if (!ready) {
    return null;
  }

  return (
    <Form
      isEdit={Boolean(id)}
      defaultValues={defaultValues}
      onDone={() => {
        refresh();
        onClose();
      }}
    />
  );
};

export default AddOrEdit;
