import { useEffect } from 'react';

const PRODUCTION_URL = 'https://login.rod1maja.szczecin.pl?portal=admin';
const DEVELOPMENT_URL = 'http://localhost:5174?portal=admin&callbackUrl=http://localhost:3000';

const Redirect = () => {
  useEffect(() => {
    window.location.href = process.env.NODE_ENV === 'production' ? PRODUCTION_URL : DEVELOPMENT_URL;
  }, []);

  return null;
};

export default Redirect;
