import Page from '../../Page';
import Removing from '../../Removing';
import { remove } from '../../httpClient/users';
import { useParams } from 'react-router-dom';

const Remove = () => {
  const { id } = useParams();

  return (
    <Page back="/users" breadcrumbs={[['/users', 'Działkowcy']]} title="Usuwanie działkowca">
      <Removing url="/users" title="Czy na pewno chcesz usunąć działkowca?" action={() => remove(id)} />
    </Page>
  );
};

export default Remove;
