import { add, details, edit } from '../../../httpClient/clients';
import { REQUIRED } from '../../../utils/formTexts';
import replaceNullByUndefined from '../../../utils/replaceNullByUndefined';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';

const schema = yup
  .object({
    name: yup.string().required(REQUIRED),
  })
  .required();

const Form = ({ isEdit = false, defaultValues = undefined, onDone }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    try {
      if (isEdit) {
        await edit(data.id, data);

        toast.success('Dane zostały zapisane');
      } else {
        await add(data);

        toast.success('Kontrahent dodany');
      }

      onDone();
    } catch (e) {}
  };

  return (
    <div className="rod-form">
      <div className="rod-form-content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="rod-form-row">
            <label>Nazwa *</label>
            <input type="text" {...register('name')} />
            {errors.name && <div className="rod-form-error">{errors.name?.message}</div>}
          </div>

          <div className="rod-form-legend">
            <ul>
              <li>* - pole wymagane</li>
            </ul>
          </div>

          <div className="rod-form-submit">
            <input type="submit" className="button-primary" value={isEdit ? 'Edytuj' : 'Dodaj'} />
          </div>
        </form>
      </div>
    </div>
  );
};

const AddOrEdit = ({ id, onClose, refresh }) => {
  const [ready, setReady] = useState(false);
  const [defaultValues, setDefaultValues] = useState();

  useEffect(() => {
    const getData = async () => {
      if (id) {
        try {
          const response = await details(id);

          setDefaultValues(
            replaceNullByUndefined({
              ...response.data,
            })
          );

          setReady(true);
        } catch (e) {}
      }
    };

    if (id) {
      getData();
    } else {
      setReady(true);
    }
  }, [id]);

  if (!ready) {
    return null;
  }

  return (
    <Form
      isEdit={Boolean(id)}
      defaultValues={defaultValues}
      onDone={() => {
        refresh();
        onClose();
      }}
    />
  );
};

export default AddOrEdit;
