import styles from './Removing.module.scss';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';

const Removing = ({ url, title, action }) => {
  const navigate = useNavigate();

  const confirm = async () => {
    try {
      await action();

      toast.success('Usunięto pomyślnie');

      if (url) {
        navigate(url);
      } else {
        navigate(-1);
      }
    } catch (e) {
      toast.error('Coś poszło nie tak');
    }
  };

  const abort = () => {
    navigate(-1);
  };

  return (
    <div className={styles.removing}>
      <div className={styles.title}>{title || 'Czy potwierdzasz akcję?'}</div>
      <div className={styles.buttons}>
        <button onClick={confirm}>Tak</button>
        <button onClick={abort}>Nie</button>
      </div>
    </div>
  );
};

export default Removing;
