import { withMobilePhone } from '../../httpClient/users';
import makeNames from '../../utils/makeNames';
import removeDiacritics from '../../utils/removeDiacritics';
import styles from './Users.module.scss';
import cn from 'classnames';
import { Check, Minus, UserRoundCheck, X } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

const CheckBox = ({ checked = false, minus = false, onClick, disabled }) => {
  return (
    <div className={cn(styles.checkbox, checked && styles.checked, disabled && styles.disabled)} onClick={onClick}>
      {checked && !minus && <Check size={12} color="#1e1e1e" />}
      {checked && minus && <Minus size={12} color="#1e1e1e" />}
    </div>
  );
};

const compareFunction = (numbers) => {
  return (a, b) => {
    const isA = numbers.includes(a.phone);
    const isB = numbers.includes(b.phone);

    if (isA && isB) {
      return a.garden - b.garden;
    } else if (isA && !isB) {
      return -1;
    } else {
      return 1;
    }
  };
};

const Users = ({ handleClose, defaultNumbers = [], confirm }) => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [numbers, setNumbers] = useState(defaultNumbers);

  useEffect(() => {
    const getAsync = async () => {
      try {
        const response = await withMobilePhone();

        setUsers(response.data);
      } catch {
        toast.error('Nie można załadować numerów telefonu');
      }
    };

    getAsync();
  }, []);

  const filteredUsers = useMemo(() => {
    if (search.length > 0) {
      return users
        .filter((user) => {
          const names = removeDiacritics(user.name).toLowerCase();
          const normalizedFilter = removeDiacritics(search).toLowerCase();

          return names.indexOf(normalizedFilter) >= 0 || user.garden.toString() === normalizedFilter;
        })
        .sort(compareFunction(numbers));
    }

    return users.sort(compareFunction(numbers));
  }, [users, search, numbers]);

  return (
    <div className="rod-modal">
      <div className={cn('rod-modal-container', styles.container)}>
        <div className={styles.back}>
          <button onClick={handleClose}>Zamknij</button>
        </div>

        <div className={styles.search}>
          <input type="text" placeholder="Szukaj...." value={search} onChange={(e) => setSearch(e.target.value)} />
          <X color="#515151" onClick={() => setSearch('')} />
        </div>

        <div className={styles.list}>
          <div className={styles.user}>
            <CheckBox
              disabled={search.length > 0}
              checked={numbers.length > 0}
              minus={numbers.length < users.length}
              onClick={() => {
                if (numbers.length === 0) {
                  setNumbers(users.map((u) => u.phone));
                } else {
                  setNumbers([]);
                }
              }}
            />
            <div className={styles.info}>zaznaczono {numbers.length} elementów</div>
          </div>

          {filteredUsers.map((user, index) => {
            const checked = numbers.includes(user.phone);

            return (
              <div className={styles.user} key={index}>
                <CheckBox
                  checked={checked}
                  onClick={() => {
                    if (checked) {
                      setNumbers((n) => n.filter((p) => p !== user.phone));
                    } else {
                      setNumbers((n) => [...n, user.phone]);
                    }
                  }}
                />
                <div className={styles.data}>
                  <div className={styles.name}>
                    {user.garden}. {user.name}
                  </div>
                  <div className={styles.parameters}>{user.phone}</div>
                </div>
              </div>
            );
          })}
        </div>

        <button
          className={cn(styles.confirm, styles.buttonYellow)}
          onClick={() => {
            confirm(numbers);
            handleClose();
          }}
        >
          <UserRoundCheck color="#1e1e1e" size={24} />
        </button>
      </div>
    </div>
  );
};

export default Users;
