import styles from './Copy.module.scss';
import cn from 'classnames';
import { useRef } from 'react';
import toast from 'react-hot-toast';

const copy = (copyText) => {
  // Select the text field
  copyText.select();
  copyText.setSelectionRange(0, 99999); // For mobile devices

  // Copy the text inside the text field
  navigator.clipboard.writeText(copyText.value);

  toast('Skopiowano');
};

const CopyItem = ({ value, children, className }) => {
  const x = useRef();

  return (
    <div
      className={cn(styles.copy, className)}
      onClick={(e) => {
        copy(x.current);
      }}
    >
      {children}

      <input type="text" value={value} readOnly ref={x} />
    </div>
  );
};

export default CopyItem;
