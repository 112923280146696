import instance from './instance';

export const add = (data) => {
  return instance.post('checklists/add', data);
};

export const edit = (id, data) => {
  return instance.put(`checklists/edit/${id}`, data);
};

export const details = (id) => {
  return instance.get(`checklists/details/${id}`);
};

export const list = () => {
  return instance.get(`checklists/list`);
};

export const remove = (id) => {
  return instance.delete(`checklists/remove/${id}`);
};

export const entries = (id) => {
  return instance.get(`checklists/entries/${id}`);
};

export const entry = (id) => {
  return instance.get(`checklists/entry/${id}`);
};

export const modify = (id, data) => {
  return instance.put(`checklists/modify/${id}`, data);
};

export const modifyBulk = (ids, value) => {
  return instance.put(`checklists/modifyBulk`, { ids, value });
};
