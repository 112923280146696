import instance from './instance';

export const add = (data) => {
  return instance.post('payments/add', data);
};

export const list = (year) => {
  return instance.get(`payments/list?year=${year}`);
};

export const listForFees = (year, paymentType = 1) => {
  return instance.get(`payments/list?paymentType=${paymentType}&year=${year}`);
};

export const remove = (id) => {
  return instance.delete(`payments/remove/${id}`);
};

export const edit = (id, data) => {
  return instance.put(`payments/edit/${id}`, data);
};

export const details = (id) => {
  return instance.get(`payments/details/${id}`);
};
