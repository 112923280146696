import Page from '../../Page';
import Select from '../../Select';
import { list as getUsers } from '../../httpClient/users';
import { list } from '../../httpClient/waterMeters';
import { ButtonMenu } from '../../shared/ButtonMenu';
import { UsersListState } from '../../store/users';
import Measures from '../Measures';
import styles from './List.module.scss';
import { CircleChevronRight, CircleGauge, User } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';

const List = () => {
  const [users, setUsers] = useRecoilState(UsersListState);
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [year, setYear] = useState(2024);

  const years = useMemo(() => {
    const currentYear = year;

    return [currentYear - 1, currentYear];
  }, [year]);

  useEffect(() => {
    const get = async () => {
      try {
        const response = await list(true, year);

        setDataList(response.data);
      } catch (e) {
        toast.error('Nie można pobrać danych');
      }
    };

    get();
  }, [year]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getUsers();

        setUsers(response.data);
      } catch (e) {
        toast.error('Nie można pobrać danych');
      }
    };

    getData();
  }, [setUsers]);

  if (!users || users.length < 1) {
    return null;
  }

  return (
    <Page title="Wodomierze">
      <ButtonMenu>
        <Link to="add">
          <div>
            <CircleGauge size={28} />
          </div>
          Dodaj odczyt
        </Link>
      </ButtonMenu>

      <div className={styles.list}>
        <Select
          onChange={(v) => {
            setDataList([]);
            setYear(v);
          }}
          value={year}
        />

        {dataList.map((item, index) => {
          return (
            <div key={item.id} className={styles.box}>
              <div
                className={styles.header}
                onClick={() => {
                  navigate(`details/${item.id}`);
                }}
              >
                <div className={styles.user}>
                  <div className={styles.number}>{item.id}</div>
                  <div className={styles.name}>{[users[index].names, users[index].subOwnerNames].filter(Boolean).join(', ')}</div>
                </div>

                <CircleChevronRight />
              </div>
              <div className={styles.measures}>
                {years.map((y) => (
                  <Measures measures={item.measures[y]} year={y} id={item.id} />
                ))}
              </div>
              {item.measures[years[0]].length < 1 && item.measures[years[1]].length < 1 && (
                <div className={styles.addWrapper}>
                  <span className={styles.add}>brak wodomierza</span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Page>
  );
};

export default List;
