import instance from './instance';

export const list = () => {
  return instance.get(`news/list`);
};

export const add = (data) => {
  return instance.post('news/add', data);
};

export const edit = (id, data) => {
  return instance.put(`news/edit/${id}`, data);
};

export const remove = (id) => {
  return instance.delete(`news/remove/${id}`);
};

export const details = (id) => {
  return instance.get(`news/details/${id}`);
};

export const newsAi = (message) => {
  return instance.post(`news/ai`, { message });
};
