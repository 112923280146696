import Page from '../../Page';
import { list } from '../../httpClient/users';
import { size } from '../../httpClient/waterMeters';
import { NOT_NUMBER, REQUIRED } from '../../utils/formTexts';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup
  .object({
    gardenId: yup.number().typeError(REQUIRED).positive(REQUIRED).integer(REQUIRED).required(REQUIRED),
    size: yup.number().typeError(NOT_NUMBER),
  })
  .required();

const Size = () => {
  const [users, setUsers] = useState([]);
  const [added, setAdded] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const get = async () => {
      try {
        const response = await list();
        const users = response.data.filter((item) => item.waterMeter).map((item) => ({ id: item.id, name: item.names }));

        setUsers(users);
      } catch (e) {}
    };

    get();
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        reset({
          gardenId: id,
        });
      }, 100);
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append('size', data.size);
      formData.append('gardenId', data.gardenId);

      await size(formData);

      toast.success('Dane zostały zapisane');
      setAdded(true);
    } catch (e) {}
  };

  return (
    <>
      <Page title="Dodawanie nowego odczytu wodomierza" back="/watermeters" breadcrumbs={[['/watermeters', 'Wodomierze']]}>
        <div className="rod-form">
          <div className="rod-form-content">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="rod-form-row">
                <label>Numer działki *</label>
                <select disabled={id !== undefined} {...register('gardenId')}>
                  <option value="" />
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.id}. {user.name}
                    </option>
                  ))}
                </select>
                {errors.gardenId && <div className="rod-form-error">{errors.gardenId?.message}</div>}
              </div>

              <div className="rod-form-row">
                <label>Rozmiar wodomierza *</label>

                <select {...register('size')}>
                  <option value={1}>1/2''</option>
                  <option value={2}>3/4''</option>
                </select>

                {errors.size && <div className="rod-form-error">{errors.size?.message}</div>}
              </div>

              <div className="rod-form-legend">
                <ul>
                  <li>* - pole wymagane</li>
                </ul>
              </div>

              <div className="rod-form-submit">
                <input disabled={added} type="submit" className="button-primary" value="Zapisz" />
                <input
                  className="button-secondary"
                  type="button"
                  value="Reset"
                  onClick={() => {
                    reset();
                    setAdded(false);
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </Page>
    </>
  );
};

export default Size;
