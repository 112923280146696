import App from './App';
import './index.css';
import './rod-form.scss';
import './rod-info.scss';
import './rod-modal.scss';
import './rod-table.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-swipeable-list/dist/styles.css';
import { RecoilRoot } from 'recoil';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>
);
