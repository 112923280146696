const makeNames = (data) => {
  const names = [];

  if (data.names) {
    names.push(data.names);
  }

  if (Boolean(data.hasTwoOwners) && data.subOwnerNames) {
    names.push(data.subOwnerNames);
  }

  return names.join(', ');
};

export default makeNames;
