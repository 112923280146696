import { list as getList } from '../../../httpClient/bank';
import styles from '../../Bank.module.scss';
import Modal from '../../Modal';
import addSvg from '../../assets/add.svg';
import Confirm from '../../utils/Confirm';
import Options from '../../utils/Options';
import useList from '../../utils/useList';
import AddOrEdit from './AddOrEdit';
import { useState } from 'react';

const remove = () => {};

const HistoryList = () => {
  const [add, setAdd] = useState();
  const [editId, setEditId] = useState();
  const [removeId, setRemoveId] = useState();

  const list = useList(getList);

  return (
    <>
      <div className={styles.menu}>
        <button
          onClick={() => {
            setAdd(true);
          }}
        >
          <div className={styles.icon}>
            <img src={addSvg} alt="Menu item" />
          </div>
          <div className={styles.title}>Dodaj</div>
        </button>
      </div>

      <div className={styles.clients}>
        {(list.data || []).map((l) => (
          <div className={styles.client} key={l.id}>
            <div className={styles.details}>
              <div className={styles.name}>{l.name}</div>
              <div className={styles.uuid}>{l.uuid}</div>
            </div>
            <div className={styles.actions}>
              <Options>
                <button
                  onClick={() => {
                    setEditId(l.id);
                  }}
                >
                  Edytuj
                </button>

                <button
                  onClick={() => {
                    setRemoveId(l.id);
                  }}
                >
                  Usuń
                </button>
              </Options>
            </div>
          </div>
        ))}
      </div>

      {add && (
        <Modal onClose={setAdd}>
          <AddOrEdit onClose={setAdd} refresh={list.refresh} />
        </Modal>
      )}

      {editId && <Modal onClose={setEditId}></Modal>}

      {removeId && (
        <Modal onClose={setRemoveId}>
          <Confirm id={removeId} onClose={setRemoveId} refresh={list.refresh} action={remove} />
        </Modal>
      )}
    </>
  );
};

export default HistoryList;
