import Page from '../../Page';
import { list } from '../../httpClient/users';
import { add, askAI, sendImage } from '../../httpClient/waterMeters';
import FixedNumberInput from '../../utils/FixedNumberInput';
import { NOT_DATE, NOT_NUMBER, REQUIRED } from '../../utils/formTexts';
import styles from './Add.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { format } from 'date-fns';
import pl from 'date-fns/locale/pl';
import { CircleX, Sparkles, Upload } from 'lucide-react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

const getExtensionManually = (name) => name.substr(name.lastIndexOf('.') + 1);

// the locale you want

registerLocale('pl', pl); // register it with the name you want

const TIME_FORMAT = 'yyyy-MM-dd';

const schema = yup
  .object({
    gardenId: yup.number().typeError(REQUIRED).positive(REQUIRED).integer(REQUIRED).required(REQUIRED),
    time: yup.date().typeError(NOT_DATE).required(REQUIRED),
    value: yup.number().typeError(NOT_NUMBER).required(REQUIRED),
  })
  .required();

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <div className="input" onClick={onClick} ref={ref}>
    {value}
  </div>
));

const Add = () => {
  const [users, setUsers] = useState([]);
  const [added, setAdded] = useState(false);
  const [blob, setBlob] = useState();
  const [fileName, setFileName] = useState('');
  const [askedAI, setAskedAI] = useState(false);
  const [uploading, setUploading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
    setValue,
    getFieldState,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      time: new Date(),
      value: '',
    },
  });

  const valueDirty = useRef(false);
  const valueState = getFieldState('value');

  const [watchFile, watchId, _valueField] = watch(['file', 'gardenId', 'value']);

  useEffect(() => {
    if (valueState.isDirty) {
      valueDirty.current = true;

      setAskedAI(false);
    }
  }, [valueState]);

  const { id } = useParams();
  const parent = id;

  useEffect(() => {
    const get = async () => {
      try {
        const response = await list();
        const users = response.data.filter((item) => item.waterMeter).map((item) => ({ id: item.id, name: item.names }));

        setUsers(users);
      } catch (e) {}
    };

    get();
  }, []);

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        reset({
          gardenId: id,
        });
      }, 100);
    }
  }, [id, reset]);

  useEffect(() => {
    const addFile = async () => {
      if (watchFile && watchFile.length > 0 && watchId) {
        setBlob(URL.createObjectURL(watchFile[0]));

        const extension = getExtensionManually(watchFile[0].name);

        const name = `${watchId}-${Date.now()}.${extension}`;

        setFileName(name);

        setUploading(true);

        const formDataFile = new FormData();
        formDataFile.append('input_file', watchFile[0]);
        formDataFile.append('file_name', name);

        if (!valueDirty.current) {
          try {
            await sendImage(formDataFile);

            setUploading(false);

            setAskedAI(true);

            const response = await askAI(name);

            if (response.data.result) {
              if (!valueDirty.current) {
                setValue('value', response.data.result);
              }
            }
          } catch (e) {
            toast.error('Nie wysłano obrazu. Odśwież i spróbuj ponowanie');
          }

          setAskedAI(false);
          setUploading(false);
        }
      }
    };

    addFile();
  }, [watchFile]);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append('time', format(data.time, TIME_FORMAT));
      formData.append('value', data.value / 1000);
      formData.append('gardenId', data.gardenId);
      formData.append('fileName', fileName);

      await add(formData);

      toast.success('Dane zostały zapisane');
      setAdded(true);
    } catch (e) {}
  };

  return (
    <>
      {uploading && (
        <div className={styles.uploading}>
          <Upload size={20} /> Trwa przesyłanie obrazu. Proszę czekać
        </div>
      )}

      {askedAI && (
        <div className={styles.ai}>
          <Sparkles size={20} /> Trwa analizowanie obrazu <span className={styles.loader}></span>
        </div>
      )}

      <Page
        title="Dodawanie nowego odczytu wodomierza"
        back={`/watermeters${parent ? `/details/${parent}` : ''}`}
        breadcrumbs={[
          ['/watermeters', 'Wodomierze'],
          parent ? [`/watermeters/details/${parent}`, `Działka nr ${parent}`] : undefined,
        ].filter(Boolean)}
      >
        <div className="rod-form">
          <div className="rod-form-content">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="rod-form-row">
                <label>Numer działki *</label>
                <select disabled={id !== undefined} {...register('gardenId')}>
                  <option value="" />
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.id}. {user.name}
                    </option>
                  ))}
                </select>
                {errors.gardenId && <div className="rod-form-error">{errors.gardenId?.message}</div>}
              </div>
              <div className="rod-form-row">
                <label>Data odczytu *</label>
                <Controller
                  control={control}
                  name="time"
                  render={({ field }) => (
                    <ReactDatePicker
                      dateFormat={TIME_FORMAT}
                      onChange={(e) => field.onChange(e)}
                      selected={field.value}
                      customInput={<ExampleCustomInput />}
                      locale="pl"
                    />
                  )}
                />
                {errors.time && <div className="rod-form-error">{errors.time?.message}</div>}
              </div>

              <div className="rod-form-row">
                <label>Zdjęcie *</label>

                <div className={styles.fileWrapper}>
                  {!blob && <label>{watchId ? 'Dodaj zdjęcie' : 'Najpierw wybierz numer działki'}</label>}

                  {blob && <img src={blob} alt="Preview" />}

                  <input type="file" {...register('file')} accept=" image/jpeg, image/png" disabled={!watchId} />
                </div>

                {errors.file && <div className="rod-form-error">{errors.file?.message}</div>}
              </div>

              <div className={cn('rod-form-row', styles.value)}>
                <label>Wartość odczytu *</label>

                <Controller
                  control={control}
                  name="value"
                  render={({ field, formState }) => <FixedNumberInput setValue={setValue} field={field} fractionDigits={3} intDigits={5} />}
                />

                <div
                  className={styles.clear}
                  onClick={() => {
                    setValue('value', '');
                  }}
                >
                  <CircleX />
                </div>

                {errors.value && <div className="rod-form-error">{errors.value?.message}</div>}
              </div>

              <div className="rod-form-legend">
                <ul>
                  <li>* - pole wymagane</li>
                </ul>
              </div>

              <div className="rod-form-submit">
                <input disabled={added} type="submit" className="button-primary" value="Zapisz" />
                <input
                  className="button-secondary"
                  type="button"
                  value="Reset"
                  onClick={() => {
                    reset();
                    setAdded(false);
                    setBlob(undefined);
                    setAskedAI(false);
                    valueDirty.current = false;
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </Page>
    </>
  );
};

export default Add;
