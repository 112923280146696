/* eslint-disable react-hooks/exhaustive-deps */
import RobotoBlackFont from '../../../assets/Roboto-Black.ttf';
import RobotoFont from '../../../assets/Roboto.ttf';
import RobotoMonoFont from '../../../assets/RobotoMono-Light.ttf';
import calculatePrice from '../helpers/calculatePrice';
import styles from './PdfDocument.style';
import logoUrl from './logofull.png';
import watermarkUrl from './rodlogo.png';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import { useMemo } from 'react';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: RobotoFont }, // font-style: normal, font-weight: normal
    { src: RobotoBlackFont, fontWeight: 700 },
  ],
});

Font.register({
  family: 'RobotoMono',
  fonts: [{ src: RobotoMonoFont }],
});

const tableColumnSizes = [20, 30, 50];
const titles = ['Nr działki', 'Kwota', 'Opis'];

const Row = ({ data }) => {
  return (
    <View
      style={[
        {
          display: 'flex',
          flexDirection: 'row',
        },
        styles.mono,
      ]}
      wrap={false}
    >
      <View
        style={[
          styles.cell,
          {
            width: `${tableColumnSizes[0]}%`,
            padding: '4px',
          },
        ]}
      >
        <Text>{data.id}</Text>
      </View>
      <View
        style={[
          styles.cell,
          {
            width: `${tableColumnSizes[1]}%`,
            padding: '4px',
          },
        ]}
      >
        <Text>{data.sum}</Text>
      </View>
      <View
        style={[
          styles.cell,
          {
            width: `${tableColumnSizes[2]}%`,
            padding: '4px',
          },
        ]}
      >
        {data.details.map((t, index) => {
          if (Array.isArray(t)) {
            return (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
                key={index}
              >
                <View
                  style={{
                    width: 96,
                  }}
                >
                  <Text>{t[0]}</Text>
                </View>
                <View>
                  <Text>{t[1]}</Text>
                </View>
              </View>
            );
          } else {
            return (
              <View key={index}>
                <Text>{t}</Text>
              </View>
            );
          }
        })}
      </View>
    </View>
  );
};

const proportion = ([w, h], height) => {
  return {
    height,
    width: (w / h) * height,
  };
};

const PdfDocument = ({ data, settings: allSettings, year, lastYear, usersDetails }) => {
  const settings = allSettings[1];

  const rows = useMemo(() => {
    return data
      .map((row, index) => {
        const prices = calculatePrice(row, lastYear, allSettings);

        const user = usersDetails.find((ud) => ud.id === row.userId);

        const hasAccessToWater = row.userId !== 2;

        const result = [
          user?.names?.toUpperCase(),
          ['Opłata działkowa', prices.gardenFee.toFixed(2)],
          ['Partycypacja', prices.participation.toFixed(2)],
          ['Składka członkowska', settings.membershipFee.toFixed(2)],
          // hasAccessToWater && ['Opłata wodna', settings.waterFee.toFixed(2)],
        ].filter(Boolean);

        if (row.waterMeter) {
          // result.push(hasAccessToWater && ['Odczyt wodomierza', settings.waterMeterReading.toFixed(2)]);
          result.push(hasAccessToWater && ['Koszt zużycia wody', prices.waterFee.toFixed(2)]);
        } else {
          result.push(hasAccessToWater && ['Koszt zużycia wody', prices.waterFee.toFixed(2)]);
        }

        result.push(['Fundusz remontowy', settings.renovationFund.toFixed(2)]);

        return {
          id: row.userId,
          sum: prices.total.toFixed(2),
          details: result,
        };
      })
      .filter(Boolean);
  }, []);

  return (
    <Document>
      <Page size="A4" style={styles.body} wrap={true}>
        <View style={styles.header} fixed>
          <View style={{ width: '50%' }}>
            <Image src={logoUrl} style={{ ...proportion([647, 307], 50), marginTop: 12 }} />
          </View>
        </View>

        <View style={styles.watermark} fixed>
          <Image src={watermarkUrl} style={{ ...proportion([324, 581], 360) }} />
        </View>

        <View style={[styles.dotted, styles.title, styles.flexRow]}>
          <View>
            <Text style={[styles.bold, { fontSize: 17 }]}>ZESTAWIENIE OPŁAT DZIAŁKOWCÓW ZA ROK {year}</Text>
          </View>
        </View>

        <View style={[styles.dotted, styles.flexRow]}>
          <View style={{ width: '50%', padding: '10px 0' }}>
            <Text style={styles.bold}>Dane ogrodu</Text>
            <Text>Rodzinne Ogrody Działkowe im. 1 Maja</Text>
            <Text>ul. 1 Maja b/n</Text>
            <Text>71-627 Szczecin</Text>
            <Text>NIP: 851-25-94-219</Text>
          </View>

          <View style={{ width: '50%', backgroundColor: '#e3e3e3', padding: '10px' }}>
            <Text style={styles.bold}>Numer rachunku</Text>
            <Text>56 1240 3972 1111 0010 3563 6279</Text>
            <Text> </Text>
            <Text style={styles.bold}>Waluta</Text>
            <Text>PLN</Text>
          </View>
        </View>

        <View style={[styles.table, { margin: '20px 0' }]}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 10,
            }}
          >
            {titles.map((title, index) => (
              <View
                key={index}
                style={[
                  styles.cell,
                  styles.bold,
                  {
                    width: `${tableColumnSizes[index]}%`,
                  },
                ]}
              >
                <Text>{title}</Text>
              </View>
            ))}
          </View>

          {rows.map((row, index) => (
            <Row key={index} data={row} />
          ))}
        </View>

        <View
          style={styles.pageNumber}
          render={(props) => {
            if (props.pageNumber && props.totalPages) {
              return (
                <View style={styles.pageNumberWrapper}>
                  <View>
                    <Text style={styles.pageNumberLeft}>Dokument wygenerowany przez oprogramowanie stworzone przez Bartosza Maciuraka</Text>
                    <Text style={styles.pageNumberLefDate}>Data wygenerowania: 06.12.2022</Text>
                  </View>
                  <Text style={styles.pageNumberRight}>
                    Strona {props.pageNumber} z {props.totalPages}
                  </Text>
                </View>
              );
            }

            return null;
          }}
          fixed
        />
      </Page>
    </Document>
  );
};

export default PdfDocument;
