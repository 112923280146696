import instance from './instance';

export const list = () => {
  return instance.get('settings/list');
};

export const edit = (data) => {
  return instance.put(`settings/edit`, data);
};

export const details = () => {
  return instance.get(`settings/details`);
};
