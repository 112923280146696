import Number from '../../Number';
import Page from '../../Page';
import { list } from '../../httpClient/settings';
import styles from './List.module.scss';
import format from 'date-fns/format';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const List = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await list();

        setData(response.data);
      } catch (e) {
        toast.error('Nie można pobrać danych');
      }
    };

    getData();
  }, [setData]);

  const currentYear = format(new Date(), 'yyyy');

  const showForCurrentYear = useMemo(() => {
    return !data.reduce((acc, curr) => {
      return acc || curr.year.toString() === currentYear;
    }, false);
  }, [currentYear, data]);

  return (
    <Page title="Cenniki">
      {showForCurrentYear && (
        <Link to={`edit`} className="rod-table-button-primary">
          Dodaj nowy rok
        </Link>
      )}

      <div className={styles.container}>
        {data.map((item) => {
          return (
            <div key={item.id} className={styles.box}>
              <div className={styles.header}>
                <div className={styles.year}>{item.year}</div>
                <div>
                  {' '}
                  {currentYear === item.year.toString() && (
                    <Link to={`edit`} className="rod-table-button-primary">
                      Edytuj
                    </Link>
                  )}
                </div>
              </div>

              <div>
                <ul className={styles.list}>
                  <li>
                    <span className={styles.title}>Składka członkowska</span> <Number value={item.membershipFee} />
                  </li>
                  <li>
                    <span className={styles.title}>
                      Opłata działkowa / m<sup>2</sup>
                    </span>{' '}
                    <Number value={item.gardenFee} />
                  </li>
                  <li>
                    <span className={styles.title}>Opłata wodna</span> <Number value={item.waterFee} />
                  </li>
                  <li>
                    <span className={styles.title}>Odczyt wodomierza</span> <Number value={item.waterMeterReading} />
                  </li>
                  <li>
                    <span className={styles.title}>
                      Cena wody - wodomierz / m<sup>3</sup>
                    </span>{' '}
                    <Number value={item.waterPriceForWatermeter} />
                  </li>
                  <li>
                    <span className={styles.title}>
                      Cena wody - powierzchnia / m<sup>2</sup>
                    </span>{' '}
                    <Number value={item.waterPriceForArea} />
                  </li>
                  <li>
                    <span className={styles.title}>
                      Partycypacja / m<sup>2</sup>
                    </span>{' '}
                    <Number value={item.participation} />
                  </li>
                  <li>
                    <span className={styles.title}>Fundusz remontowy</span> <Number value={item.renovationFund} />
                  </li>
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </Page>
  );
};

export default List;
