import instance from './instance';
import axios from 'axios';

const filesInstance = axios.create({
  baseURL: 'https://files.r1m.pl',
  timeout: 100000,
});

export const add = (data) => {
  return instance.post('watermeters/add', data);
};

export const details = (id) => {
  return instance.get(`watermeters/details/${id}`);
};

export const list = (withWatermeter, year) => {
  return instance.get(`watermeters/list?withWatermeter=${withWatermeter ? 'true' : 'false'}&year=${year}`);
};

export const remove = (id) => {
  return instance.delete(`watermeters/remove/${id}`);
};

export const usage = (year) => {
  return instance.get(`watermeters/usage?year=${year}`);
};

export const size = (data) => {
  return instance.post('watermeters/size', data);
};

export const edit = (id, value) => {
  return instance.put(`watermeters/edit/${id}`, { value });
};

export const value = (id, value) => {
  return instance.get(`watermeters/value/${id}`);
};

export const sendImage = (data) => {
  return filesInstance.post('/', data);
};

export const askAI = (name) => {
  return filesInstance.get(`/verify.php?name=${name}`);
};
