import Page from '../../Page';
import { details, edit } from '../../httpClient/settings';
import { NOT_NUMBER, REQUIRED, TOO_BIG } from '../../utils/formTexts';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import 'yup-phone';

const schema = yup
  .object({
    gardenFee: yup.number().typeError(NOT_NUMBER).max(1000, TOO_BIG).required(REQUIRED),
    waterFee: yup.number().typeError(NOT_NUMBER).max(1000, TOO_BIG).required(REQUIRED),
    waterPriceForWatermeter: yup.number().typeError(NOT_NUMBER).max(1000, TOO_BIG).required(REQUIRED),
    waterPriceForArea: yup.number().typeError(NOT_NUMBER).max(1000, TOO_BIG).required(REQUIRED),
    waterMeterReading: yup.number().typeError(NOT_NUMBER).max(1000, TOO_BIG).required(REQUIRED),
    renovationFund: yup.number().typeError(NOT_NUMBER).max(1000, TOO_BIG).required(REQUIRED),
    membershipFee: yup.number().typeError(NOT_NUMBER).max(1000, TOO_BIG).required(REQUIRED),
    participation: yup.number().typeError(NOT_NUMBER).max(1000, TOO_BIG).required(REQUIRED),
  })
  .required();

const Edit = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await details();
        const { data } = response;

        if (data) {
          setTimeout(() => {
            reset(data);
          }, 100);
        }
      } catch (e) {
        toast.error('Nie udało się pobrać danych');
      }
    };

    getData();
  }, [reset]);

  const onSubmit = async (data) => {
    try {
      await edit(data);

      toast.success('Dane zostały zapisane');
    } catch (e) {
      toast.error('Nie udało się');
    }
  };

  return (
    <Page title={`Cennik dla roku ${new Date().getFullYear()}`} back="/settings" breadcrumbs={[['/settings', 'Cenniki']]}>
      <div className="rod-form">
        <div className="rod-form-content">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="rod-form-row">
              <label>Składka członkowska *</label>
              <input type="text" {...register('membershipFee')} />
              {errors.membershipFee && <div className="rod-form-error">{errors.membershipFee?.message}</div>}
            </div>

            <div className="rod-form-row">
              <label>
                Opłata działkowa / m<sup>2</sup> *
              </label>
              <input type="text" {...register('gardenFee')} />
              {errors.gardenFee && <div className="rod-form-error">{errors.gardenFee?.message}</div>}
            </div>

            <div className="rod-form-row">
              <label>Opłata wodna *</label>
              <input type="text" {...register('waterFee')} />
              {errors.waterFee && <div className="rod-form-error">{errors.waterFee?.message}</div>}
            </div>
            <div className="rod-form-row">
              <label>Odczyt wodomierza *</label>
              <input type="text" {...register('waterMeterReading')} />
              {errors.waterMeterReading && <div className="rod-form-error">{errors.waterMeterReading?.message}</div>}
            </div>
            <div className="rod-form-row">
              <label>
                Cena wody - wodomierz / m<sup>3</sup> *
              </label>
              <input type="text" {...register('waterPriceForWatermeter')} />
              {errors.waterPriceForWatermeter && <div className="rod-form-error">{errors.waterPriceForWatermeter?.message}</div>}
            </div>
            <div className="rod-form-row">
              <label>
                Cena wody - powierzchnia / m<sup>2</sup> *
              </label>
              <input type="text" {...register('waterPriceForArea')} />
              {errors.waterPriceForArea && <div className="rod-form-error">{errors.waterPriceForArea?.message}</div>}
            </div>

            <div className="rod-form-row">
              <label>
                Partycypacja / m<sup>2</sup> *
              </label>
              <input type="text" {...register('participation')} />
              {errors.participation && <div className="rod-form-error">{errors.participation?.message}</div>}
            </div>

            <div className="rod-form-row">
              <label>Fundusz remontowy *</label>
              <input type="text" {...register('renovationFund')} />
              {errors.renovationFund && <div className="rod-form-error">{errors.renovationFund?.message}</div>}
            </div>

            <div className="rod-form-legend">
              <ul>
                <li>* - pole wymagane</li>
              </ul>
            </div>

            <div className="rod-form-submit">
              <input type="submit" className="button-primary" value="Zapisz" />
            </div>
          </form>
        </div>
      </div>
    </Page>
  );
};

export default Edit;
