import { modify } from '../../httpClient/checklists';
import { NOT_NUMBER, REQUIRED } from '../../utils/formTexts';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';

const schema = yup
  .object({
    value: yup.number().typeError(NOT_NUMBER).required(REQUIRED),
  })
  .required();

const Form = ({ isEdit = false, defaultValues = undefined }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    try {
      await modify(data.id, data);

      toast.success('Dane zostały zapisane');
    } catch (e) {
      toast.error('Nie udało się');
    }
  };

  return (
    <div className="rod-form">
      <div className="rod-form-content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="rod-form-row">
            <label>Wartość *</label>
            <select {...register('value')}>
              <option value={1}>tak</option>
              <option value={2}>nie</option>
              <option value={3}>nie dotyczy</option>
            </select>
            {errors.value && <div className="rod-form-error">{errors.value?.message}</div>}
          </div>

          <div className="rod-form-legend">
            <ul>
              <li>* - pole wymagane</li>
            </ul>
          </div>

          <div className="rod-form-submit">
            <input type="submit" className="button-primary" value="Zapisz" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
