import styles from '../Bank.module.scss';
import closeSvg from '../assets/close.svg';

const Modal = ({ children, onClose }) => {
  return (
    <div className={styles.modal}>
      <button
        className={styles.close}
        onClick={() => {
          onClose(undefined);
        }}
      >
        <img src={closeSvg} alt="Close" />
      </button>

      <div className={styles.inner}>{children}</div>
    </div>
  );
};

export default Modal;
