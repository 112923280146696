import Page from '../../Page';
import Removing from '../../Removing';
import { remove } from '../../httpClient/files';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Remove = () => {
  const query = useQuery();

  const id = query.get('file');

  return (
    <Page back="/files" breadcrumbs={[['/files', 'Dokumenty przesłane']]} title="Usuwanie dokumentu">
      <Removing url="/files" title="Czy na pewno chcesz usunąć dokument?" action={() => remove(id)} />
    </Page>
  );
};

export default Remove;
