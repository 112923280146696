import { useMemo } from 'react';

const FROM = 2022;
const TO = 2024;

const Select = ({ onChange, value, from = FROM, to = TO }) => {
  const list = useMemo(() => {
    const l = [];

    for (let i = from; i <= to; i++) {
      l.push(i);
    }

    return l;
  }, [from, to]);

  return (
    <div className="rod-year-select">
      <select
        onChange={(e) => {
          onChange(e.target.value);
        }}
        value={value}
      >
        {list.map((l) => (
          <option key={l} value={l}>
            {l}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
