import Page from '../../Page';
import { entries, modify, modifyBulk } from '../../httpClient/checklists';
import makeNames from '../../utils/makeNames';
import styles from './Entries.module.scss';
import cn from 'classnames';
import { CircleCheck, CircleHelp, CircleX } from 'lucide-react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import { LeadingActions, SwipeAction, SwipeableList, SwipeableListItem, TrailingActions, Type } from 'react-swipeable-list';

const SwipeableEntry = ({ item, setIds, ids, hiddenId }) => {
  return (
    <div className={styles.entry}>
      <div
        className={cn(styles.checkbox, ids.includes(item.id) && styles.selected)}
        onClick={() => {
          setIds((ids) => {
            if (ids.includes(item.id)) {
              return ids.filter((entry) => entry !== item.id);
            }

            return [...ids, item.id];
          });
        }}
      >
        <span>{item.userId}</span>
      </div>
      <div className={styles.name}>
        <Link to={`/checklists/entry/${item.id}`} className={styles.edit}>
          {makeNames(item)}
        </Link>
      </div>
      <div className={cn(styles.value, hiddenId === item.id && styles.hidden)}>
        {item.value === 1 && <CircleCheck color="#ffe37e" />}
        {item.value === 2 && <CircleX color="#e34848" />}
        {item.value === 3 && <CircleHelp color="#5c5c5c" />}
        {item.accountName ? <span className={styles.modified}>({item.accountName})</span> : ''}
      </div>
    </div>
  );
};

const Entries = () => {
  const { id } = useParams();
  const [dataList, setDataList] = useState([]);
  const [title, setTitle] = useState('Check-listy');
  const [ids, setIds] = useState([]);
  const [changeValue, setChangeValue] = useState(0);
  const [hiddenId, setHiddenId] = useState(undefined);

  const get = async () => {
    try {
      const response = await entries(id);

      setDataList(response.data.entries.filter((e) => e.userId));
      setTitle('Checklista ' + response.data.details.name);
    } catch (e) {
      toast.error('Nie można pobrać danych');
    }
  };

  useEffect(() => {
    get();
  }, [id]);

  const bulkEdit = async () => {
    try {
      await modifyBulk(ids, changeValue);

      toast.success('Dane zostały zmienione');

      setIds([]);
      setChangeValue(0);

      get();
    } catch {
      toast.error('Nie udało się zmodyfikować danych');
    }
  };

  useEffect(() => {
    if (changeValue) {
      bulkEdit();
    }
  }, [changeValue]);

  const singleEdit = async (id, value) => {
    try {
      await modify(id, { value });
      await get();
    } catch (e) {
      toast.error('Nie udało się');
    }
  };

  const leadingActions = (id) => (
    <LeadingActions>
      <SwipeAction
        onClick={() => {
          singleEdit(id, 1);
        }}
      >
        <div className={cn(styles.actions, styles.yes)}>
          <CircleCheck size={24} />
        </div>
      </SwipeAction>
    </LeadingActions>
  );

  const trailingActions = (id) => (
    <TrailingActions>
      <SwipeAction onClick={() => singleEdit(id, 2)}>
        <div className={cn(styles.actions, styles.no)}>
          <CircleX size={24} />
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  const allSelected = ids.length === dataList.length;

  return (
    <Page title={title} back="/checklists" breadcrumbs={[['/checklists', 'Check-listy']]}>
      <div>
        <div className={styles.selectContainer}>
          <div
            className={cn(styles.checkbox, allSelected && styles.selected, ids.length > 0 && !allSelected && styles.halfSelected)}
            onClick={() => {
              setIds((ids) => {
                if (allSelected) {
                  return [];
                }

                return [...dataList.map((e) => e.id)];
              });
            }}
          ></div>

          <select
            className={styles.select}
            disabled={ids.length < 1}
            value={changeValue}
            onChange={(e) => {
              setChangeValue(e.target.value);
            }}
          >
            <option value={0}>Ustaw wartość na:</option>
            <option value={1}>tak</option>
            <option value={2}>nie</option>
            <option value={3}>nie dotyczy</option>
          </select>
        </div>

        <SwipeableList type={Type.IOS} fullSwipe={true} threshold={0.5}>
          {dataList.map((item) => {
            return (
              <SwipeableListItem
                key={item.id}
                leadingActions={leadingActions(item.id)}
                trailingActions={trailingActions(item.id)}
                className={styles.swipeable}
                onSwipeStart={() => setHiddenId(item.id)}
                onSwipeEnd={() => setHiddenId(undefined)}
              >
                <SwipeableEntry ids={ids} item={item} setIds={setIds} hiddenId={hiddenId} />
              </SwipeableListItem>
            );
          })}
        </SwipeableList>

        <div className={styles.count}>Ilość elementów: {dataList.length}</div>
      </div>
    </Page>
  );
};

export default Entries;
