import Page from '../../Page';
import Form from '../Form';

const Add = () => {
  return (
    <Page back="/payments" title="Dodawanie płatności" breadcrumbs={[['/payments', 'Kasa']]}>
      <Form />
    </Page>
  );
};

export default Add;
