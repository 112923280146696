import { add, edit, newsAi } from '../../httpClient/news';
import { NOT_DATE, REQUIRED } from '../../utils/formTexts';
import styles from './Form.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoaderCircle, Sparkles } from 'lucide-react';
import { useCallback, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import * as yup from 'yup';

const TIME_FORMAT = 'yyyy-MM-dd';

const schema = yup
  .object({
    title: yup.string().required(REQUIRED),
    content: yup.string().required(REQUIRED),
    time: yup.date().typeError(NOT_DATE).required(REQUIRED),
    visible: yup.boolean(),
  })
  .required();

const Form = ({ isEdit = false, defaultValues = undefined }) => {
  const [ai, setAi] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    try {
      if (isEdit) {
        await edit(data.id, data);

        toast.success('Dane zostały zapisane');
      } else {
        await add(data);

        toast.success('News dodany');
      }
    } catch (e) {
      toast.error('Nie udało się');
    }
  };

  const askAi = useCallback(async () => {
    if (prompt.length < 1) {
      return;
    }

    setLoading(true);

    try {
      const response = await newsAi(prompt);

      if (response?.data?.choices?.[0]) {
        setValue('content', response.data.choices[0].message.content);
      }
    } catch (e) {
      console.log(e);

      toast.error('Nie można zapytać AI');
    }

    setLoading(false);
  }, [prompt, setLoading]);

  const content = watch('content');

  return (
    <div className="rod-form">
      <div className="rod-form-content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="rod-form-row">
            <label>Tytuł *</label>
            <input type="text" {...register('title')} />
            {errors.title && <div className="rod-form-error">{errors.title?.message}</div>}
          </div>

          <div className="rod-form-row">
            <label>Data publikacji *</label>
            <Controller
              control={control}
              name="time"
              render={({ field }) => (
                <ReactDatePicker dateFormat={TIME_FORMAT} onChange={(e) => field.onChange(e)} selected={field.value} />
              )}
            />
            {errors.time && <div className="rod-form-error">{errors.time?.message}</div>}
          </div>

          <div className={styles.ai}>
            <span className={styles.aiButton} onClick={() => setAi(true)}>
              <Sparkles size={20} /> Prompt AI
            </span>

            {ai && (
              <div className={styles.container}>
                <textarea value={prompt} onChange={(e) => setPrompt(e.target.value)} placeholder="Napisz newsa o..."></textarea>

                <div className={styles.controls}>
                  <span
                    onClick={() => {
                      setPrompt('');
                      setAi(false);
                    }}
                  >
                    Anuluj
                  </span>
                  <span
                    onClick={() => {
                      askAi();
                    }}
                  >
                    Wykonaj
                  </span>
                </div>
              </div>
            )}

            {loading && (
              <div className={styles.loading}>
                <LoaderCircle size={60} />
              </div>
            )}
          </div>

          <div className="rod-form-row">
            <label>Treść *</label>

            <Controller
              control={control}
              name="content"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <>
                  <div className={styles.textarea}>
                    <TextareaAutosize onBlur={onBlur} onChange={onChange} value={value} />
                  </div>
                  {error && <div className="rod-form-error">{error?.message}</div>}
                </>
              )}
            />

            <div className={styles.preview}>
              {(!content || content.length < 1) && <span className={styles.info}>Podgląd newsa</span>}

              <ReactMarkdown children={content} />
            </div>
          </div>

          <div className="rod-form-row-checkbox">
            <div className="rod-form-row-checkbox-line">
              <div className="rod-form-checkbox-wrapper">
                <input type="checkbox" className="ikxBAC" {...register('visible')} />
              </div>
              <span>Opublikowane</span>
            </div>
            {errors.visible && <div className="rod-form-error">{errors.visible?.message}</div>}
          </div>

          <div className="rod-form-legend">
            <ul>
              <li>* - pole wymagane</li>
            </ul>
          </div>

          <div className="rod-form-submit">
            <input type="submit" className="button-primary" value="Zapisz" />
            {isEdit && (
              <Link to={`/news/remove/${defaultValues.id}`} className="button-secondary">
                Usuń
              </Link>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
