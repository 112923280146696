import instance from './instance';

export const login = (token) => {
  return instance.post(
    'login/login',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const checkPin = (token, pin) => {
  return instance.post(
    'login/checkPin',
    {
      pin,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const checkToken = (token) => {
  return instance.get('login/checkToken', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const checkDeviceToken = (token) => {
  return instance.get('login/checkDeviceToken', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

///////////////////////////////////////////////////////////////

export const changePassword = (data) => {
  return instance.post('account/changePassword', data);
};

export const changePin = (data) => {
  return instance.post('account/changePin', data);
};

export const edit = (data) => {
  return instance.put('account/edit', data);
};
