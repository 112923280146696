/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef } from 'react';

const useScroll = () => {
  const headerRef = useRef();
  const lastPosition = useRef(window.scrollY);
  const showRef = useRef(false);

  const fn = useCallback(() => {
    const currentPosition = window.scrollY;

    if (currentPosition > 200) {
      const delta = currentPosition - lastPosition.current;

      if (delta < -5) {
        if (!showRef.current) {
          showRef.current = true;
          headerRef.current.style.top = '0';
        }
      }

      if (delta > 5) {
        if (showRef.current) {
          showRef.current = false;
          headerRef.current.style.top = '-70px';
        }
      }
    } else {
      showRef.current = false;
      headerRef.current.style.top = '-70px';
    }

    lastPosition.current = currentPosition;
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', fn, false);

    return () => {
      document.removeEventListener('scroll', fn, false);
    };
  }, []);

  return headerRef;
};

export default useScroll;
