import Number from '../../../Number';
import Page from '../../../Page';
import { listForFees } from '../../../httpClient/payments';
import { list as settingsList } from '../../../httpClient/settings';
import { list } from '../../../httpClient/waterMeters';
import Bill from '../Bill';
import Demand from '../Demand';
import Raport from '../Raport';
import calculatePrice from '../helpers/calculatePrice';
import mergePayments from '../helpers/mergePayments';
import styles from './List.module.scss';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const List = () => {
  const [dataList, setDataList] = useState([]);
  const [settings, setSettings] = useState();
  const [loaded, setLoaded] = useState(false);
  const [payments, setPayments] = useState({});
  const [openActions, setOpenActions] = useState();
  const year = 2022;

  const lastYear = year - 1;

  useEffect(() => {
    const get = async () => {
      try {
        const responseMeasures = await list(false, lastYear + 1);
        const responseSettings = await settingsList();
        const responsePayments = await listForFees(lastYear + 1);

        setDataList(responseMeasures.data);

        setPayments(mergePayments(responsePayments.data));

        const allSettings = responseSettings.data;

        const lastTwoSettings = [allSettings.find((s) => s.year === lastYear), allSettings.find((s) => s.year === lastYear + 1)];
        const settingsAreCorrect = lastTwoSettings.reduce((acc, curr) => Boolean(curr) && acc, true);

        if (settingsAreCorrect) {
          setSettings(lastTwoSettings);
        } else {
          setSettings(undefined);
        }

        setLoaded(true);
      } catch (e) {
        toast.error('Nie można pobrać danych');
      }
    };

    get();
  }, [lastYear]);

  const allPrices = useMemo(() => {
    if (settings && dataList) {
      return dataList.map((item) => {
        return calculatePrice(item, lastYear, settings);
      });
    }

    return undefined;
  }, [lastYear, settings, dataList]);

  const sums = useMemo(() => {
    if (allPrices && allPrices.length > 0) {
      const keys = Object.keys(allPrices[0]);

      const sum = {};
      keys.forEach((key) => (sum[key] = 0));

      allPrices.forEach((row) => {
        keys.forEach((key) => {
          sum[key] += row[key];
        });
      });

      return sum;
    }

    return {};
  }, [allPrices]);

  return (
    <>
      <Page title="Opłaty roczne 2022">
        <div className={styles.list}>
          <table className="rod-table">
            <thead>
              <tr>
                <th className="rod-table-left">Działka</th>
                <th className="rod-table-left">
                  Powierzchnia [m<sup>2</sup>]
                </th>
                <th className="rod-table-left">Opłata działkowa [zł]</th>
                <th className="rod-table-left">Partycypacja [zł]</th>
                <th className="rod-table-left">Wodomierz</th>
                <th className="rod-table-left">Odczyt wodomierza</th>
                <th className="rod-table-left">Opłata wodna</th>
                <th className="rod-table-left">
                  Zuzycie wody w {lastYear} [m<sup>3</sup>]
                </th>
                <th className="rod-table-left">Saldo za wodę [zł]</th>
                <th className="rod-table-left">Ryczałt [zł]</th>
                <th className="rod-table-left">Woda [zł]</th>
                <th className="rod-table-left">Razem [zł]</th>
                <th className="rod-table-left">Płatność</th>
                <th className="rod-table-right">Akcje</th>
              </tr>
            </thead>

            <tbody>
              {loaded && (
                <>
                  {dataList && settings && allPrices ? (
                    dataList.map((item, index) => {
                      const prices = allPrices[index];

                      const payment = payments[item.id];
                      let delta = 0;
                      let triedPayment = false;

                      if (payment) {
                        triedPayment = true;
                        delta = payment - prices.total;
                      }

                      const hasWatermeter = Boolean(item.waterMeter);

                      return (
                        <tr key={item.userId}>
                          <td>
                            <Link to={`/users/edit/${item.userId}`}>{item.userId}</Link>
                          </td>
                          <td>{item.area}</td>
                          <td>
                            <Number value={prices.gardenFee} />
                          </td>
                          <td>
                            <Number value={prices.participation} />
                          </td>
                          <td>{hasWatermeter ? 'tak' : '-'}</td>
                          <td>{hasWatermeter ? settings[0].waterMeterReading : '-'}</td>
                          <td>{hasWatermeter ? settings[0].waterFee : '-'}</td>
                          <td>
                            {hasWatermeter ? (
                              prices.correctData ? (
                                <Number value={prices.usedWater} />
                              ) : (
                                <span className={styles.error}>niepoprawny odczyt</span>
                              )
                            ) : (
                              '-'
                            )}{' '}
                          </td>
                          <td>{prices.correctData && hasWatermeter ? <Number value={prices.saldo} /> : '-'}</td>
                          <td>{prices.correctData && hasWatermeter ? <Number value={prices.lumpSum} /> : '-'}</td>
                          <td>{prices.correctData && <Number value={prices.waterFee} />}</td>
                          <td>{prices.correctData && <Number value={prices.total} />}</td>
                          <td>{triedPayment ? delta === 0 ? '✔' : <Number value={delta} /> : '-'}</td>
                          <td className="rod-table-right">
                            <div className={styles.more}>
                              <div
                                className={styles.moreButton}
                                onClick={() => {
                                  setOpenActions(openActions === item.id ? undefined : item.id);
                                }}
                              >
                                {openActions === item.id ? 'Zamknij' : 'Pokaż'}
                              </div>
                              {openActions === item.id && prices.correctData && (
                                <ul>
                                  <li>
                                    <Bill data={item} settings={settings} prices={prices} />
                                  </li>
                                  <li>
                                    <Demand data={item} prices={prices} />
                                  </li>
                                </ul>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={14}>
                        <div className="rod-table-no-data">
                          Brak danych
                          {!settings && ' (ustawień)'}
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              )}

              <tr key={sums}>
                <th className="rod-table-left"></th>
                <th className="rod-table-left">
                  <Number decimalScale={0} value={sums.area} />
                </th>
                <th className="rod-table-left">
                  <Number value={sums.gardenFee} />
                </th>
                <th className="rod-table-left">
                  <Number value={sums.participation} />
                </th>
                <th className="rod-table-left"></th>
                <th className="rod-table-left"></th>
                <th className="rod-table-left"></th>
                <th className="rod-table-left">
                  <Number value={sums.usedWater} />
                </th>
                <th className="rod-table-left"></th>
                <th className="rod-table-left"></th>
                <th className="rod-table-left">
                  <Number value={sums.waterFee} />
                </th>
                <th className="rod-table-left">
                  <Number value={sums.total} />
                </th>
                <th className="rod-table-left"></th>
                <th className="rod-table-left"></th>
              </tr>
            </tbody>
          </table>
        </div>
      </Page>

      <div className={styles.generate}>
        <Raport data={dataList} lastYear={lastYear} settings={settings} />
      </div>
    </>
  );
};

export default List;
