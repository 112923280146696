/* eslint-disable react-hooks/exhaustive-deps */
// import RobotoBlackFont from '../../../assets/Roboto-Black.ttf';
// import RobotoFont from '../../../assets/Roboto.ttf';
import PriceFormater from '../../Price/PriceConverter';
import LoraBlackFont from '../../assets/Lora-Bold.ttf';
import LoraFont from '../../assets/Lora-Regular.ttf';
import logoUrl from '../../assets/logofull.png';
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useMemo } from 'react';

const watermark = false;

Font.register({
  family: 'Lora',
  fonts: [
    { src: LoraFont }, // font-style: normal, font-weight: normal
    { src: LoraBlackFont, fontWeight: 700 },
  ],
});

const priceFormater = new PriceFormater();
priceFormater.format = { myFormat: 'zl-words zl gr-short' };

const tableColumnSizes = [5, 50, 15, 15, 15];
const fixes = [0, 0, 2, 1, 2];

const Row = ({ values, aligns = undefined, weights = undefined }) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {tableColumnSizes.map((size, index) => (
        <View
          key={index}
          style={[
            styles.cell,
            {
              width: `${size}%`,
              padding: '4px',
            },
          ]}
        >
          <Text
            style={{
              textAlign: aligns ? aligns[index] : 'left',
              fontWeight: weights ? weights[index] : 400,
            }}
          >
            {typeof values[index] === 'number' ? values[index].toFixed(fixes[index]) : values[index].toString()}
          </Text>
        </View>
      ))}
    </View>
  );
};

const proportion = ([w, h], height) => {
  return {
    height,
    width: (w / h) * height,
  };
};

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 9,
    fontFamily: 'Lora',
  },
  header: {
    borderBottom: '3px solid #292929',
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'row',
  },
  headerChild: {},
  dotted: {
    borderBottom: '1px dotted #292929',
  },
  bold: {
    fontWeight: 700,
  },
  cell: {
    borderRight: '1px solid #979797',
    borderBottom: '1px solid #979797',
  },
  table: {
    borderTop: '1px solid #979797',
    borderLeft: '1px solid #979797',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '3px',
  },
  greetings: {
    padding: '15px 0',
    borderBottom: '3px solid #292929',
    textAlign: 'center',
    fontSize: 12,
  },
  pageNumber: {
    position: 'absolute',
    bottom: 30,
    left: 35,
    right: 35,
  },
  payment: {
    backgroundColor: '#292929',
    width: '170px',
    margin: '10px 0',
    padding: '3px 10px',
    color: 'white',
    fontWeight: 700,
    fontSize: 13,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    padding: '10px 0',
  },
  more: {
    marginBottom: 3,
    fontSize: 8,
  },
  waterHeader: {
    marginBottom: 3,
    width: 88,
    backgroundColor: '#f1f1f1',
    padding: '1px 3px',
  },
  watermark: {
    position: 'absolute',
    top: 20,
    left: 0,
    right: 0,
    bottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.2,
  },
  watermarkText: {
    fontSize: 70,
    fontWeight: 700,
    transform: 'rotate(-45deg)',
  },
  description: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  frame: {
    flex: 1,
  },
  frameTexts: {
    marginTop: 16,
    border: '1px solid #979797',
    padding: 10,
  },
  scanAndPay: {
    marginLeft: 35,
  },
  pay: {
    alignItems: 'center',
    marginBottom: 5,
  },
  payText: {
    fontWeight: 700,
  },
  info: {
    fontSize: 7,
    marginBottom: 20,
  },
  warning: {
    fontSize: 8,
    fontWeight: 700,
  },
});

const Empty = <View />;

const PdfDocument = ({ qrUrls, barUrls, data, settings, prices, year }) => {
  return (
    <Document>
      {data.map((item, index) => (
        <SinglePage
          key={index}
          data={item}
          settings={settings}
          prices={prices[index]}
          year={year}
          barUrl={barUrls[index]}
          qrUrl={qrUrls[index]}
        />
      ))}
    </Document>
  );
};

const SinglePage = ({ settings, prices, data, year, barUrl, qrUrl }) => {
  // console.log(barUrl, qrUrl)

  const rows = useMemo(() => {
    const result = [
      ['Opłata działkowa', settings.gardenFee, data.area, prices.gardenFee],
      ['Partycypacja', settings.participation, data.area, prices.participation],
      ['Składka członkowska', settings.membershipFee, 1, prices.membershipFee],
    ];

    result.push(['Fundusz remontowy', settings.renovationFund, 1, settings.renovationFund]);
    result.push(['Wywóz odpadów - zaliczka', prices.garbageLoan, 1, prices.garbageLoan]);

    return result.filter(Boolean);
  }, []);

  return (
    <Page style={styles.body}>
      <View style={styles.header}>
        <View style={{ width: '50%' }}>
          <Image src={logoUrl} style={{ ...proportion([647, 307], 50), marginTop: 12 }} />
        </View>
        <View style={{ width: '50%', textAlign: 'right' }}>
          <Text style={[styles.bold, { fontSize: 8 }]}>
            Sygnatura: OP/{data.id}/{year}
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              marginTop: 10,
              marginRight: -4,
            }}
          >
            <Image src={barUrl} style={{ ...proportion([266, 80], 40) }} />
          </View>
        </View>
      </View>

      <View style={[styles.dotted, styles.title, styles.flexRow]}>
        <View>
          <Text style={[styles.bold, { fontSize: 17 }]}>
            RACHUNEK: OP/{data.id}/{year}
          </Text>
        </View>
      </View>

      <View style={[styles.dotted, styles.flexRow]}>
        <View style={{ width: '30%', padding: '10px 0' }}>
          <Text>
            Data wystawienia: <Text style={styles.bold}>24.05.{year}</Text>
          </Text>
          <Text>
            Termin płatności: <Text style={styles.bold}>30.06.{year}</Text>
          </Text>
        </View>
        <View style={{ width: '35%', padding: '10px 0' }}>
          <Text style={styles.bold}>Wystawiający</Text>
          <Text>Rodzinne Ogrody Działkowe im. 1 Maja</Text>
          <Text>ul. 1 Maja b/n</Text>
          <Text>71-627 Szczecin</Text>
          <Text>NIP: 851-25-94-219</Text>
        </View>
        <View style={{ width: '35%', backgroundColor: '#e3e3e3', padding: '10px' }}>
          <Text style={styles.bold}>Płatnik (działka nr {data.id})</Text>
          <Text>
            {data.names}
            {data.hasTwoOwners ? ',' : ''}
          </Text>
          {data.hasTwoOwners ? <Text>{data.subOwnerNames}</Text> : Empty}
          {data.street ? (
            <Text>
              ul. {data.street} {data.building}
              {data.flat && `/${data.flat}`}
            </Text>
          ) : (
            Empty
          )}
          <Text>
            {data.postcode} {data.city}
          </Text>
        </View>
      </View>

      <View style={[styles.table, { margin: '20px 0' }]}>
        <Row
          sizes={tableColumnSizes}
          values={['Lp.', 'Nazwa opłaty cząstkowej', 'Cena jedn.', 'Ilość', 'Wartość brutto']}
          weights={[700, 700, 700, 700, 700]}
          aligns={['center', 'center', 'center', 'center', 'center']}
        />

        {rows.map((row, index) => (
          <Row key={row} sizes={tableColumnSizes} aligns={['center', 'left', 'right', 'right', 'right']} values={[index + 1, ...row]} />
        ))}

        <View style={styles.flexRow}>
          <View
            style={[
              styles.cell,
              {
                width: '85%',
                padding: '4px',
                textAlign: 'right',
                backgroundColor: '#e3e3e3',
              },
            ]}
          >
            <Text style={{ fontWeight: 700 }}>Razem:</Text>
          </View>

          <View
            style={[
              styles.cell,
              {
                width: '15%',
                padding: '4px',
                textAlign: 'right',
              },
            ]}
          >
            <Text>{prices.total.toFixed(2)}</Text>
          </View>
        </View>
      </View>

      <View style={styles.dotted} />

      <View style={styles.payment}>
        <Text>Do zapłaty: {prices.total.toFixed(2)} zł</Text>
      </View>

      <View>
        <View style={styles.row}>
          <View style={{ width: '80px' }}>
            <Text>Słownie:</Text>
          </View>
          <View>
            <Text>{priceFormater.convert(prices.total)}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={{ width: '80px' }}>
            <Text>Forma płatności:</Text>
          </View>
          <View>
            <Text>przelew</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={{ width: '80px' }}>
            <Text>Numer konta:</Text>
          </View>
          <View>
            <Text>56 1240 3972 1111 0010 3563 6279</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={{ width: '80px' }}>
            <Text>Tytuł przelewu:</Text>
          </View>
          <View>
            <Text>
              OP/{data.id}/{year}
            </Text>
          </View>
        </View>
      </View>

      <View style={[styles.dotted, { marginTop: '7px' }]} />

      <View style={[styles.description]}>
        <View style={styles.frame}>
          <View style={styles.frameTexts}>
            <Text style={[styles.info]}>
              Zgodnie z Uchwałą ZW/5/2023 w roku 2023 wystawione zostaną dwa rachunki. Pierwszy, płatny do 30 czerwca, stanowiący opłatę
              ogrodową wraz z zaliczkowaną opłatą za wywóz odpadów. Drugi rachunek, płatny do 30 listopada, wystawiony zostanie po
              zakończeniu sezonu i będzie stanowił rozliczenie zużycia wody i opłaty za wywóz odpadów.
            </Text>

            <Text style={styles.warning}>Przy dokonywaniu płatności prosimy podać właściwy tytuł.</Text>
          </View>
        </View>
        <View style={styles.scanAndPay}>
          <View style={styles.pay}>
            <Text style={styles.payText}>Zeskanuj i zapłać</Text>
          </View>
          <View>
            <Image src={qrUrl} style={{ ...proportion([128, 128], 100) }} />
          </View>
        </View>
      </View>

      {watermark && (
        <View style={styles.watermark} fixed>
          <Text style={styles.watermarkText}>To nie jest rachunek</Text>
        </View>
      )}

      <View style={styles.pageNumber}>
        <View style={styles.dotted} />

        <View style={styles.greetings}>
          <Text>Życzymy radosnych chwil spędzonych w naszym ogrodzie</Text>
        </View>

        <View style={[styles.row, { fontSize: 7, marginTop: '4px' }]}>
          <View>
            <Text>Dokument wygenerowany przez oprogramowanie stworzone przez Bartosza Maciuraka</Text>
          </View>
          <View style={{ textAlign: 'right', width: '50%' }}>
            <Text>Strona 1 z 1</Text>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default PdfDocument;
