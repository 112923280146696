/* eslint-disable react-hooks/exhaustive-deps */
import font from '../../assets/Roboto.ttf';
import { list } from '../../httpClient/users';
import split from '../../utils/split';
import { Document, Font, PDFDownloadLink, PDFViewer, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';

// Register Font
Font.register({
  family: 'Roboto',
  format: 'truetype',
  src: font,
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 9,
    fontFamily: 'Roboto',
  },

  title: {
    fontSize: '14px',
    textAlign: 'center',
    marginBottom: '20px',
  },

  bold: {
    fontWeight: 700,
  },

  header: {
    borderBottom: '1px solid #292929',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
  },

  content: {
    display: 'flex',
  },

  center: {
    textAlign: 'center',
  },

  row: {
    borderBottom: '1px solid #292929',
    borderLeft: '1px solid #292929',
    borderRight: '1px solid #292929',
    padding: '8px',
  },

  garden: {
    color: '#818181',
    fontSize: '8px',
  },
});

const SinglePage = ({ title, data, page, pages }) => {
  return (
    <>
      <View style={styles.title}>
        <Text style={[styles.bold]}>
          {title} ({page}/{pages})
        </Text>
      </View>

      <View style={styles.header}>
        <View style={{ width: '50%' }}>
          <Text style={[styles.bold, styles.center]}>Imię i nazwisko</Text>
        </View>
        <View style={{ width: '50%' }}>
          <Text style={[styles.bold, styles.center]}>Data i podpis</Text>
        </View>
      </View>

      <View style={styles.content}>
        {data.map((user, index) => (
          <View style={styles.row} key={index}>
            <Text>{user.names}</Text>
            <Text style={[styles.garden]}>Działka nr {user.id}</Text>
          </View>
        ))}
      </View>

      <Text break />
    </>
  );
};

const MyHtml = ({ data, title }) => {
  const pages = split(data, 17);

  return (
    <Document>
      <Page style={styles.body} wrap={true}>
        {pages.map((page, index) => (
          <SinglePage title={title} key={index} data={page} page={index + 1} pages={pages.length} />
        ))}
      </Page>
    </Document>
  );
};

const makeUsers = (users) => {
  const arr = [];

  users.forEach((u) => {
    arr.push({
      names: u.names,
      id: u.id,
    });

    if (Boolean(u.hasTwoOwners)) {
      arr.push({
        names: u.subOwnerNames,
        id: u.id,
      });
    }
  });

  return arr;
};

const Modal = ({ handleClose, title }) => {
  const [show, setShow] = useState(false);
  const [enhancedUsers, setEnhancedUsers] = useState();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await list();

        setEnhancedUsers(makeUsers(response.data));
      } catch (e) {
        handleClose();
      }
    };

    getData();
  }, [setEnhancedUsers]);

  useEffect(() => {
    if (enhancedUsers) {
      setTimeout(() => {
        setShow(true);
      }, 2000);
    }
  }, [enhancedUsers]);

  return (
    <div className="rod-modal">
      {enhancedUsers && (
        <div className="rod-modal-hidden ">
          <PDFViewer>
            <MyHtml data={enhancedUsers} title={title} />
          </PDFViewer>
        </div>
      )}

      <div className="rod-modal-container">
        {!show && <div className="rod-modal-loading">Generowanie PDF...</div>}

        {show && (
          <div>
            <div>
              <PDFDownloadLink
                className="button-primary rod-modal-button"
                document={<MyHtml data={enhancedUsers} title={title} />}
                fileName="lista.pdf"
              >
                {({ blob, url, loading, error }) => (loading ? 'Tworzenie łącza...' : 'Pobierz PDF')}
              </PDFDownloadLink>
            </div>
            <div className="rod-modal-close">
              <button onClick={handleClose}>Zamknij okno</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const GardenList = ({ title }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className="button-primary rod-modal-button"
        onClick={() => {
          setOpen(true);
        }}
      >
        PDF
      </button>

      {open && (
        <Modal
          title={title}
          handleClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default GardenList;
