import instance from './instance';

export const send = (email, name, garden, attachment) => {
  return instance.post('mails/send', {
    email,
    name,
    garden,
    attachment,
  });
};
