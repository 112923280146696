/* eslint-disable react-hooks/exhaustive-deps */
import LoraBlackFont from '../../assets/Lora-Bold.ttf';
import LoraFont from '../../assets/Lora-Regular.ttf';
import PriceFormater from '../../fees/Price/PriceConverter';
import logoUrl from '../../fees/assets/logofull.png';
import { list } from '../../httpClient/users';
import { UsersListState } from '../../store/users';
import { Document, Font, Image, PDFDownloadLink, PDFViewer, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useBarcode } from 'react-barcodes';
import { useRecoilState } from 'recoil';

// Register Font
Font.register({
  family: 'Lora',
  fonts: [
    { src: LoraFont }, // font-style: normal, font-weight: normal
    { src: LoraBlackFont, fontWeight: 700 },
  ],
});

const priceFormater = new PriceFormater();
priceFormater.format = { myFormat: 'zl-words zl gr-short' };

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 9,
    fontFamily: 'Lora',
  },
  header: {
    borderBottom: '3px solid #292929',
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'row',
  },
  dotted: {
    borderBottom: '1px dotted #292929',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    padding: '10px 0',
  },
  bold: {
    fontWeight: 700,
  },
  content: {
    marginTop: 30,
    marginBottom: 30,
  },
  pageNumber: {
    position: 'absolute',
    bottom: 30,
    left: 35,
    right: 35,
  },
  greetings: {
    padding: '15px 0',
    borderBottom: '3px solid #292929',
    textAlign: 'center',
    fontSize: 12,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '3px',
  },
  payment: {
    backgroundColor: '#292929',
    width: '170px',
    margin: '10px 0',
    padding: '3px 10px',
    color: 'white',
    fontWeight: 700,
    fontSize: 13,
  },
  bold2: {
    fontWeight: 700,
  },
});

const proportion = ([w, h], height) => {
  return {
    height,
    width: (w / h) * height,
  };
};

const Empty = <View />;

const MyHtml = ({ data, barUrl }) => {
  const userDetails = data;
  const price = 609.83;

  return (
    <Document>
      <Page style={styles.body} wrap={true}>
        <View style={styles.header}>
          <View style={{ width: '50%' }}>
            <Image src={logoUrl} style={{ ...proportion([647, 307], 50), marginTop: 12 }} />
          </View>
          <View style={{ width: '50%', textAlign: 'right' }}>
            <Text style={[styles.bold, { fontSize: 8 }]}>Sygnatura: EL/{data.id}</Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                marginTop: 10,
                marginRight: -4,
              }}
            >
              <Image src={barUrl} style={{ ...proportion([266, 80], 40) }} />
            </View>
          </View>
        </View>

        <View style={[styles.dotted, styles.title, styles.flexRow]}>
          <View>
            <Text style={[styles.bold, { fontSize: 17 }]}>WEZWANIE DO ZAPŁATY - SIEĆ ELEKTROENERGETYCZNA</Text>
          </View>
        </View>

        <View style={[styles.dotted, styles.flexRow]}>
          <View style={{ width: '30%', padding: '10px 0' }}>
            <Text>
              Data wystawienia: <Text style={styles.bold}>16.11.2023</Text>
            </Text>
            <Text>
              Termin płatności: <Text style={styles.bold}>16.12.2023</Text>
            </Text>
          </View>
          <View style={{ width: '35%', padding: '10px 0' }}>
            <Text style={styles.bold}>Wystawiający</Text>
            <Text>Rodzinne Ogrody Działkowe im. 1 Maja</Text>
            <Text>ul. 1 Maja b/n</Text>
            <Text>71-627 Szczecin</Text>
            <Text>NIP: 851-25-94-219</Text>
          </View>
          <View style={{ width: '35%', backgroundColor: '#e3e3e3', padding: '10px' }}>
            <Text style={styles.bold}>Płatnik (działka nr {userDetails.id})</Text>
            <Text>{userDetails.names}</Text>
            {userDetails.street ? (
              <Text>
                ul. {userDetails.street} {userDetails.building}
                {userDetails.flat && `/${userDetails.flat}`}
              </Text>
            ) : (
              Empty
            )}
            <Text>
              {userDetails.postcode} {userDetails.city}
            </Text>
          </View>
        </View>

        <View style={styles.content}>
          <Text
            style={{
              lineHeight: 1.75,
              textAlign: 'justify',
            }}
          >
            Zarząd ROD im. 1 Maja w Szczecinie wzywa Panią/Pana do zapłaty kwoty <Text style={styles.bold2}>{price.toFixed(2)} zł</Text> z
            tytułu opłaty inwestycyjnej na podłączenie do sieci elektronengetycznej dla działki nr {userDetails.id} w ROD im. 1 Maja w
            Szczecinie wraz z ustawowymi odsetkami za opóźnieninie od dnia 30 listopada 2021 r. do dnia zapłaty, wynikającej z Uchwały
            Walnego Zebrania nr 11/2021 z dnia 17 lipca 2021 r.
          </Text>
          <Text
            style={{
              marginTop: 20,
              textAlign: 'justify',
            }}
          >
            Zapłata ww. kwoty winna nastąpić na wskazany nr konta w terminie <Text style={styles.bold2}>miesiąca</Text> od dnia otrzymania
            przedmiotowego pisma.
          </Text>
          <Text
            style={{
              marginTop: 20,
              lineHeight: 1.75,
              fontWeight: 700,
              textAlign: 'justify',
            }}
          >
            Jednocześnie Zarząd ROD im. 1 Maja w Szczecinie wskazuje, że brak zapłaty kwoty z tytułu opłaty inwestycyjnej na podłączenie do
            sieci elektroenergetycznej dla działki nr {userDetails.id} w ROD im. 1 Maja w Szczecinie wraz z ustawowymi odsetkami za
            opóźnienie od dnia 30 listopada 2021 r. do dnia zapłaty wynikającej z Uchwały Walnego Zebrania nr 11/2021 z dnia 17 lipca 2021
            r. we wskazanym powyżej terminie sprawi, że ROD im. 1 Maja w Szczecinie wystąpi na drogę sądową.
          </Text>
          <Text
            style={{
              marginTop: 20,
              lineHeight: 1.75,
              fontWeight: 700,
              textAlign: 'justify',
            }}
          >
            Dodatkowo, Zarząd ROD im. 1 Maja w Szczecinie informuje, że może dokonać zgodnie z art. 35 ust. 3 pkt. 2 ustawy o rodzinnych
            ogrodach działkowych z dnia 13 grudnia 2013 r. wypowiedzenia umowy dzierżawy działkowej.
          </Text>
        </View>

        <View style={styles.dotted} />

        <View style={styles.payment}>
          <Text>Do zapłaty: {price.toFixed(2)} zł</Text>
        </View>

        <View>
          <View style={styles.row}>
            <View style={{ width: '80px' }}>
              <Text>Słownie:</Text>
            </View>
            <View>
              <Text>{priceFormater.convert(price)}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{ width: '80px' }}>
              <Text>Forma płatności:</Text>
            </View>
            <View>
              <Text>przelew</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{ width: '80px' }}>
              <Text>Numer konta:</Text>
            </View>
            <View>
              <Text>56 1240 3972 1111 0010 3563 6279</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{ width: '80px' }}>
              <Text>Tytuł przelewu:</Text>
            </View>
            <View>
              <Text>EL/{data.id}</Text>
            </View>
          </View>
        </View>

        <View style={[styles.dotted, { marginTop: '7px' }]} />

        <View style={styles.pageNumber}>
          <View style={styles.dotted} />

          <View style={styles.greetings}>
            <Text>Prosimy o pilne uregulowanie należności!</Text>
          </View>

          <View style={[styles.row, { fontSize: 7, marginTop: '4px' }]}>
            <View>
              <Text>Dokument wygenerowany przez oprogramowanie stworzone przez Bartosza Maciuraka</Text>
            </View>
            <View style={{ textAlign: 'right', width: '50%' }}>
              <Text>Strona 1 z 1</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const Modal = ({ handleClose }) => {
  const [users, setUsers] = useRecoilState(UsersListState);
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(0);
  const barRef = useRef();
  const [barUrl, setBarUrl] = useState();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await list();

        setUsers(response.data);
      } catch (e) {
        handleClose();
      }
    };

    getData();
  }, [setUsers]);

  useEffect(() => {
    if (users) {
      setTimeout(() => {
        setShow(true);
      }, 2000);
    }
  }, [users]);

  const data = useMemo(() => {
    if (!users || users.length < 1) {
      return null;
    }

    const arr = [users[selectedUser]];

    return arr;
  }, [users, selectedUser]);

  const { inputRef } = useBarcode({
    value: `EL/${data ? data[0].id : ''}`,
    options: {
      displayValue: false,
      height: 60,
    },
  });

  useEffect(() => {
    if (inputRef.current) {
      const dataURL = inputRef.current.toDataURL();

      if (dataURL) {
        setBarUrl(dataURL);
      }
    }
  }, []);

  return (
    <div className="rod-modal">
      <div className="rod-modal-hidden ">
        <div ref={barRef}>
          <canvas ref={inputRef} />
        </div>

        {data && data[0] && barUrl && (
          <PDFViewer>
            <MyHtml data={data[0]} barUrl={barUrl} />
          </PDFViewer>
        )}
      </div>

      <div className="rod-modal-container">
        {!show && <div className="rod-modal-loading">Generowanie PDF...</div>}

        {show && data && barUrl && (
          <div>
            <div>
              <div className="rod-form-row">
                <select
                  onChange={(e) => {
                    setSelectedUser(e.target.value);
                  }}
                >
                  {users &&
                    users.map((u, index) => (
                      <option key={index} value={index}>
                        {u.names}
                      </option>
                    ))}
                </select>
              </div>

              <PDFDownloadLink
                className="button-primary rod-modal-button"
                document={<MyHtml data={data[0]} barUrl={barUrl} />}
                fileName="wezwanie-oplata-za-prad.pdf"
              >
                {({ blob, url, loading, error }) => (loading ? 'Tworzenie łącza...' : 'Pobierz PDF')}
              </PDFDownloadLink>
            </div>
            <div className="rod-modal-close">
              <button onClick={handleClose}>Zamknij okno</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Monit = ({ title }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className="rod-table-button-special"
        onClick={() => {
          setOpen(true);
        }}
      >
        PDF
      </button>

      {open && (
        <Modal
          title={title}
          handleClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Monit;
