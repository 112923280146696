import Page from '../../Page';
import { edit, value } from '../../httpClient/waterMeters';
import FixedNumberInput from '../../utils/FixedNumberInput';
import { NOT_NUMBER, REQUIRED } from '../../utils/formTexts';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup
  .object({
    value: yup.number().typeError(NOT_NUMBER).required(REQUIRED),
  })
  .required();

const Edit = () => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      value: '',
    },
  });

  const { id, parent } = useParams();

  useEffect(() => {
    const get = async () => {
      try {
        const response = await value(id);

        if (response.data.value) {
          setValue('value', response.data.value * 1000);
        }
      } catch {
        toast.error('Nie można pobrać danych');
      }
    };

    get();
  }, [id]);

  const onSubmit = async (data) => {
    try {
      await edit(id, data.value / 1000);

      toast.success('Dane zostały zapisane');
    } catch (e) {}
  };

  return (
    <>
      <Page
        title="Edycja odczytu wodomierza"
        back={`/watermeters/details/${parent}`}
        breadcrumbs={[
          ['/watermeters', 'Wodomierze'],
          [`/watermeters/details/${parent}`, `Działka nr ${parent}`],
        ]}
      >
        <div className="rod-form">
          <div className="rod-form-content">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="rod-form-row">
                <label>Wartość odczytu *</label>

                <Controller
                  control={control}
                  name="value"
                  render={({ field, formState }) => <FixedNumberInput setValue={setValue} field={field} fractionDigits={3} intDigits={5} />}
                />

                {errors.value && <div className="rod-form-error">{errors.value?.message}</div>}
              </div>

              <div className="rod-form-legend">
                <ul>
                  <li>* - pole wymagane</li>
                </ul>
              </div>

              <div className="rod-form-submit">
                <input type="submit" className="button-primary" value="Zapisz" />
                <input
                  className="button-secondary"
                  type="button"
                  value="Reset"
                  onClick={() => {
                    reset();
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </Page>
    </>
  );
};

export default Edit;
