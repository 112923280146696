import { atom } from 'recoil';

export const LoggedAccount = atom({
  key: 'LoggedAccount',
  default: undefined,
});

export const Stage = atom({
  key: 'Stage',
  default: 0,
});
