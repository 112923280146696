import { add, edit } from '../../httpClient/payments';
import { NOT_DATE, NOT_INTEGER_NUMBER, NOT_NUMBER, NOT_POSITIVE_NUMBER, REQUIRED } from '../../utils/formTexts';
import useUsers from './useUsers';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import 'yup-phone';

const TIME_FORMAT = 'yyyy-MM-dd';

const schema = yup
  .object({
    time: yup.date().typeError(NOT_DATE).required(REQUIRED),
    refNr: yup.string().required(REQUIRED),
    inOut: yup.number().typeError(NOT_NUMBER).integer(NOT_INTEGER_NUMBER).required(REQUIRED),
    value: yup.number().typeError(NOT_NUMBER).positive(NOT_POSITIVE_NUMBER).required(REQUIRED),
    accountId: yup.number().typeError(NOT_NUMBER).integer(NOT_INTEGER_NUMBER).required(REQUIRED),
    paymentType: yup.number().typeError(NOT_NUMBER).integer(NOT_INTEGER_NUMBER).required(REQUIRED),
    gardenId: yup.number().typeError(NOT_NUMBER).integer(NOT_INTEGER_NUMBER).required(REQUIRED),
  })
  .required();

const Form = ({ isEdit = false, defaultValues = undefined, id }) => {
  const users = useUsers();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    try {
      data.time = format(data.time, TIME_FORMAT);

      if (isEdit) {
        await edit(id, data);

        toast.success('Dane zostały zapisane');
      } else {
        await add(data);

        toast.success('Płatność dodana');
      }
    } catch (e) {
      toast.error('Nie udało się');
    }
  };

  if (!users) {
    return null;
  }

  return (
    <div className="rod-form">
      <div className="rod-form-content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="rod-form-row">
            <label>Data *</label>
            <Controller
              control={control}
              name="time"
              render={({ field }) => (
                <ReactDatePicker dateFormat={TIME_FORMAT} onChange={(e) => field.onChange(e)} selected={field.value} />
              )}
            />
            {errors.time && <div className="rod-form-error">{errors.time?.message}</div>}
          </div>
          <div className="rod-form-row">
            <label>Numer referencyjny *</label>
            <input type="text" {...register('refNr')} />
            {errors.refNr && <div className="rod-form-error">{errors.refNr?.message}</div>}
          </div>
          <div className="rod-form-row">
            <label>Kwota</label>
            <input type="text" {...register('value')} />
            {errors.value && <div className="rod-form-error">{errors.value?.message}</div>}
          </div>
          <div className="rod-form-row">
            <label>Wpływ / wydatek *</label>
            <select {...register('inOut')}>
              <option value={1}>wpływ</option>
              <option value={-1}>wydatek</option>
            </select>
            {errors.inOut && <div className="rod-form-error">{errors.inOut?.message}</div>}
          </div>
          <div className="rod-form-row">
            <label>Dla rachunku *</label>
            <select {...register('accountId')}>
              <option value={0}>Rachunek "Bieżący - podmiot gosp."</option>
              <option value={1}>Rachunek "Rach. Dobry Zysk dla Firmy"</option>
            </select>
            {errors.accountId && <div className="rod-form-error">{errors.accountId?.message}</div>}
          </div>
          <div className="rod-form-row">
            <label>Wewnętrzny typ płatności *</label>
            <select {...register('paymentType')}>
              <option value={0}>-</option>
              <option value={1}>Opłata ogrodowa</option>
              <option value={2}>Opłata inwestycyjna - prąd</option>
              <option value={3}>Media</option>
            </select>
            {errors.paymentType && <div className="rod-form-error">{errors.paymentType?.message}</div>}
          </div>

          <div className="rod-form-row">
            <label>Zrealizowane przez</label>
            <select {...register('gardenId')}>
              <option value={-1}>podmiot zewnętrzny</option>
              <option value={0}>Zarząd ROD</option>

              {users?.map((u) => (
                <option key={u.id} value={u.id}>
                  {u.id}. {u.names}
                </option>
              ))}
            </select>
            {errors.gardenId && <div className="rod-form-error">{errors.gardenId?.message}</div>}
          </div>

          <div className="rod-form-row">
            <label>Opis</label>
            <input type="text" {...register('description')} />
            {errors.description && <div className="rod-form-error">{errors.description?.message}</div>}
          </div>

          <div className="rod-form-legend">
            <ul>
              <li>* - pole wymagane</li>
            </ul>
          </div>

          <div className="rod-form-submit">
            <input type="submit" className="button-primary" value="Zapisz" />

            {isEdit && (
              <Link to={`remove/${defaultValues.id}`} className="button-secondary">
                Usuń
              </Link>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
