import NumberFormat from 'react-number-format';

const types = {
  none: '',
  pln: 'zł',
  m2: (
    <>
      m<sup>2</sup>
    </>
  ),
  m3: (
    <>
      m<sup>3</sup>
    </>
  ),
};

const Number = ({ value, decimalScale = 2, type = 'none' }) => {
  return (
    <>
      <NumberFormat
        key={value}
        value={value}
        displayType={'text'}
        thousandSeparator={' '}
        decimalScale={decimalScale}
        fixedDecimalScale={true}
      />
      &nbsp;
      {types[type]}
    </>
  );
};

export default Number;
