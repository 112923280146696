if (Number.EPSILON === undefined) {
  Number.EPSILON = Math.pow(2, -52);
}

const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

const calculatePrice = (data, lastYear, settings) => {
  const settingsCurrentYear = settings[1];
  const settingsLastYear = settings[0];

  const prices = {
    gardenFee: data.area * settingsCurrentYear.gardenFee,
    participation: data.area * settingsCurrentYear.participation,
    correctData: true,
    usedWater: 0,
    waterFee: 0,
    total: -1,
    saldo: 0,
    lumpSum: 0,
  };

  const hasAccessToWater = data.id !== 2;

  if (!data.waterMeter) {
    prices.waterFee = data.area * settingsCurrentYear.waterPriceForArea;
  } else {
    const measures = data.measures[lastYear].sort((a, b) => {
      return b.time - a.time;
    });

    if (measures.length > 0 && measures.length % 2 === 0) {
      let usedWater = 0;

      for (let i = 0; i < measures.length; i += 2) {
        usedWater += measures[i + 1].value - measures[i].value;
      }

      prices.usedWater = round(usedWater);

      const saldo = (5.0 - usedWater) * settingsLastYear.waterPriceForWatermeter;
      const lumpSum = 5.0 * settingsCurrentYear.waterPriceForWatermeter;

      prices.waterFee = round(lumpSum - saldo);
      prices.saldo = round(saldo);
      prices.lumpSum = round(lumpSum);
    } else {
      prices.correctData = false;
    }
  }

  if (prices.correctData) {
    prices.total = round(
      prices.gardenFee +
        prices.participation +
        (hasAccessToWater ? prices.waterFee : 0) +
        // settingsCurrentYear.waterFee +
        // (data.waterMeter ? settingsCurrentYear.waterMeterReading : 0) +
        settingsCurrentYear.renovationFund +
        settingsCurrentYear.membershipFee
    );
  }

  prices.area = data.area;

  return prices;
};

export default calculatePrice;
