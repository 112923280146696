import Page from '../../Page';
import Removing from '../../Removing';
import { remove } from '../../httpClient/checklists';
import { useParams } from 'react-router-dom';

const Remove = () => {
  const { id } = useParams();

  return (
    <Page back="/checklists" breadcrumbs={[['/checklists', 'Check-listy']]} title="Usuwanie check-listy">
      <Removing url="/checklists" title="Czy na pewno chcesz usunąć check-listę?" action={() => remove(id)} />
    </Page>
  );
};

export default Remove;
