import { calculatePricesForMedia } from '../Media/helpers';

if (Number.EPSILON === undefined) {
  Number.EPSILON = Math.pow(2, -52);
}

const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

const calculatePrice = (data, settings, usage, usagesSummary) => {
  const prices = {
    membershipFee: settings.membershipFee,
    gardenFee: data.area * settings.gardenFee,
    participation: data.area * settings.participation,
    renovationFund: settings.renovationFund,
    garbageLoan: 100,
    correctData: true,
    total: -1,
  };

  const waterPrices = calculatePricesForMedia(settings, usage, data, usagesSummary);

  if (prices.correctData) {
    prices.total = round(prices.membershipFee + prices.gardenFee + prices.participation + prices.renovationFund + prices.garbageLoan);
  }

  prices.area = data.area;

  prices.totalWater = waterPrices.total;

  return prices;
};

export default calculatePrice;
