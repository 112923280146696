import Page from '../../Page';
import Removing from '../../Removing';
import { remove } from '../../httpClient/payments';
import { Link, useParams } from 'react-router-dom';

const Remove = () => {
  const { id } = useParams();

  return (
    <Page
      menu={
        <Link to="/payments" className="button-primary">
          Powrót do listy
        </Link>
      }
      breadcrumbs={[['/payments', 'Kasa']]}
      title="Usuwanie płatności"
    >
      <Removing url="/payments" title="Czy na pewno chcesz usunąć płatność?" action={() => remove(id)} />
    </Page>
  );
};

export default Remove;
