const replaceNullByUndefined = (objRef) => {
  const obj = { ...objRef };

  for (const [key, value] of Object.entries(obj)) {
    if (value === null) {
      obj[key] = undefined;
    }
  }

  return obj;
};

export default replaceNullByUndefined;
