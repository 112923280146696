import instance from './instance';

export const list = () => {
  return instance.get(`clients/list`);
};

export const add = (data) => {
  return instance.post('clients/add', data);
};

export const edit = (id, data) => {
  return instance.put(`clients/edit/${id}`, data);
};

export const remove = (id) => {
  return instance.delete(`clients/remove/${id}`);
};

export const details = (id) => {
  return instance.get(`clients/details/${id}`);
};
