import Page from '../../Page';
import Form from '../Form';
import { Link } from 'react-router-dom';

const Add = () => {
  return (
    <Page
      menu={
        <Link to="/checklists" className="button-secondary">
          Powrót do listy
        </Link>
      }
      title="Dodawanie check-listy"
      breadcrumbs={[['/checklists', 'Check-listy']]}
    >
      <Form />
    </Page>
  );
};

export default Add;
