import { details } from '../../../httpClient/users';
import Form from './Form';
import PdfDocument from './PdfDocument';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

const Modal = ({ handleClose, data, prices }) => {
  const [show, setShow] = useState(false);
  const [values, setValues] = useState(false);
  const [userDetails, setUserDetails] = useState();

  const year = new Date().getFullYear();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await details(data.userId);

        setUserDetails({
          ...response.data,
          waterMeter: Boolean(response.data.waterMeter),
        });
      } catch (e) {
        toast.error('Nie udało się pobrać danych');
      }
    };

    getUserData();
  }, [data.userId]);

  useEffect(() => {
    if (userDetails) {
      setTimeout(() => {
        setShow(true);
      }, 200);
    }
  }, [userDetails]);

  const Component = userDetails && values ? <PdfDocument data={data} values={values} year={year} userDetails={userDetails} /> : null;

  return (
    <div className="rod-modal">
      <div className="rod-modal-container">
        {!values && (
          <div>
            <Form
              defaultValues={{
                fee: prices.total,
                shipment: 11.8,
              }}
              onSubmit={setValues}
            />
          </div>
        )}

        {values && (
          <>
            <div className="rod-modal-hidden">
              <PDFViewer width={1000} height={700}>
                {Component}
              </PDFViewer>
            </div>

            {!show && <div className="rod-modal-loading">Generowanie PDF...</div>}

            {show && (
              <div>
                <div>
                  <PDFDownloadLink
                    className="button-primary rod-modal-button"
                    document={Component}
                    fileName={`Wezwanie-${year}-${data.userId}.pdf`}
                  >
                    {({ blob, url, loading, error }) => (loading ? 'Tworzenie łącza...' : 'Pobierz PDF')}
                  </PDFDownloadLink>
                </div>
              </div>
            )}
          </>
        )}

        <div className="rod-modal-close">
          <button onClick={handleClose}>Zamknij okno</button>
        </div>
      </div>
    </div>
  );
};

const Demand = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className="rod-table-button-special"
        onClick={() => {
          setOpen(true);
        }}
      >
        Generuj wezwanie
      </button>

      {open && (
        <Modal
          handleClose={() => {
            setOpen(false);
          }}
          {...props}
        />
      )}
    </>
  );
};

export default Demand;
