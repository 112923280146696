import Page from '../../Page';
import positions from '../../assets/positions';
import { LoggedAccount } from '../../store/account';
import styles from './Dashboard.module.scss';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const Dashboard = () => {
  const account = useRecoilValue(LoggedAccount);

  return (
    <Page title="Moje konto" plain={true}>
      <div className={styles.container}>
        <div className={styles.box}>
          <div className={styles.title}>Dane o użytkowniku</div>
          <div className={styles.metrics}>
            <div className={styles.row}>
              <div className={styles.label}>Imię i nazwisko</div>
              <div className={styles.value}>{account.name}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Pełniona funkcja</div>
              <div className={styles.value}>{positions[account.position]}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Adres e-mail do kontaktu</div>
              <div className={styles.value}>{account.login}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Telefon</div>
              <div className={styles.value}>{account.phone}</div>
            </div>

            <Link to="edit" className="button-primary">
              Edytuj
            </Link>
          </div>
        </div>

        <div className={styles.box}>
          <div className={styles.title}>Hasło</div>
          <div>
            <Link to="password" className="button-primary">
              Ustaw nowe hasło
            </Link>
          </div>
        </div>

        <div className={styles.box}>
          <div className={styles.title}>PIN</div>
          <div>
            <Link to="pin" className="button-primary">
              Ustaw nowy PIN
            </Link>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Dashboard;
