import styles from '../Bank.module.scss';
import cn from 'classnames';
import toast from 'react-hot-toast';

const Confirm = ({ id, onClose, refresh, action, warning, title }) => {
  const confirm = async () => {
    try {
      await action(id);

      toast.success('Zakończono powodzeniem');

      onClose();
      refresh();
    } catch (e) {}
  };

  const abort = () => {
    onClose();
  };

  return (
    <div className={styles.removing}>
      <div className={cn(styles.title, warning && styles.warning)}>{title || 'Czy na pewno chcesz usunąć?'}</div>
      <div className={styles.buttons}>
        <button onClick={confirm}>Tak</button>
        <button onClick={abort}>Nie</button>
      </div>
    </div>
  );
};

export default Confirm;
