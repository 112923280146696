import Number from '../../../Number';
import Page from '../../../Page';
import { listForFees } from '../../../httpClient/payments';
import { list as settingsList } from '../../../httpClient/settings';
import { list } from '../../../httpClient/users';
import { usage } from '../../../httpClient/waterMeters';
import { ButtonMenu } from '../../../shared/ButtonMenu';
import { UsersListState } from '../../../store/users';
import AllBills from '../AllBills';
import Bill from '../Bill';
import Media from '../Media';
import Summary from '../Summary';
import calculatePrice from '../helpers/calculatePrice';
import mergePayments from '../helpers/mergePayments';
import Indicator from './Indicator';
import styles from './List.module.scss';
import useCsvData from './hooks/useCsvData';
import { Coins, Sheet } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';

const year = 2024;

const garbagePrice = 18.57;

const List = () => {
  const [users, setUsers] = useRecoilState(UsersListState);
  const [settings, setSettings] = useState();
  const [loaded, setLoaded] = useState(false);
  const [payments, setPayments] = useState({});
  const [waterPayments, setWaterPayments] = useState({});
  const [openActions, setOpenActions] = useState();
  const [usages, setUsages] = useState();
  const [usagesSummary, setUsagesSummary] = useState();

  const lastYear = year - 1;

  useEffect(() => {
    const get = async () => {
      try {
        const responseSettings = await settingsList();
        const responsePayments = await listForFees(year);
        const responseWaterPayments = await listForFees(year, 3);
        const response = await list();
        const responseUsage = await usage(year);

        setUsers(response.data);

        setPayments(mergePayments(responsePayments.data));
        setWaterPayments(mergePayments(responseWaterPayments.data));

        setUsages(responseUsage.data.list);
        setUsagesSummary(responseUsage.data.summary);

        const allSettings = responseSettings.data;
        const lastSettings = allSettings.find((s) => s.year === lastYear + 1);

        if (lastSettings) {
          setSettings(lastSettings);
        } else {
          setSettings(undefined);
        }

        setLoaded(true);
      } catch (e) {
        toast.error('Nie można pobrać danych');
      }
    };

    get();
  }, [lastYear, setUsers]);

  const allPrices = useMemo(() => {
    if (settings && users && usages) {
      return users.map((item) => {
        return calculatePrice(
          item,
          settings,
          usages.find((u) => u.userId === item.id),
          usagesSummary
        );
      });
    }

    return undefined;
  }, [settings, users, usages, usagesSummary]);

  const sums = useMemo(() => {
    if (allPrices && allPrices.length > 0) {
      const keys = Object.keys(allPrices[0]);

      const sum = {};
      keys.forEach((key) => (sum[key] = 0));

      allPrices.forEach((row) => {
        keys.forEach((key) => {
          if (!isNaN(row[key])) {
            sum[key] += row[key];
          }
        });
      });

      return sum;
    }

    return {};
  }, [allPrices]);

  const csvData = useCsvData(allPrices, users);

  if (!users || !settings || !allPrices) {
    return null;
  }

  return (
    <>
      <Page title="Rachunki (2024)">
        <div className={styles.container}>
          <ButtonMenu>
            <Link to="/settings">
              <div>
                <Coins size={28} />
              </div>
              Cenniki
            </Link>
            {users && settings && allPrices && <AllBills data={users} settings={settings} prices={allPrices} />}
            {csvData && (
              <CSVLink data={csvData} filename={'billings.csv'} enclosingCharacter={``} separator={';'} className={styles.icon}>
                <div className={styles.icon}>
                  <Sheet size={28} />
                </div>
                Pobierz .csv
              </CSVLink>
            )}
          </ButtonMenu>

          {loaded && (
            <>
              <Summary sums={sums} usages={usagesSummary} payments={payments} mediaPayments={waterPayments} users={users} />

              {users && settings && allPrices ? (
                users.map((item, index) => {
                  const prices = allPrices[index];
                  const userUsage = usages.find((u) => u.userId === item.id);

                  const payment = payments[item.id];
                  const waterPayment = waterPayments[item.id];

                  const names = [item.names, item.subOwnerNames].filter(Boolean).join(', ');

                  return (
                    <div className={styles.box} key={item.id}>
                      <div className={styles.name}>
                        {item.id}. {names}
                      </div>

                      <div className={styles.inner}>
                        <div className={styles.left}>
                          <div className={styles.priceTitle}>Opłata bazowa</div>

                          <div className={styles.uls}>
                            <ul className={styles.prices}>
                              <li>
                                <em>Powierzchnia:</em>
                                <Number value={item.area} decimalScale={0} type="m2" />
                              </li>
                              <li>
                                <em>Opłata działkowa:</em>
                                <Number value={prices.gardenFee} type="pln" />
                              </li>
                              <li>
                                <em>Partycypacja:</em>
                                <Number value={prices.participation} type="pln" />
                              </li>
                              <li>
                                <em>Koszty stałe:</em>
                                <Number value={prices.membershipFee + prices.garbageLoan + prices.renovationFund} type="pln" />
                              </li>
                            </ul>
                          </div>

                          {prices.correctData && (
                            <>
                              <Indicator total={prices.total} payment={payment} />
                            </>
                          )}
                        </div>

                        <div className={styles.right}>
                          <div className={styles.priceTitle}>Rozliczenie mediów</div>

                          <div className={styles.uls}>
                            <ul className={styles.prices}>
                              <li>
                                <em>Zużycie wody:</em>

                                {userUsage.isCorrect && (
                                  <>
                                    {userUsage.waterMeter && (
                                      <>
                                        <Number value={userUsage.usage} type="m3" />
                                      </>
                                    )}
                                    {!userUsage.waterMeter && 'ryczałt'}
                                  </>
                                )}

                                {!userUsage.isCorrect && '-'}
                              </li>

                              <li>
                                <em>Opłata wodna:</em>
                                {prices.totalWater ? <Number value={prices.totalWater - garbagePrice} type="pln" /> : '-'}
                              </li>
                              <li>
                                <em>Wywóz odpadów:</em>
                                <Number value={garbagePrice} type="pln" />
                              </li>
                              <li>&nbsp;</li>
                            </ul>
                          </div>

                          {prices.correctData && (
                            <>
                              <Indicator total={prices.totalWater} payment={waterPayment} secondary />
                            </>
                          )}
                        </div>
                      </div>

                      {prices.correctData && (
                        <div className={styles.actions}>
                          <div
                            className={styles.moreButton}
                            onClick={() => {
                              setOpenActions(openActions === item.id ? undefined : item.id);
                            }}
                          >
                            {openActions !== item.id && <span>»</span>}
                            {openActions === item.id && <span>«</span>}
                          </div>

                          {openActions === item.id && (
                            <>
                              <span>
                                <Bill data={item} settings={settings} prices={prices} />
                              </span>
                              <span>
                                <Media
                                  data={item}
                                  settings={settings}
                                  usage={usages.find((u) => u.userId === item.id)}
                                  usagesSummary={usagesSummary}
                                />
                              </span>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className={styles.noData}>
                  Brak danych
                  {!settings && ' (ustawień)'}
                </div>
              )}
            </>
          )}
        </div>
      </Page>

      <div className={styles.generate}></div>
    </>
  );
};

export default List;
