import Page from '../../Page';
import Form from '../Form';

const Add = () => {
  return (
    <Page back="/files" title="Dokumenty przesłane" breadcrumbs={[['/files', 'Dodawanie dokumentu']]}>
      <Form />
    </Page>
  );
};

export default Add;
