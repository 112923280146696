import Copy from '../Copy';
import { details } from '../httpClient/users';
import replaceNullByUndefined from '../utils/replaceNullByUndefined';
import styles from './GardenModal.module.scss';
import cn from 'classnames';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import { Mail, Phone } from 'lucide-react';
import { User } from 'lucide-react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import SwipeableViews from 'react-swipeable-views';

const getPhone = (p) => {
  const number = parsePhoneNumber(p, 'PL');

  return {
    name: new AsYouType('PL').input(number.number),
    uri: number.getURI(),
  };
};

const LinkToPhone = ({ phone }) => {
  const p = getPhone(phone);

  return (
    <a className={styles.phone} href={p.uri}>
      <Phone size={12} />
      {p.name}
    </a>
  );
};

const Modal = ({ handleClose, id, forceBig }) => {
  const [data, setData] = useState({});
  const [big, setBig] = useState(forceBig);
  const ref = useRef(0);

  useEffect(() => {
    const getData = async () => {
      if (id) {
        try {
          const response = await details(id);

          setData(
            replaceNullByUndefined({
              ...response.data,
              gardenId: response.data.id,
              waterMeter: Boolean(response.data.waterMeter),
            })
          );
        } catch (e) {
          toast.error('Nie udało się pobrać danych');
        }
      }
    };

    getData();
  }, [id]);

  useLayoutEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  return (
    <SwipeableViews
      className={cn(styles.bg, !big && styles.small)}
      axis="y-reverse"
      onChangeIndex={(x) => {
        ref.current = x;
      }}
      onTransitionEnd={() => {
        if (ref.current === 1) {
          handleClose();
        }
      }}
    >
      <div className={cn(big && styles.modalContainerBig, !big && styles.modalContainerRegular)}>
        <div className={styles.modal} style={{ height: big ? window.innerHeight : 300 }}>
          <div className={styles.header}>
            <div className={styles.name}>Działka nr {id}</div>

            <div className={styles.actions}>
              {!forceBig && (
                <div
                  className={cn(styles.size, big && styles.flipped)}
                  onClick={() => {
                    setBig((b) => !b);
                  }}
                />
              )}
              <div className={styles.close} onClick={handleClose} />
            </div>
          </div>
          <div>
            <div className="rod-info">
              <div className="rod-info-title">Powierzchnia</div>
              <div className="rod-info-value">
                {data.area} m<sup>2</sup>
              </div>
            </div>

            <div className="rod-info">
              <div className="rod-info-title">Liczba właścicieli</div>
              <div className="rod-info-value">{data.hasTwoOwners ? 2 : 1}</div>
            </div>

            <div className={styles.user}>
              <User size={24} />

              <div className="rod-info-column">
                <div className="rod-info">
                  <div className="rod-info-title">Imię i nazwisko</div>
                  <div className={cn('rod-info-value', styles.names)}>{data.names}</div>
                </div>
                <div className="rod-info">
                  <div className="rod-info-title">Telefon</div>
                  <div className="rod-info-value">{data.phone ? <LinkToPhone phone={data.phone} /> : '-'}</div>
                </div>
                <div className="rod-info">
                  <div className="rod-info-title">E-mail</div>
                  <div className="rod-info-value">
                    {data.email ? (
                      <Copy value={data.email}>
                        <div className={styles.email}>
                          <Mail size={12} />
                          {data.email}
                        </div>
                      </Copy>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
                <div className="rod-info">
                  <div className="rod-info-title">Uzupełniony formularz RODO</div>
                  <div className="rod-info-value">{data.rodo ? 'tak' : 'nie'}</div>
                </div>
                <div className="rod-info">
                  <div className="rod-info-title">Zgoda na wysyłke rach. pocz. elektr.</div>
                  <div className="rod-info-value">{data.useEmailAsDefault ? 'tak' : 'nie'}</div>
                </div>
              </div>
            </div>

            {Boolean(data.hasTwoOwners) && (
              <div className={styles.user}>
                <User size={24} />

                <div className="rod-info-column">
                  <div className="rod-info">
                    <div className="rod-info-title">Imię i nazwisko</div>
                    <div className={cn('rod-info-value', styles.names)}>{data.subOwnerNames}</div>
                  </div>
                  <div className="rod-info">
                    <div className="rod-info-title">Telefon</div>
                    <div className="rod-info-value">{data.subOwnerPhone ? <LinkToPhone phone={data.subOwnerPhone} /> : '-'}</div>
                  </div>
                  <div className="rod-info">
                    <div className="rod-info-title">E-mail</div>
                    <div className="rod-info-value">
                      {data.subOwnerEmail ? (
                        <Copy value={data.subOwnerEmail}>
                          <div className={styles.email}>
                            <Mail size={12} />
                            {data.subOwnerEmail}
                          </div>
                        </Copy>
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                  <div className="rod-info">
                    <div className="rod-info-title">Uzupełniony formularz RODO</div>
                    <div className="rod-info-value">{data.subOwnerRodo ? 'tak' : 'nie'}</div>
                  </div>
                  <div className="rod-info">
                    <div className="rod-info-title">Zgoda na wysyłke rach. pocz. elektr.</div>
                    <div className="rod-info-value">{data.subOwnerUseEmailAsDefault ? 'tak' : 'nie'}</div>
                  </div>
                </div>
              </div>
            )}

            <div className="rod-info">
              <div className="rod-info-title">Adres właściciela</div>
              <div className="rod-info-value">
                {data.street ? `${data.street} ${data.building} ${data.flat ? `/ ${data.flat}` : ''}` : '-'}
                {data.city && `, ${data.postcode} ${data.city}`}
              </div>
            </div>

            <div className="rod-info">
              <div className="rod-info-title">Rozliczenie wody</div>
              <div className="rod-info-value">{data.waterMeter ? 'wodomierz' : 'ryczałt'}</div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </SwipeableViews>
  );
};

export default Modal;
