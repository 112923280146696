import Page from '../../Page';
import { getBalance, getPartsCount, send } from '../../httpClient/sms';
import Users from '../Users';
import styles from './Sms.module.scss';
import cn from 'classnames';
import { ChevronLeft, ChevronRight, CircleAlert, CircleCheckBig, MailCheck, Send } from 'lucide-react';
import { Wallet } from 'lucide-react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import TextareaAutosize from 'react-textarea-autosize';

const CreateMessage = ({ reset }) => {
  const [balance, setBalance] = useState(0);
  const [step, setStep] = useState(0);
  const [numbers, setNumbers] = useState([]);
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState('');
  const [parts, setParts] = useState(0);

  useEffect(() => {
    const getBalanceAsync = async () => {
      try {
        const response = await getBalance();

        setBalance(response.data.balance);
      } catch {
        toast.error('Nie można pobrać balansu');
      }
    };

    getBalanceAsync();
  }, []);

  useEffect(() => {
    const getCountAsync = async () => {
      try {
        const response = await getPartsCount(message);

        setParts(response.data.count);
      } catch {
        toast.error('Nie można wyliczyć długości SMS');
      }
    };

    if (step === 1) {
      getCountAsync();
    }
  }, [step, message]);

  const sendSms = async () => {
    try {
      const response = await send(message, numbers);

      if (response.data.errorCode) {
        toast.error(response.data.errorMsg);
      } else {
        setStep(2);
      }
    } catch (e) {
      toast.error('Nie można wysłać SMS');
    }
  };

  const disabled = step > 0;
  const canSend = balance - numbers.length * parts >= 0;

  return (
    <Page title="SMS">
      <div className={styles.body}>
        {step < 2 && (
          <>
            <div className={styles.balance}>
              <Wallet />
              <strong>Stan środków:</strong> {balance}
            </div>

            <div className={styles.sendTo}>
              <button disabled={disabled} onClick={() => setModal(true)}>
                Wyślij do:
              </button>
              <span>{numbers.length > 0 ? `Wybrano ${numbers.length}` : `Nie wybrano odbiorców`}</span>
            </div>

            <div className={styles.area}>
              <TextareaAutosize
                disabled={disabled}
                minRows={8}
                placeholder="Wpisz wiadomość"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <div className={styles.count}>Ilość znaków: {message.length} / 160</div>
            </div>

            {step === 1 && (
              <div className={styles.summary}>
                <div className={styles.icon}>
                  {canSend && <CircleCheckBig size={40} color="white" />}
                  {!canSend && <CircleAlert size={40} color="white" />}
                </div>

                <div>
                  <div>Ilość SMS: {parts}</div>
                  <div>Koszt wysyłki: {parts * numbers.length}</div>
                </div>
              </div>
            )}

            <div className={cn(styles.actions, disabled && styles.two)}>
              {disabled && (
                <>
                  <button className={styles.buttonBorder} onClick={() => setStep(0)}>
                    <ChevronLeft color="white" size={30} />
                  </button>
                  <button
                    className={styles.buttonYellow}
                    disabled={!(numbers.length > 0 && message.length > 0) || !canSend}
                    onClick={sendSms}
                  >
                    <Send color="#1c1c1c" size={24} />
                  </button>
                </>
              )}

              {!disabled && (
                <>
                  <button className={styles.buttonBorder} disabled={!(numbers.length > 0 && message.length > 0)} onClick={() => setStep(1)}>
                    <ChevronRight color="white" size={30} />
                  </button>
                </>
              )}
            </div>
          </>
        )}

        {step === 2 && (
          <div className={styles.success}>
            <div>Wiadomość została wysłana</div>
            <MailCheck size={80} color="#ffe37e" />
            <button onClick={() => reset()}>Nowa wiadomość</button>
          </div>
        )}
      </div>

      {modal && <Users handleClose={() => setModal(false)} defaultNumbers={numbers} confirm={setNumbers} />}
    </Page>
  );
};

const Sms = () => {
  const [key, setKey] = useState(0);

  return <CreateMessage reset={() => setKey((k) => k + 1)} key={key} />;
};

export default Sms;
