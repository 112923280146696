import styles from './Layout.module.scss';
import cn from 'classnames';
import { Outlet, ScrollRestoration } from 'react-router-dom';

const Layout = () => {
  return (
    <>
      <div className={styles.layout}>
        <div className={cn(styles.content)}>
          <Outlet />
        </div>
      </div>

      <div className={styles.bg}>
        <div className={styles.triangle}>
          <svg fill="#242424" preserveAspectRatio="none" viewBox="0 0 25 100">
            <polygon points="0,100 25,0 25,100"></polygon>
          </svg>
        </div>
      </div>

      <ScrollRestoration />
    </>
  );
};

export default Layout;
