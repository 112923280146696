import instance from './instance';

export const getBalance = () => {
  return instance.post('sms/getBalance');
};

export const getPartsCount = (content) => {
  return instance.get(`sms/partsCount?content=${content}`);
};

export const send = (msg, to) => {
  return instance.post(`sms/send`, {
    msg,
    to,
  });
};
