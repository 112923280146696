import styles from './DialogModal.module.scss';
import cn from 'classnames';
import { useLayoutEffect, useRef, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

const DialogModal = ({ handleClose, children }) => {
  const ref = useRef(0);
  const [showClose, setShowClose] = useState(true);

  useLayoutEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  return (
    <SwipeableViews
      className={cn(styles.bg)}
      axis="y-reverse"
      onChangeIndex={(x) => {
        ref.current = x;

        setShowClose(false);
      }}
      onTransitionEnd={() => {
        if (ref.current === 1) {
          handleClose();
        }
      }}
    >
      <div className={styles.modal} style={{ height: window.innerHeight }}>
        <div className={styles.content}>{children}</div>
        <div className={cn(styles.close, !showClose && styles.hidden)}></div>
      </div>

      <div></div>
    </SwipeableViews>
  );
};

export default DialogModal;
