import { details } from '../../../httpClient/users';
import PdfDocument from './PdfDocument';
import { calculatePricesForMedia } from './helpers';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { QRCodeCanvas } from 'qrcode.react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useBarcode } from 'react-barcodes';
import toast from 'react-hot-toast';

const makePriceForQr = (prices) => {
  const value = parseInt(prices.total * 100).toString();

  let trimer = '';

  for (let i = value.length; i < 6; i++) {
    trimer += '0';
  }

  return `${trimer}${value}`;
};

const Modal = ({ handleClose, data, settings, usage, usagesSummary }) => {
  const prices = useMemo(() => calculatePricesForMedia(settings, usage, data, usagesSummary), [settings, usage, data, usagesSummary]);

  const [show, setShow] = useState(false);
  const [qrUrl, setQrUrl] = useState();
  const [barUrl, setBarUrl] = useState();
  const divRef = useRef();
  const barRef = useRef();
  const [userDetails, setUserDetails] = useState();

  const { inputRef } = useBarcode({
    value: `ME/${data.id}/2023`,
    options: {
      displayValue: false,
      height: 60,
    },
  });

  const year = new Date().getFullYear();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await details(data.id);

        setUserDetails({
          ...response.data,
        });
      } catch (e) {
        toast.error('Nie udało się pobrać danych');
      }
    };

    getUserData();
  }, [data.id]);

  useEffect(() => {
    setTimeout(() => {
      if (divRef.current) {
        const canvas = divRef.current.childNodes[0];
        const dataURL = canvas.toDataURL();

        setQrUrl(dataURL);
      }

      if (barRef.current) {
        const canvas = barRef.current.childNodes[0];
        const dataURL = canvas.toDataURL();

        setBarUrl(dataURL);
      }
    }, 200);
  }, []);

  const qr = `8512594219|PL|56124039721111001035636279|${makePriceForQr(prices)}|ROD im. 1 Maja|ME/${data.id}/2023|||`;

  useEffect(() => {
    if (userDetails && qrUrl && barUrl) {
      setTimeout(() => {
        setShow(true);
      }, 200);
    }
  }, [userDetails, qrUrl, barUrl]);

  const Component =
    userDetails && usage.isCorrect ? (
      <PdfDocument
        qrUrl={qrUrl}
        barUrl={barUrl}
        data={data}
        settings={settings}
        usage={usage}
        prices={prices}
        year={year}
        userDetails={userDetails}
      />
    ) : null;

  return (
    <div className="rod-modal">
      <div className="rod-modal-hidden">
        <div ref={divRef}>
          <QRCodeCanvas level="L" size={250} value={qr} />
        </div>

        <div ref={barRef}>
          <canvas ref={inputRef} />
        </div>

        <PDFViewer width={1000} height={700}>
          {Component}
        </PDFViewer>
      </div>

      <div className="rod-modal-container">
        {!show && <div className="rod-modal-loading">Generowanie PDF...</div>}

        {show && usage.isCorrect && (
          <div>
            <div>
              <PDFDownloadLink className="button-primary rod-modal-button" document={Component} fileName={`ME-${data.id}-${year}.pdf`}>
                {({ blob, url, loading, error }) => (loading ? 'Tworzenie łącza...' : 'Pobierz PDF')}
              </PDFDownloadLink>
            </div>
            <div className="rod-modal-close">
              <button onClick={handleClose}>Zamknij okno</button>
            </div>
          </div>
        )}

        {show && !usage.isCorrect && (
          <div>
            <div>Niepoprawne dane</div>
            <div className="rod-modal-close">
              <button onClick={handleClose}>Zamknij okno</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Media = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => {
          setOpen(true);
        }}
      >
        Rachunek za media
      </button>

      {open && (
        <Modal
          handleClose={() => {
            setOpen(false);
          }}
          {...props}
        />
      )}
    </>
  );
};

export default Media;
