/* eslint-disable  react-hooks/exhaustive-deps */
import useAuth from '../utils/useAuth';
import styles from './Pin.module.scss';
import deleteSvg from './assets/delete.svg';
import cn from 'classnames';
import { useEffect, useState } from 'react';

const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, undefined, 0];

const stars = [0, 1, 2, 3];

const Pin = () => {
  const { logout, checkPin } = useAuth();

  const [pin, setPin] = useState('');
  const [error, setError] = useState(false);
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    const verifyPin = async () => {
      try {
        const success = await checkPin(pin);

        if (success) {
          return;
        }
      } catch (e) {}

      setError(true);
      setAttempts((i) => i + 1);
      setPin('');
    };

    if (pin.length === 4) {
      verifyPin();
    }
  }, [pin]);

  useEffect(() => {
    if (attempts >= 3) {
      logout();
    }
  }, [attempts]);

  return (
    <div className={styles.container}>
      <div className={styles.info}>{error ? 'Błędne dane. Sprobuj ponownie.' : 'Podaj PIN by odblokować.'}</div>

      <div className={styles.stars}>
        {stars.map((star, index) => (
          <div key={index} className={cn(star < pin.length && styles.selected)}></div>
        ))}
      </div>

      <div className={styles.buttons}>
        {buttons.map((button, index) => (
          <div key={index}>
            {button !== undefined && (
              <button
                className={styles.button}
                onClick={() => {
                  setPin((currentPin) => {
                    if (currentPin.length < 4) {
                      return currentPin + button.toString();
                    }

                    return currentPin;
                  });
                }}
              >
                {button}
              </button>
            )}
          </div>
        ))}

        <div>
          <button
            className={styles.delete}
            onClick={() => {
              setPin((currentPin) => currentPin.substring(0, Math.max(0, currentPin.length - 1)));
            }}
          >
            <img src={deleteSvg} alt="Delete" />
          </button>
        </div>
      </div>

      <div className={styles.forget}>
        <span onClick={() => logout()}>Wyloguj</span>
      </div>
    </div>
  );
};

export default Pin;
