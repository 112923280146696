/* eslint-disable react-hooks/exhaustive-deps */
import RobotoBlackFont from '../../assets/Roboto-Black.ttf';
import RobotoFont from '../../assets/Roboto.ttf';
import RobotoMonoFont from '../../assets/RobotoMono-Light.ttf';
import styles from './PdfDocument.style';
import logoUrl from './logofull.png';
import watermarkUrl from './rodlogo.png';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { useMemo } from 'react';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: RobotoFont }, // font-style: normal, font-weight: normal
    { src: RobotoBlackFont, fontWeight: 700 },
  ],
});

Font.register({
  family: 'RobotoMono',
  fonts: [{ src: RobotoMonoFont }],
});

const tableColumnSizes = [10, 20, 20, 50];
const titles = ['Data', 'Nr ref.', 'Kwota', 'Opis'];
const types = ['Rach. Bieżący - podmioty gosp.', 'Rach. Dobry Zysk dla Firmy'];
const paymentTypes = ['', 'Opłata ogrodowa', 'Opłata inwestycyjna - prąd'];

const Row = ({ data }) => {
  return (
    <View
      style={[
        {
          display: 'flex',
          flexDirection: 'row',
        },
        styles.mono,
      ]}
      wrap={false}
    >
      <View
        style={[
          styles.cell,
          {
            width: `${tableColumnSizes[0]}%`,
            padding: '4px',
          },
        ]}
      >
        <Text>{format(new Date(data.time), 'dd.MM.yyyy')}</Text>
      </View>
      <View
        style={[
          styles.cell,
          {
            width: `${tableColumnSizes[1]}%`,
            padding: '4px',
          },
        ]}
      >
        <Text>{data.refNr}</Text>
      </View>
      <View
        style={[
          styles.cell,
          {
            width: `${tableColumnSizes[2]}%`,
            padding: '4px',
            textAlign: data.inOut > 0 ? 'right' : 'left',
          },
        ]}
      >
        <Text>{(data.value * data.inOut).toFixed(2)}</Text>
      </View>
      <View
        style={[
          styles.cell,
          {
            width: `${tableColumnSizes[3]}%`,
            padding: '4px',
          },
        ]}
      >
        <Text>{types[data.accountId]}</Text>
        <Text>{data.names ? `Od: ${data.names} (dz. ${data.gardenId})` : ''}</Text>
        <Text>{paymentTypes[data.paymentType]}</Text>
        <Text>{data.description}</Text>
        <Text>#ID: {data.id}</Text>
      </View>
    </View>
  );
};

const proportion = ([w, h], height) => {
  return {
    height,
    width: (w / h) * height,
  };
};

const PdfDocument = ({ data, year }) => {
  const rows = useMemo(() => [...data].reverse(), [data]);

  return (
    <Document>
      <Page size="A4" style={styles.body} wrap={true}>
        <View style={styles.header} fixed>
          <View style={{ width: '50%' }}>
            <Image src={logoUrl} style={{ ...proportion([647, 307], 50), marginTop: 12 }} />
          </View>
        </View>

        <View style={styles.watermark} fixed>
          <Image src={watermarkUrl} style={{ ...proportion([324, 581], 360) }} />
        </View>

        <View style={[styles.dotted, styles.title, styles.flexRow]}>
          <View>
            <Text style={[styles.bold, { fontSize: 17 }]}>RAPORT KASOWY ZA ROK {year}</Text>
          </View>
        </View>

        <View style={[styles.dotted, styles.flexRow]}>
          <View style={{ width: '50%', padding: '10px 0' }}>
            <Text style={styles.bold}>Dane ogrodu</Text>
            <Text>Rodzinne Ogrody Działkowe im. 1 Maja</Text>
            <Text>ul. 1 Maja b/n</Text>
            <Text>71-627 Szczecin</Text>
            <Text>NIP: 851-25-94-219</Text>
          </View>

          <View style={{ width: '50%', backgroundColor: '#e3e3e3', padding: '10px' }}>
            <Text style={styles.bold}>Numer rachunku</Text>
            <Text>56 1240 3972 1111 0010 3563 6279</Text>
            <Text> </Text>
            <Text style={styles.bold}>Za okres</Text>
            <Text>01.01.2022 - 31.12.2022</Text>
            <Text> </Text>
            <Text style={styles.bold}>Waluta</Text>
            <Text>PLN</Text>
          </View>
        </View>

        <View style={[styles.table, { margin: '20px 0' }]}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 10,
            }}
          >
            {titles.map((title, index) => (
              <View
                style={[
                  styles.cell,
                  styles.bold,
                  {
                    width: `${tableColumnSizes[index]}%`,
                  },
                ]}
              >
                <Text>{title}</Text>
              </View>
            ))}
          </View>

          {rows.map((row, index) => (
            <Row key={index} data={row} />
          ))}
        </View>

        <View
          style={styles.pageNumber}
          render={(props) => {
            if (props.pageNumber && props.totalPages) {
              return (
                <View style={styles.pageNumberWrapper}>
                  <Text style={styles.pageNumberLeft}>Dokument wygenerowany przez oprogramowanie stworzone przez Bartosza Maciuraka</Text>
                  <Text style={styles.pageNumberRight}>
                    Strona {props.pageNumber} z {props.totalPages}
                  </Text>
                </View>
              );
            }

            return null;
          }}
          fixed
        />
      </Page>
    </Document>
  );
};

export default PdfDocument;
