import axios from 'axios';
import toast from 'react-hot-toast';

const DEV = process.env.NODE_ENV !== 'production';

const instance = axios.create({
  baseURL: DEV ? '//192.168.0.101:3001' : process.env.REACT_APP_API_URL,
  timeout: 10000,
});

export const addToken = (token) => {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const addLogoutCallback = (callback) => {
  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status < 404) {
        callback();
      } else {
        toast.error('Coś poszło nie tak...');
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
};

export default instance;
