import Map from '../../Map';
import Page from '../../Page';
import Info from '../Info';
import Management from '../Management';
import styles from './Dashboard.module.scss';
import detailsSvg from './assets/details.svg';
import detailsActiveSvg from './assets/detailsActive.svg';
import mapSvg from './assets/map.svg';
import mapActiveSvg from './assets/mapActive.svg';
import teamSvg from './assets/team.svg';
import teamActiveSvg from './assets/teamActive.svg';
import cn from 'classnames';
import { ChevronDown, Info as InfoIcon, Users } from 'lucide-react';
import { useState } from 'react';

const menus = [
  {
    name: 'Mapa',
    value: 'map',
    icon: mapActiveSvg,
    iconActive: mapSvg,
  },
  {
    name: 'Informacje',
    value: 'info',
    icon: detailsActiveSvg,
    iconActive: detailsSvg,
  },
  {
    name: 'Zarząd',
    value: 'management',
    icon: teamActiveSvg,
    iconActive: teamSvg,
  },
];

const Dashboard = () => {
  const [showInfo, setShowInfo] = useState(false);
  const [showManagement, setShowManagement] = useState(false);

  return (
    <Page title="ROD im. 1 Maja" plain={true} noBreadcrumbs={true}>
      <div className={styles.container}>
        <Map />

        <div className={styles.box}>
          <div className={cn(styles.title, showInfo && styles.open)} onClick={() => setShowInfo((f) => !f)}>
            <div className={styles.withIcon}>
              <InfoIcon size={24} color="white" /> Informacje
            </div>
            <ChevronDown size={20} color="#535353" />
          </div>

          {showInfo && <Info />}
        </div>

        <div className={styles.box}>
          <div className={cn(styles.title, showManagement && styles.open)} onClick={() => setShowManagement((f) => !f)}>
            <div className={styles.withIcon}>
              <Users size={24} color="white" /> Zarząd
            </div>
            <ChevronDown size={20} color="#535353" />
          </div>

          {showManagement && <Management />}
        </div>
      </div>
    </Page>
  );
};

export default Dashboard;
