/* eslint-disable react-hooks/exhaustive-deps */
import RobotoBlackFont from '../../../assets/Roboto-Black.ttf';
import RobotoFont from '../../../assets/Roboto.ttf';
import PriceFormater from '../../Price/PriceConverter';
import logoUrl from '../../assets/logofull.png';
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useMemo } from 'react';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: RobotoFont }, // font-style: normal, font-weight: normal
    { src: RobotoBlackFont, fontWeight: 700 },
  ],
});

const priceFormater = new PriceFormater();
priceFormater.format = { myFormat: 'zl-words zl gr-short' };

const tableColumnSizes = [5, 80, 15];
const fixes = [0, 0, 2];

const Row = ({ values, aligns = undefined, weights = undefined }) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {tableColumnSizes.map((size, index) => (
        <View
          key={index}
          style={[
            styles.cell,
            {
              width: `${size}%`,
              padding: '4px',
            },
          ]}
        >
          <Text
            style={{
              textAlign: aligns ? aligns[index] : 'left',
              fontWeight: weights ? weights[index] : 400,
            }}
          >
            {typeof values[index] === 'number' ? values[index].toFixed(fixes[index]) : values[index].toString()}
          </Text>
        </View>
      ))}
    </View>
  );
};

const proportion = ([w, h], height) => {
  return {
    height,
    width: (w / h) * height,
  };
};

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 9,
    fontFamily: 'Roboto',
  },

  header: {
    borderBottom: '3px solid #292929',
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'row',
  },

  headerChild: {},
  dotted: {
    borderBottom: '1px dotted #292929',
  },
  bold: {
    fontWeight: 700,
  },
  cell: {
    borderRight: '1px solid #979797',
    borderBottom: '1px solid #979797',
  },
  table: {
    borderTop: '1px solid #979797',
    borderLeft: '1px solid #979797',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '3px',
  },

  greetings: {
    padding: '15px 0',
    borderBottom: '3px solid #292929',
    textAlign: 'center',
    fontSize: 12,
  },

  pageNumber: {
    position: 'absolute',
    bottom: 30,
    left: 35,
    right: 35,
  },
  payment: {
    backgroundColor: '#292929',
    width: '170px',
    margin: '10px 0',
    padding: '3px 10px',
    color: 'white',
    fontWeight: 700,
    fontSize: 13,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    padding: '10px 0',
  },
  more: {
    marginBottom: 3,
    fontSize: 8,
  },
  waterHeader: {
    marginBottom: 3,
    width: 88,
    backgroundColor: '#f1f1f1',
    padding: '1px 3px',
  },
});

const Empty = <View />;

const PdfDocument = ({ data, values, year, userDetails }) => {
  const total = useMemo(() => {
    return values.fee + values.interest + values.shipment;
  }, [values]);

  const rows = useMemo(() => {
    const result = [
      ['Opłata działkowa', values.fee],
      ['Odsetki', values.interest],
      ['Koszt wysyłki wezwania', values.shipment],
    ];

    return result;
  }, []);

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.header}>
          <View style={{ width: '50%' }}>
            <Image src={logoUrl} style={{ ...proportion([647, 307], 50), marginTop: 12 }} />
          </View>
        </View>

        <View style={[styles.dotted, styles.title, styles.flexRow]}>
          <View>
            <Text style={[styles.bold, { fontSize: 17 }]}>
              WEZWANIE DO ZAPŁATY ZA ROK {year} DLA DZIAŁKI NR {data.userId}
            </Text>
          </View>
        </View>

        <View style={[styles.dotted, styles.flexRow]}>
          <View style={{ width: '30%', padding: '10px 0' }}>
            <Text>
              Data wystawienia: <Text style={styles.bold}>16.12.2022</Text>
            </Text>
            <Text>
              Termin płatności: <Text style={styles.bold}>30.12.2022</Text>
            </Text>
          </View>
          <View style={{ width: '35%', padding: '10px 0' }}>
            <Text style={styles.bold}>Wystawiający</Text>
            <Text>Rodzinne Ogrody Działkowe im. 1 Maja</Text>
            <Text>ul. 1 Maja b/n</Text>
            <Text>71-627 Szczecin</Text>
            <Text>NIP: 851-25-94-219</Text>
          </View>
          <View style={{ width: '35%', backgroundColor: '#e3e3e3', padding: '10px' }}>
            <Text style={styles.bold}>Płatnik</Text>
            <Text>{userDetails.names}</Text>
            {userDetails.street ? (
              <Text>
                ul. {userDetails.street} {userDetails.building}
                {userDetails.flat && `/${userDetails.flat}`}
              </Text>
            ) : (
              Empty
            )}
            <Text>
              {userDetails.postcode} {userDetails.city}
            </Text>
          </View>
        </View>

        <View style={{ marginTop: '20px' }}>
          <Text style={{ lineHeight: 1.5, textIndent: '30px' }}>
            Na podstawie rachunku nr 2022/{data.userId} i art. 481 Kodeksu cywilnego wzywa się Pana/Panią do uregulowania płatności za
            {` działkę\u202Fnr\u202F${data.userId}`}. Termin wpływu należności upłynął w dniu 30.06.2022. Poniższa tabela zawiera
            zestawienie opłat, które należy uiścić w ciągu 14 dni od daty otrzymania niniejszego pisma.
          </Text>
        </View>

        <View style={[styles.table, { margin: '20px 0' }]}>
          <Row
            sizes={tableColumnSizes}
            values={['Lp.', 'Nazwa opłaty', 'Wartość brutto']}
            weights={[700, 700, 700]}
            aligns={['center', 'center', 'center']}
          />

          {rows.map((row, index) => (
            <Row key={row} sizes={tableColumnSizes} aligns={['center', 'left', 'right']} values={[index + 1, ...row]} />
          ))}

          <View style={styles.flexRow}>
            <View
              style={[
                styles.cell,
                {
                  width: '85%',
                  padding: '4px',
                  textAlign: 'right',
                  backgroundColor: '#e3e3e3',
                },
              ]}
            >
              <Text style={{ fontWeight: 700 }}>Razem:</Text>
            </View>

            <View
              style={[
                styles.cell,
                {
                  width: '15%',
                  padding: '4px',
                  textAlign: 'right',
                },
              ]}
            >
              <Text>{total.toFixed(2)}</Text>
            </View>
          </View>
        </View>

        <View style={styles.dotted} />

        <View style={styles.payment}>
          <Text>Do zapłaty: {total.toFixed(2)} zł</Text>
        </View>

        <View>
          <View style={styles.row}>
            <View style={{ width: '80px' }}>
              <Text>Słownie:</Text>
            </View>
            <View>
              <Text>{priceFormater.convert(total)}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{ width: '80px' }}>
              <Text>Forma płatności:</Text>
            </View>
            <View>
              <Text>przelew</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{ width: '80px' }}>
              <Text>Numer konta:</Text>
            </View>
            <View>
              <Text>56 1240 3972 1111 0010 3563 6279</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{ width: '80px' }}>
              <Text>Tytuł przelewu:</Text>
            </View>
            <View>
              <Text>
                Opłata nr {year}/{data.userId}
              </Text>
            </View>
          </View>
        </View>

        <View style={[styles.dotted, { marginTop: '7px' }]} />

        <View style={styles.pageNumber}>
          <View style={styles.dotted} />

          <View style={styles.greetings}>
            <Text>Prosimy o jak najszybsze uregulowanie płatności</Text>
          </View>

          <View style={[styles.row, { fontSize: 7, marginTop: '4px', justifyContent: 'space-between' }]}>
            <View>
              <Text> </Text>
            </View>
            <View style={{ textAlign: 'right', width: '50%' }}>
              <Text>Strona 1 z 1</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfDocument;
