import { useCallback, useEffect, useState } from 'react';

const useList = (apiCall, params) => {
  const [data, setData] = useState();
  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    const get = async () => {
      try {
        const response = await apiCall(params);

        setData(response.data);
      } catch (e) {}
    };

    get();
  }, [refreshCount, params, apiCall]);

  const refresh = useCallback(() => {
    setRefreshCount((i) => i + 1);
  }, []);

  return {
    data,
    refresh,
  };
};

export default useList;
