import positions from '../../assets/positions';
import { list } from '../../httpClient/management';
import styles from './Management.module.scss';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

const Management = () => {
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    const get = async () => {
      try {
        const response = await list();

        setDataList(response.data);
      } catch (e) {
        toast.error('Nie można pobrać danych');
      }
    };

    get();
  }, []);

  return (
    <div className={styles.wrapper}>
      <table className="rod-table rod-table-no-border">
        <tbody>
          {dataList.map((item) => (
            <tr key={item.id}>
              <td>
                <span className={styles.position}>{positions[item.position]}</span>
                <span className={styles.name}>{item.name}</span>
              </td>

              <td>
                <div className={styles.details}>
                  <div>{item.contactEmail}</div>
                  <div>{item.phone}</div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Management;
