import Page from '../../Page';
import { details } from '../../httpClient/checklists';
import Form from '../Form';
import { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

const Edit = () => {
  const { id } = useParams();
  const [defaultValues, setDefaultValues] = useState();

  useEffect(() => {
    const getData = async () => {
      if (id) {
        try {
          const response = await details(id);

          setDefaultValues({
            ...response.data,
          });
        } catch (e) {
          toast.error('Nie udało się pobrać danych');
        }
      }
    };

    getData();
  }, [id]);

  return (
    <Page back="/checklists" title="Edycja check-listy" breadcrumbs={[['/checklists', 'Check-listy']]}>
      {defaultValues && <Form isEdit={true} defaultValues={defaultValues} />}
    </Page>
  );
};

export default Edit;
