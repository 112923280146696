import { list } from '../../httpClient/users';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

const useUsers = () => {
  const [users, setUsers] = useState();

  useEffect(() => {
    const get = async () => {
      try {
        const response = await list();

        setUsers(response.data);
      } catch (e) {
        console.log(e);
      }
    };

    get();
  }, []);

  return users;
};

export default useUsers;
