import Page from '../../Page';
import { details as userDetails } from '../../httpClient/users';
import { details } from '../../httpClient/waterMeters';
import { ButtonMenu } from '../../shared/ButtonMenu';
import { LoggedAccount } from '../../store/account';
import styles from './Details.module.scss';
import { compareAsc, format, parseISO } from 'date-fns';
import { CircleGauge, Ruler } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts';
import { useRecoilValue } from 'recoil';

const URL = `https://files.r1m.pl/watermeters/`;

const Details = () => {
  const { gardenId } = useParams();
  const [list, setList] = useState([]);
  const [user, setUser] = useState();

  const account = useRecoilValue(LoggedAccount);

  useEffect(() => {
    const get = async () => {
      const response = await details(gardenId);
      const userResponse = await userDetails(gardenId);

      setList(response.data);
      setUser(userResponse.data);
    };

    if (gardenId) {
      get();
    }
  }, [gardenId]);

  const diagram = useMemo(
    () =>
      [...list]
        .sort((a, b) => compareAsc(parseISO(a.time), parseISO(b.time)))
        .map((item) => ({
          date: format(new Date(item.time), 'yy/MM/dd'),
          value: item.value,
        })),
    [list]
  );

  return (
    <Page title={`Odczyty dla działki ${gardenId}`} back="/watermeters" breadcrumbs={[['/watermeters', 'Wodomierze']]}>
      <ButtonMenu>
        <Link to={`/watermeters/add/${gardenId}`}>
          <div>
            <CircleGauge size={28} />
          </div>
          Dodaj odczyt
        </Link>
        <Link to={`/watermeters/size/${gardenId}`}>
          <div>
            <Ruler size={28} />
          </div>
          Zmień rozmiar
        </Link>
      </ButtonMenu>

      <div className={styles.page}>
        {user && (
          <div className={styles.user}>
            <div className={styles.userName}>{user.names}</div>
            <div>Rozmiar wodomierza: {user.waterMeterSize === 1 ? '1/2' : '3/4'}''</div>
            {diagram.length > 1 && (
              <div className={styles.diagram}>
                <LineChart width={330} height={110} data={diagram} margin={{ left: -30 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" tick={{ fontSize: 10 }} />
                  <YAxis tick={{ fontSize: 10 }} />
                  <Line type="monotone" dataKey="value" stroke="#ffe37e" isAnimationActive={false} dot={false} />
                </LineChart>
              </div>
            )}
          </div>
        )}

        <div>
          {list.map((item) => (
            <div key={item.id} className={styles.item}>
              {item.image && (
                <a className={styles.image} href={`${URL}${item.image}`} target="_blank" rel="noreferrer">
                  <img src={`${URL}${item.image}`} alt="Odczyt" />
                </a>
              )}
              <div className={styles.info}>
                <div>
                  <div className={styles.value}>
                    {item.value} m<sup>3</sup>
                  </div>
                  <div className={styles.data}>{format(new Date(item.time), 'dd.MM.yyyy')}</div>
                </div>
                <div className={styles.links}>
                  {account.login === 'laxip@protonmail.com' && (
                    <Link to={`/watermeters/edit/${gardenId}/${item.id}`} className="rod-table-button-primary">
                      Edytuj
                    </Link>
                  )}
                  <Link to={`/watermeters/remove/${item.id}`} className="rod-table-button-secondary">
                    Usuń
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
};

export default Details;
