import instance from './instance';

export const list = () => {
  return instance.get(`bankAccounts/list`);
};

export const add = (data) => {
  return instance.post('bankAccounts/add', data);
};

export const edit = (id, data) => {
  return instance.put(`bankAccounts/edit/${id}`, data);
};

export const remove = (id) => {
  return instance.delete(`bankAccounts/remove/${id}`);
};

export const details = (id) => {
  return instance.get(`bankAccounts/details/${id}`);
};

export const markAsMain = (id) => {
  return instance.put(`bankAccounts/markAsMain`, { id });
};
