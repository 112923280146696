import styles from './Info.module.scss';

const Info = () => {
  return (
    <div className={styles.wrapper}>
      <div className="rod-info">
        <div className="rod-info-title">Nazwa</div>
        <div className="rod-info-value">ROD im. 1 Maja</div>
      </div>

      <div className="rod-info">
        <div className="rod-info-title">Adres</div>
        <div className="rod-info-value">
          ul. 1 Maja b/n
          <br />
          71-627 Szczecin
        </div>
      </div>

      <div className="rod-info">
        <div className="rod-info-title">Adres e-mail</div>
        <div className="rod-info-value">
          1maja.szczecin@ogrodyrod.pl
          <br />
          rod1maja.szczecin@gmail.com
        </div>
      </div>

      <div className="rod-info">
        <div className="rod-info-title">Telefon Zarządu</div>
        <div className="rod-info-value">+48 515 45 80 90</div>
      </div>

      <div className="rod-info">
        <div className="rod-info-title">NIP</div>
        <div className="rod-info-value">851-25-94-219</div>
      </div>

      <div className="rod-info">
        <div className="rod-info-title">Regon</div>
        <div className="rod-info-value">007015915-1791-3</div>
      </div>

      <div className="rod-info">
        <div className="rod-info-title">Skrytka pocztowa</div>
        <div className="rod-info-value">
          UP Szczecin 8<br />
          Skr. pocztowa nr 56
          <br />
          ul. Stanisława Dubois 9<br />
          71-611 Szczecin
        </div>
      </div>

      <div className="rod-info">
        <div className="rod-info-title">Konto bankowe bieżące</div>
        <div className="rod-info-value">56 1240 3972 1111 0010 3563 6279</div>
      </div>

      <div className="rod-info">
        <div className="rod-info-title">Konto bankowe - fundusz</div>
        <div className="rod-info-value">83 1240 3972 1111 0010 3563 6569</div>
      </div>
    </div>
  );
};

export default Info;
