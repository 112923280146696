import { NOT_NUMBER, NOT_POSITIVE_NUMBER, REQUIRED } from '../../../utils/formTexts';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import 'yup-phone';

const schema = yup
  .object({
    fee: yup.number().typeError(NOT_NUMBER).positive(NOT_POSITIVE_NUMBER).required(REQUIRED),
    interest: yup.number().typeError(NOT_NUMBER).positive(NOT_POSITIVE_NUMBER).required(REQUIRED),
    shipment: yup.number().typeError(NOT_NUMBER).positive(NOT_POSITIVE_NUMBER).required(REQUIRED),
  })
  .required();

const Form = ({ defaultValues = undefined, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  return (
    <div className="rod-form">
      <div className="rod-form-content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="rod-form-row">
            <label>Wartość opłaty *</label>
            <input type="text" {...register('fee')} />
            {errors.fee && <div className="rod-form-error">{errors.fee?.message}</div>}
          </div>
          <div className="rod-form-row">
            <label>Odsetki *</label>
            <input type="text" {...register('interest')} />
            {errors.interest && <div className="rod-form-error">{errors.interest?.message}</div>}
          </div>
          <div className="rod-form-row">
            <label>Koszt wysyłki</label>
            <input type="text" {...register('shipment')} />
            {errors.shipment && <div className="rod-form-error">{errors.shipment?.message}</div>}
          </div>

          <div className="rod-form-legend">
            <ul>
              <li>* - pole wymagane</li>
            </ul>
          </div>

          <div className="rod-form-submit">
            <input type="submit" className="button-primary" value="Generuj" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
