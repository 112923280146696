import styles from './List.module.scss';
import cn from 'classnames';

export const makeInitials = (name) => {
  return name
    .match(/(^\S\S?|\s\S)?/g)
    .map((v) => v.trim())
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toLocaleUpperCase();
};

const Avatar = ({ user }) => {
  const names = [user.names, user.subOwnerNames].filter(Boolean).map(makeInitials);

  return (
    <div className={cn(styles.avatar, names.length === 2 && styles.small)}>
      {names.map((name, index) => (
        <div key={index}>{name}</div>
      ))}
    </div>
  );
};

export default Avatar;
