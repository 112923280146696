import { list } from '../../../httpClient/users';
import PdfDocument from './PdfDocument';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

const Modal = ({ handleClose, data, settings, lastYear }) => {
  const [show, setShow] = useState(false);
  const [usersDetails, setUsersDetails] = useState();

  const year = new Date().getFullYear();

  useEffect(() => {
    const getUsersData = async () => {
      try {
        const response = await list();

        setUsersDetails(response.data);
      } catch (e) {
        toast.error('Nie udało się pobrać danych');
      }
    };

    getUsersData();
  }, []);

  useEffect(() => {
    if (usersDetails) {
      setTimeout(() => {
        setShow(true);
      }, 200);
    }
  }, [usersDetails]);

  const Component = usersDetails ? (
    <PdfDocument data={data} settings={settings} lastYear={lastYear} year={year} usersDetails={usersDetails} />
  ) : null;

  return (
    <div className="rod-modal">
      <div className="rod-modal-hidden">
        <PDFViewer width={1000} height={700}>
          {Component}
        </PDFViewer>
      </div>

      {!show && <div className="rod-modal-loading">Generowanie PDF...</div>}

      {show && (
        <div className="rod-modal-container">
          <div>
            <div>
              <PDFDownloadLink className="button-primary rod-modal-button" document={Component} fileName={`Raport-oplaty-${year}.pdf`}>
                {({ blob, url, loading, error }) => (loading ? 'Tworzenie łącza...' : 'Pobierz PDF')}
              </PDFDownloadLink>
            </div>
            <div className="rod-modal-close">
              <button onClick={handleClose}>Zamknij okno</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Raport = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className="rod-table-button-special"
        onClick={() => {
          setOpen(true);
        }}
      >
        Generuj raport
      </button>

      {open && (
        <Modal
          handleClose={() => {
            setOpen(false);
          }}
          {...props}
        />
      )}
    </>
  );
};

export default Raport;
