import { useMemo } from 'react';

const formatNumber = (value) => {
  return String(value).replace('.', ',');
};

const useCsvData = (allPrices, users) => {
  return useMemo(() => {
    if (allPrices && users) {
      const csvData = [];

      users.forEach((u, index) => {
        const price = allPrices[index];

        csvData.push([
          '01-01-2022',
          u.id,
          u.names || '',
          formatNumber(price.area),
          formatNumber(price.gardenFee),
          formatNumber(price.participation),
          formatNumber(price.membershipFee),
          formatNumber(price.renovationFund),
          '',
          '',
          formatNumber(price.total),
        ]);
      });

      return csvData;
    } else {
      return null;
    }
  }, [allPrices, users]);
};

export default useCsvData;
