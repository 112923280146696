import Page from '../../Page';
import Form from '../Form';

const Add = () => {
  return (
    <Page back="/news" title="Dodawanie newsa" breadcrumbs={[['/news', 'News']]}>
      <Form />
    </Page>
  );
};

export default Add;
