import Page from '../../Page';
import { changePin } from '../../httpClient/account';
import { REQUIRED, TOO_SHORT } from '../../utils/formTexts';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import 'yup-phone';

const schema = yup
  .object({
    password: yup.string().min(3, TOO_SHORT).required(REQUIRED),
    newPin: yup
      .string()
      .matches(/^[0-9]{4}$/, 'Podaj 4 cyfry')
      .required(REQUIRED),
    rePin: yup
      .string()
      .oneOf([yup.ref('newPin'), null], 'Piny się różnią')
      .required(REQUIRED),
  })
  .required();

const Pin = () => {
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      await changePin(data);

      toast.success('Dane zostały zapisane');
    } catch (e) {
      toast.error('Nie udało się');
    }
  };

  return (
    <Page title={`Zmiana PINu`} back="/account" breadcrumbs={[['/account', 'Moje konto']]}>
      <div className="rod-form">
        <div className="rod-form-content">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="rod-form-row">
              <label>Aktualne hasło *</label>
              <input type="password" {...register('password')} />
              {errors.password && <div className="rod-form-error">{errors.password?.message}</div>}
            </div>
            <div className="rod-form-row">
              <label>Nowy PIN *</label>
              <input type="password" {...register('newPin')} />
              {errors.newPin && <div className="rod-form-error">{errors.newPin?.message}</div>}
            </div>
            <div className="rod-form-row">
              <label>Powtórz nowy PIN *</label>
              <input type="password" {...register('rePin')} />
              {errors.rePin && <div className="rod-form-error">{errors.rePin?.message}</div>}
            </div>

            <div className="rod-form-legend">
              <ul>
                <li>* - pole wymagane</li>
              </ul>
            </div>

            <div className="rod-form-submit">
              <input type="submit" className="button-primary" value="Zapisz" />
            </div>
          </form>
        </div>
      </div>
    </Page>
  );
};

export default Pin;
