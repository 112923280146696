import instance from './instance';

export const add = (data) => {
  return instance.post('users/add', data);
};

export const list = () => {
  return instance.get('users/list');
};

export const remove = (id) => {
  return instance.delete(`users/remove/${id}`);
};

export const edit = (id, data) => {
  return instance.put(`users/edit/${id}`, data);
};

export const details = (id) => {
  return instance.get(`users/details/${id}`);
};

export const withMobilePhone = (id) => {
  return instance.get(`users/withMobilePhone`);
};
