import Number from '../../../Number';
import styles from './List.module.scss';
import cn from 'classnames';

const Indicator = ({ total, payment, secondary }) => {
  let delta = 0;
  let triedPayment = false;

  const percent = Math.min(1, (payment || 0) / total) * 100;

  if (payment) {
    triedPayment = true;
    delta = payment - total;
  }

  const isCorrect = !isNaN(total);

  return (
    <div className={styles.payments}>
      {isCorrect && (
        <>
          <div className={styles.total}>
            <Number value={total} /> zł
          </div>

          <div className={styles.indicator}>
            <div className={styles.progress}>
              <div className={cn(secondary ? styles.water : styles.garden)} style={{ width: `${percent}%` }} />
            </div>
            <div className={styles.percent}>
              <Number value={percent} />%
            </div>
          </div>

          {triedPayment && Math.abs(delta) > 0.01 && (
            <div className={cn(styles.delta, delta > 0 && styles.plus)}>
              <Number value={delta} /> zł
            </div>
          )}
        </>
      )}

      {!isCorrect && <div className={styles.incorrectData}>Niepoprawne dane odczytu</div>}
    </div>
  );
};

export default Indicator;
