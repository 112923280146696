import Modal from '../GardenModal';
import styles from './Map.module.scss';
import areas from './coords';
import mapUrl from './map.svg';
import cn from 'classnames';
import { MapPinned, ZoomIn, ZoomOut } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';

const sizes = [1713, 2806];

const Map = () => {
  const divRef = useRef();

  const [bbox, setBbox] = useState();
  const [scaleExponent, setScaleExponent] = useState(1);
  const [selectedGarden, setSelectedGarden] = useState(undefined);
  const [point, setPoint] = useState(undefined);

  useEffect(() => {
    if (divRef.current) {
      const bbox = divRef.current.getBoundingClientRect();

      const widthBbox = Math.max(Math.floor(bbox.width), 320);

      const width = Math.min(Math.max(Math.floor(bbox.width), 320), 500);

      const prop = sizes[1] / sizes[0];
      const heightBbox = Math.min(Math.max(window.innerHeight - 270, 380), Math.floor(widthBbox * prop));

      const scale = (heightBbox / sizes[1]) * 0.98 * (window.innerWidth < 500 ? 2 : 1);

      const scaleExponent = Math.floor(Math.log2(scale));

      setScaleExponent(scaleExponent);

      setBbox([widthBbox, heightBbox, width]);
    }
  }, []);

  const scale = useMemo(() => Math.pow(2, scaleExponent), [scaleExponent]);

  const centerOfMass = useMemo(() => {
    const p = areas.find((a) => a.id === point);

    if (p) {
      const coords = p.path.split(',');
      let x = 0;
      let y = 0;
      const size = coords.length / 2;

      for (let i = 0; i < coords.length; i += 2) {
        x += parseFloat(coords[i]);
        y += parseFloat(coords[i + 1]);
      }

      return [x / size, y / size];
    }

    return undefined;
  }, [point]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          <MapPinned size={24} color="white" /> Mapa
        </div>

        <div
          className={cn(styles.box, 'S')}
          ref={divRef}
          style={
            bbox
              ? {
                  width: bbox[0],
                  height: bbox[1],
                  display: bbox[2] > sizes[0] * scale ? 'flex' : 'block',
                }
              : {}
          }
        >
          {bbox && (
            <div
              style={{
                width: sizes[0] * scale,
                height: sizes[1] * scale,
              }}
              className={styles.map}
            >
              <div
                style={{
                  width: sizes[0],
                  height: sizes[1],
                  transform: `scale(${scale})`,
                  transformOrigin: '0 0',
                }}
                onClick={() => {
                  setPoint(undefined);
                }}
              >
                <img src={mapUrl} alt="Workplace" useMap="#workmap" width={sizes[0]} height={sizes[1]} className={styles.cover} />

                <map name="workmap" className={styles.map}>
                  {areas.map((area) => {
                    return (
                      <area
                        key={area.id}
                        shape="poly"
                        coords={area.path}
                        alt={`Dzialka nr ${area.id}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();

                          setSelectedGarden(area.id);
                          setPoint(area.id);
                        }}
                        href={`/garden/${area.id}`}
                      />
                    );
                  })}
                </map>

                {centerOfMass && (
                  <div
                    className={styles.point}
                    style={{
                      left: centerOfMass[0],
                      top: centerOfMass[1],
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>

        <div className={styles.controls}>
          <button
            onClick={() => {
              setScaleExponent((e) => e + 0.5);
            }}
          >
            <ZoomIn size={20} />
          </button>
          <button
            onClick={() => {
              setScaleExponent((e) => e - 0.5);
            }}
          >
            <ZoomOut size={20} />
          </button>
        </div>
      </div>

      {selectedGarden && (
        <Modal
          forceBig={true}
          id={selectedGarden}
          handleClose={() => {
            setSelectedGarden(undefined);
          }}
        />
      )}
    </>
  );
};

export default Map;
