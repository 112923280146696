import { markAsMain, remove } from '../../../httpClient/bankAccounts';
import styles from '../../Bank.module.scss';
import Modal from '../../Modal';
import addSvg from '../../assets/add.svg';
import Confirm from '../../utils/Confirm';
import Options from '../../utils/Options';
import AddOrEdit from './AddOrEdit';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

const AccountsList = () => {
  const [add, setAdd] = useState();
  const [editId, setEditId] = useState();
  const [removeId, setRemoveId] = useState();
  const [mainId, setMainId] = useState();

  const { bankAccountsList } = useOutletContext();

  return (
    <>
      <div className={styles.menu}>
        <button
          onClick={() => {
            setAdd(true);
          }}
        >
          <div className={styles.icon}>
            <img src={addSvg} alt="Menu item" />
          </div>
          <div className={styles.title}>Dodaj</div>
        </button>
      </div>

      <div className={styles.clients}>
        {(bankAccountsList.data || []).map((l) => (
          <div className={styles.client} key={l.id}>
            <div className={styles.details}>
              <div className={styles.name}>{l.name}</div>
              <div className={styles.uuid}>
                {l.isMain && <span>(Konto główne)</span>}

                {l.number}
              </div>
            </div>
            <div className={styles.actions}>
              <Options>
                <button
                  onClick={() => {
                    setMainId(l.id);
                  }}
                >
                  Ustaw jako główne
                </button>

                <button
                  onClick={() => {
                    setEditId(l.id);
                  }}
                >
                  Edytuj
                </button>

                <button
                  onClick={() => {
                    setRemoveId(l.id);
                  }}
                >
                  Usuń
                </button>
              </Options>
            </div>
          </div>
        ))}
      </div>

      {add && (
        <Modal onClose={setAdd}>
          <AddOrEdit onClose={setAdd} refresh={bankAccountsList.refresh} />
        </Modal>
      )}

      {editId && (
        <Modal onClose={setEditId}>
          <AddOrEdit id={editId} onClose={setEditId} refresh={bankAccountsList.refresh} />
        </Modal>
      )}

      {removeId && (
        <Modal onClose={setRemoveId}>
          <Confirm warning={true} id={removeId} onClose={setRemoveId} refresh={bankAccountsList.refresh} action={remove} />
        </Modal>
      )}

      {mainId && (
        <Modal onClose={setMainId}>
          <Confirm
            title="Czy na pewno chcesz ustawić jako konto główne?"
            id={mainId}
            onClose={setMainId}
            refresh={bankAccountsList.refresh}
            action={markAsMain}
          />
        </Modal>
      )}
    </>
  );
};

export default AccountsList;
