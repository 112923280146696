/* eslint-disable  react-hooks/exhaustive-deps */
import Again from '../Again';
import Loading from '../Loading';
import Pin from '../Pin';
import Redirect from '../Redirect';
import Router from '../Router';
import useAuth from '../utils/useAuth';
import { Toaster } from 'react-hot-toast';

const App = () => {
  const { stage } = useAuth();

  return (
    <>
      {stage === -1 && <Redirect />}
      {stage === 0 && <Loading />}
      {stage === 1 && <Pin />}
      {stage === 2 && <Router />}
      {stage === 3 && <Again />}

      <Toaster
        toastOptions={{
          style: {
            background: '#1d1d1d',
            color: '#fff',
          },
        }}
        containerClassName="toster"
      />
    </>
  );
};

export default App;
