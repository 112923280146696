import { add, edit } from '../../httpClient/users';
import { NOT_EMAIL, NOT_INTEGER_NUMBER, NOT_NUMBER, NOT_PHONE, NOT_POSITIVE_NUMBER, NOT_POSTCODE, REQUIRED } from '../../utils/formTexts';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import 'yup-phone';

const phoneValidator = yup.lazy((value) => {
  if (!value || value.toString().length === 0) {
    return yup.string();
  }

  return yup.string().phone('PL', false, NOT_PHONE);
});

const schema = yup
  .object({
    gardenId: yup.number().typeError(NOT_NUMBER).positive(NOT_POSITIVE_NUMBER).integer(NOT_INTEGER_NUMBER).required(REQUIRED),
    area: yup.number().typeError(NOT_NUMBER).positive(NOT_POSITIVE_NUMBER).required(REQUIRED),
    waterMeter: yup.boolean().required(REQUIRED),
    names: yup.string().required(REQUIRED),
    street: yup.string(),
    building: yup.string(),
    flat: yup.string(),
    postcode: yup.string().matches(/^(\d{2}-\d{3})?$/, NOT_POSTCODE),
    city: yup.string(),
    rodo: yup.boolean(),
    useEmailAsDefault: yup.boolean(),
    phone: phoneValidator,
    email: yup.string().email(NOT_EMAIL),
    hasTwoOwners: yup.boolean().required(REQUIRED),
    subOwnerNames: yup.string().when('hasTwoOwners', {
      is: true,
      then: yup.string().required(REQUIRED),
    }),
    subOwnerPhone: phoneValidator,
    subOwnerEmail: yup.string().email(NOT_EMAIL),
    subOwnerRodo: yup.boolean(),
    subOwnerUseEmailAsDefault: yup.boolean(),
  })
  .required();

const Form = ({ isEdit = false, defaultValues = undefined }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValues,
      hasTwoOwners: Boolean(defaultValues?.hasTwoOwners),
    },
  });

  const hasTwoOwners = Boolean(watch('hasTwoOwners'));

  const onSubmit = async (data) => {
    try {
      if (isEdit) {
        await edit(data.gardenId, data);

        toast.success('Dane zostały zapisane');
      } else {
        await add(data);

        toast.success('Działkowiec dodany');
      }
    } catch (e) {
      toast.error('Nie udało się');
    }
  };

  return (
    <div className="rod-form">
      <div className="rod-form-content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="rod-form-row">
            <label>Numer działki *</label>
            <input disabled={isEdit} type="text" {...register('gardenId')} />
            {errors.gardenId && <div className="rod-form-error">{errors.gardenId?.message}</div>}
          </div>
          <div className="rod-form-row">
            <label>
              Powierzchnia działki (M<sup>3</sup>)*
            </label>
            <input type="text" {...register('area')} />
            {errors.area && <div className="rod-form-error">{errors.area?.message}</div>}
          </div>
          <div className="rod-form-row">
            <label>Wodomierz *</label>
            <select {...register('waterMeter')}>
              <option value={true}>tak</option>
              <option value={false}>nie</option>
            </select>
            {errors.waterMeter && <div className="rod-form-error">{errors.waterMeter?.message}</div>}
          </div>

          <div className="rod-form-row">
            <label>Ulica</label>
            <input type="text" {...register('street')} />
            {errors.street && <div className="rod-form-error">{errors.street?.message}</div>}
          </div>
          <div className="rod-form-two-rows">
            <div className="rod-form-row">
              <label>Numer budynku</label>
              <input type="text" {...register('building')} />
              {errors.building && <div className="rod-form-error">{errors.building?.message}</div>}
            </div>
            <div className="rod-form-row">
              <label>Numer mieszkania</label>
              <input type="text" {...register('flat')} />
              {errors.flat && <div className="rod-form-error">{errors.flat?.message}</div>}
            </div>
          </div>
          <div className="rod-form-two-rows">
            <div className="rod-form-row">
              <label>Kod pocztowy</label>
              <input type="text" {...register('postcode')} />
              {errors.postcode && <div className="rod-form-error">{errors.postcode?.message}</div>}
            </div>
            <div className="rod-form-row">
              <label>Miejscowość</label>
              <input type="text" {...register('city')} />
              {errors.city && <div className="rod-form-error">{errors.city?.message}</div>}
            </div>
          </div>

          <div className="rod-form-row">
            <label>Liczba właścicieli *</label>
            <select {...register('hasTwoOwners')}>
              <option value={false}>1</option>
              <option value={true}>2</option>
            </select>
            {errors.hasTwoOwners && <div className="rod-form-error">{errors.hasTwoOwners?.message}</div>}
          </div>

          <div className="rod-form-fieldset">
            <label className="rod-form-fieldset-label">{hasTwoOwners ? 'Pierwszy ' : ''}Właściciel</label>

            <div className="rod-form-row">
              <label>Imię i nazwisko *</label>
              <input type="text" {...register('names')} />
              {errors.names && <div className="rod-form-error">{errors.names?.message}</div>}
            </div>
            <div className="rod-form-row">
              <label>Telefon</label>
              <input type="text" {...register('phone')} />
              {errors.phone && <div className="rod-form-error">{errors.phone?.message}</div>}
            </div>
            <div className="rod-form-row">
              <label>Adres e-mail</label>
              <input type="text" {...register('email')} />
              {errors.email && <div className="rod-form-error">{errors.email?.message}</div>}
            </div>

            <div className="rod-form-row-checkbox">
              <div className="rod-form-row-checkbox-line">
                <div className="rod-form-checkbox-wrapper">
                  <input type="checkbox" className="ikxBAC" {...register('rodo')} />
                </div>
                <span>Wypełniony formularz RODO</span>
              </div>
              {errors.rodo && <div className="rod-form-error">{errors.rodo?.message}</div>}
            </div>

            <div className="rod-form-row-checkbox">
              <div className="rod-form-row-checkbox-line">
                <div className="rod-form-checkbox-wrapper">
                  <input type="checkbox" className="ikxBAC" {...register('useEmailAsDefault')} />
                </div>
                <span>Kontakt przez pocztę elektroniczną</span>
              </div>
              {errors.useEmailAsDefault && <div className="rod-form-error">{errors.useEmailAsDefault?.message}</div>}
            </div>
          </div>

          {hasTwoOwners && (
            <div className="rod-form-fieldset" style={{ marginTop: 30 }}>
              <label className="rod-form-fieldset-label">Drugi Właściciel</label>

              <div className="rod-form-row">
                <label>Imię i nazwisko</label>
                <input type="text" {...register('subOwnerNames')} />
                {errors.subOwnerNames && <div className="rod-form-error">{errors.subOwnerNames?.message}</div>}
              </div>
              <div className="rod-form-row">
                <label>Telefon</label>
                <input type="text" {...register('subOwnerPhone')} />
                {errors.subOwnerPhone && <div className="rod-form-error">{errors.subOwnerPhone?.message}</div>}
              </div>
              <div className="rod-form-row">
                <label>Adres e-mail</label>
                <input type="text" {...register('subOwnerEmail')} />
                {errors.subOwnerEmail && <div className="rod-form-error">{errors.subOwnerEmail?.message}</div>}
              </div>

              <div className="rod-form-row-checkbox">
                <div className="rod-form-row-checkbox-line">
                  <div className="rod-form-checkbox-wrapper">
                    <input type="checkbox" className="ikxBAC" {...register('subOwnerRodo')} />
                  </div>
                  <span>Wypełniony formularz RODO</span>
                </div>
                {errors.subOwnerRodo && <div className="rod-form-error">{errors.subOwnerRodo?.message}</div>}
              </div>

              <div className="rod-form-row-checkbox">
                <div className="rod-form-row-checkbox-line">
                  <div className="rod-form-checkbox-wrapper">
                    <input type="checkbox" className="ikxBAC" {...register('subOwnerUseEmailAsDefault')} />
                  </div>
                  <span>Kontakt przez pocztę elektroniczną</span>
                </div>
                {errors.subOwnerUseEmailAsDefault && <div className="rod-form-error">{errors.subOwnerUseEmailAsDefault?.message}</div>}
              </div>
            </div>
          )}

          <div className="rod-form-legend">
            <ul>
              <li>* - pole wymagane</li>
            </ul>
          </div>

          <div className="rod-form-submit">
            <input type="submit" className="button-primary" value="Zapisz" />
            {isEdit && (
              <Link to={`/users/remove/${defaultValues.gardenId}`} className="button-secondary">
                Usuń
              </Link>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
