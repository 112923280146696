import Page from '../../Page';
import positions from '../../assets/positions';
import { edit } from '../../httpClient/account';
import { LoggedAccount } from '../../store/account';
import { NOT_EMAIL, NOT_NUMBER, NOT_PHONE, REQUIRED } from '../../utils/formTexts';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useRecoilState } from 'recoil';
import * as yup from 'yup';
import 'yup-phone';

const schema = yup
  .object({
    name: yup.string().required(REQUIRED),
    phone: yup.string().phone('PL', false, NOT_PHONE).required(REQUIRED),
    contactEmail: yup.string().email(NOT_EMAIL).required(REQUIRED),
    position: yup.number().typeError(NOT_NUMBER).required(REQUIRED),
  })
  .required();

const Edit = () => {
  const [account, setAccount] = useRecoilState(LoggedAccount);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: account,
  });

  const onSubmit = async (data) => {
    try {
      await edit(data);

      toast.success('Dane zostały zapisane');
      setAccount({
        ...account,
        ...data,
      });
    } catch (e) {
      toast.error('Nie udało się');
    }
  };

  return (
    <Page title={`Edycja konta`} back="/account" breadcrumbs={[['/account', 'Moje konto']]}>
      <div className="rod-form">
        <div className="rod-form-content">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="rod-form-row">
              <label>Imie i nazwisko *</label>
              <input type="text" {...register('name')} />
              {errors.name && <div className="rod-form-error">{errors.name?.message}</div>}
            </div>
            <div className="rod-form-row">
              <label>Email do kontaktu *</label>
              <input type="text" {...register('contactEmail')} />
              {errors.contactEmail && <div className="rod-form-error">{errors.contactEmail?.message}</div>}
            </div>
            <div className="rod-form-row">
              <label>Telefon *</label>
              <input type="text" {...register('phone')} />
              {errors.phone && <div className="rod-form-error">{errors.phone?.message}</div>}
            </div>
            <div className="rod-form-row">
              <label>Pałniona funkcja *</label>
              <select {...register('position')}>
                {positions.map((position, index) => {
                  return (
                    <option key={index} value={index}>
                      {position}
                    </option>
                  );
                })}
              </select>
              {errors.position && <div className="rod-form-error">{errors.position?.message}</div>}
            </div>

            <div className="rod-form-legend">
              <ul>
                <li>* - pole wymagane</li>
              </ul>
            </div>

            <div className="rod-form-submit">
              <input type="submit" className="button-primary" value="Zapisz" />
            </div>
          </form>
        </div>
      </div>
    </Page>
  );
};

export default Edit;
