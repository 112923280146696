import styles from './List.module.scss';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import { Phone } from 'lucide-react';

const makePhoneUri = (p) => {
  const number = parsePhoneNumber(p, 'PL');

  return {
    name: new AsYouType('PL').input(number.number),
    uri: number.getURI(),
  };
};

const CallTo = ({ user }) => {
  const phones = [user.phone, user.subOwnerPhone].map(makePhoneUri);
  const names = [user.names, user.subOwnerNames];

  return (
    <div className={styles.callToWrapper}>
      {phones.map((p, index) => (
        <a href={p.uri} className={styles.callTo}>
          <Phone size={24} />
          <div>
            <div className={styles.name}>{names[index]}</div>
            <div>{phones[index].name}</div>
          </div>
        </a>
      ))}
    </div>
  );
};

export default CallTo;
