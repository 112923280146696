import { add } from '../../httpClient/files';
import { directories } from '../../httpClient/files';
import { REQUIRED } from '../../utils/formTexts';
import styles from './Form.module.scss';
import { slugify } from './helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';

const schema = yup
  .object({
    name: yup.string().required(REQUIRED),
    directory: yup.string().required(REQUIRED),
    file: yup.mixed().test('file', REQUIRED, (value) => {
      if (value.length > 0) {
        return true;
      }
      return false;
    }),
  })
  .required();

const Form = ({ isEdit = false, defaultValues = undefined }) => {
  const [directoriesList, setDirectoriesList] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const name = watch('name');
  const file = watch('file');

  const slug = useMemo(() => {
    if (name && name.length > 0) {
      return `${slugify(name)}`;
    }

    return '';
  }, [name]);

  useEffect(() => {
    const get = async () => {
      try {
        const response = await directories();

        setDirectoriesList(response.data);
      } catch (e) {
        toast.error('Nie można pobrać danych');
      }
    };

    get();
  }, []);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append('file', data.file[0]);
      formData.append('name', slug);
      formData.append('directory', data.directory);

      await add(formData);

      toast.success('Dokument dodany');
    } catch (e) {
      toast.error('Nie udało się');
    }
  };

  const selectedFileName = file && file.length === 1 && file[0].name;

  return (
    <div className="rod-form">
      <div className="rod-form-content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="rod-form-row">
            <label>Nazwa opisowa dokumentu *</label>
            <input type="text" {...register('name')} />
            <div className={styles.potentialName}>Slug: {slug}</div>
            {errors.name && <div className="rod-form-error">{errors.name?.message}</div>}
          </div>

          <div className="rod-form-row">
            <label>Katalog *</label>

            <select {...register('directory')}>
              <option value="">Wybierz katalog</option>

              {directoriesList.map((v) => (
                <option key={v} value={v}>
                  /{v}
                </option>
              ))}
            </select>

            {errors.directory && <div className="rod-form-error">{errors.directory?.message}</div>}
          </div>

          <div className={styles.fileWrapper}>
            <label>{selectedFileName || 'Wybierz plik'}</label>
            <input type="file" {...register('file')} accept="application/pdf, image/jpeg, image/png" />
            {errors.file && <div className="rod-form-error">{errors.file?.message}</div>}
          </div>

          <div className="rod-form-legend">
            <ul>
              <li>* - pole wymagane</li>
            </ul>
          </div>

          <div className="rod-form-submit">
            <input type="submit" className="button-primary" value="Zapisz" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
