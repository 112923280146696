import { details } from '../../../httpClient/users';
import PdfDocument from './PdfDocument';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { QRCodeCanvas } from 'qrcode.react';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';

const Modal = ({ handleClose, data, settings, prices }) => {
  const [show, setShow] = useState(false);
  const [qrUrl, setQrUrl] = useState();
  const divRef = useRef();
  const [userDetails, setUserDetails] = useState();

  const year = new Date().getFullYear();

  useEffect(() => {
    if (divRef.current) {
      const canvas = divRef.current.childNodes[0];
      const dataURL = canvas.toDataURL();

      setQrUrl(dataURL);
    }

    const getUserData = async () => {
      try {
        const response = await details(data.userId);

        setUserDetails({
          ...response.data,
          waterMeter: Boolean(response.data.waterMeter),
        });
      } catch (e) {
        toast.error('Nie udało się pobrać danych');
      }
    };

    getUserData();
  }, [data.userId]);

  useEffect(() => {
    if (userDetails) {
      setTimeout(() => {
        setShow(true);
      }, 200);
    }
  }, [userDetails]);

  const Component = userDetails ? (
    <PdfDocument qrUrl={qrUrl} data={data} settings={settings} prices={prices} year={year} userDetails={userDetails} />
  ) : null;

  return (
    <div className="rod-modal">
      <div className="rod-modal-hidden">
        <div ref={divRef}>
          <QRCodeCanvas value={`Sygnatura ${year}/${data.userId}`} />
        </div>

        <PDFViewer width={1000} height={700}>
          {Component}
        </PDFViewer>
      </div>

      <div className="rod-modal-container">
        {!show && <div className="rod-modal-loading">Generowanie PDF...</div>}

        {show && (
          <div>
            <div>
              <PDFDownloadLink className="button-primary rod-modal-button" document={Component} fileName={`${year}-${data.userId}.pdf`}>
                {({ blob, url, loading, error }) => (loading ? 'Tworzenie łącza...' : 'Pobierz PDF')}
              </PDFDownloadLink>
            </div>
            <div className="rod-modal-close">
              <button onClick={handleClose}>Zamknij okno</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Bill = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className="rod-table-button-special"
        onClick={() => {
          setOpen(true);
        }}
      >
        Generuj rachunek
      </button>

      {open && (
        <Modal
          handleClose={() => {
            setOpen(false);
          }}
          {...props}
        />
      )}
    </>
  );
};

export default Bill;
