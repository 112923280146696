import Menu from '../Menu';
import Header from './Header';
import styles from './Page.module.scss';
import useScroll from './helpers/useScroll';
import cn from 'classnames';
import { useState } from 'react';

const IS_BROWSER = false;

const Page = ({ menu, navigation, title, children, back, plain = false, breadcrumbs = [], noBreadcrumbs = false }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const headerRef = useScroll();

  return (
    <div className={styles.page}>
      <Header back={back} setOpenMenu={setOpenMenu} title={title} noBreadcrumbs={noBreadcrumbs} breadcrumbs={breadcrumbs} />

      <Header
        headerRef={headerRef}
        back={back}
        setOpenMenu={setOpenMenu}
        title={title}
        noBreadcrumbs={noBreadcrumbs}
        breadcrumbs={breadcrumbs}
      />

      {navigation && <div className={styles.navigation}>{navigation}</div>}

      {!plain && (
        <div className={styles.columns}>
          <div className={cn(styles.content, IS_BROWSER && styles.isBrowser)}>{children}</div>

          {menu && (
            <div className={styles.menu}>
              <div className={styles.actions}>{menu}</div>
            </div>
          )}
        </div>
      )}

      {plain && children}

      {openMenu && (
        <Menu
          hideMenu={() => {
            setOpenMenu(false);
          }}
        />
      )}
    </div>
  );
};

export default Page;
