import styles from './Again.module.scss';

export const PRODUCTION_URL = 'https://login.rod1maja.szczecin.pl?portal=admin';
export const DEVELOPMENT_URL = 'http://localhost:5174?portal=admin&callbackUrl=http://localhost:3000';

const Again = () => {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <h1>Wylogowano</h1>
        <a href={process.env.NODE_ENV === 'production' ? PRODUCTION_URL : DEVELOPMENT_URL}>Zaloguj ponownie</a>
      </div>
    </div>
  );
};

export default Again;
