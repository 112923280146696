import Page from '../Page';
import { getSummary } from '../httpClient/bank';
import { list as getBankAccountsList } from '../httpClient/bankAccounts';
import { list as getClientsList } from '../httpClient/clients';
import styles from './Bank.module.scss';
import accountSvg from './assets/account.svg';
import clientSvg from './assets/client.svg';
import historySvg from './assets/history.svg';
import useList from './utils/useList';
import cn from 'classnames';
import { Link, Outlet, useMatches } from 'react-router-dom';

const links = [
  {
    key: 'history',
    name: 'Historia',
    counterText: 'Liczba danych',
    icon: historySvg,
  },
  {
    key: 'clients',
    name: 'Kontrahenci',
    counterText: 'Liczba kontrahentów',
    icon: clientSvg,
  },
  {
    key: 'accounts',
    name: 'Konta',
    counterText: 'Liczba kont',
    icon: accountSvg,
  },
];

const Bank = () => {
  const matches = useMatches();
  const selectedKey = matches[matches.length - 1]?.handle?.key;

  const summary = useList(getSummary);

  const bankAccountsList = useList(getBankAccountsList);
  const clientsList = useList(getClientsList);

  return (
    <Page
      menu={
        <>
          {summary.data &&
            links.map((l) => (
              <Link key={l.key} to={l.key} className={cn(styles.button, l.key === selectedKey && styles.selected)}>
                <div className={styles.icon}>
                  <img src={l.icon} alt={l.key} />
                </div>
                <div>
                  <div className={styles.title}>{l.name}</div>
                  <div className={styles.info}>
                    {l.counterText}: {summary.data[l.key]}
                  </div>
                </div>
              </Link>
            ))}
        </>
      }
      title="Bank"
    >
      <Outlet context={{ bankAccountsList, clientsList }} />
    </Page>
  );
};

export default Bank;
