import Page from '../../Page';
import { details } from '../../httpClient/payments';
import Form from '../Form';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

const replaceNullByUndefined = (objRef) => {
  const obj = { ...objRef };

  for (const [key, value] of Object.entries(obj)) {
    if (value === null) {
      obj[key] = undefined;
    }
  }

  return obj;
};

const Edit = () => {
  const { id } = useParams();
  const [defaultValues, setDefaultValues] = useState();

  useEffect(() => {
    const getData = async () => {
      if (id) {
        try {
          const response = await details(id);

          setDefaultValues(
            replaceNullByUndefined({
              ...response.data,
              time: new Date(response.data.time),
            })
          );
        } catch (e) {
          toast.error('Nie udało się pobrać danych');
        }
      }
    };

    getData();
  }, [id]);

  return (
    <Page back="/payments" title="Edycja płatności" breadcrumbs={[['/payments', 'Kasa']]}>
      {defaultValues && <Form isEdit={true} defaultValues={defaultValues} id={id} />}
    </Page>
  );
};

export default Edit;
