import Page from '../../Page';
import { entry } from '../../httpClient/checklists';
import Form from './Form';
import { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

const Entry = () => {
  const { id } = useParams();
  const [defaultValues, setDefaultValues] = useState();
  const [title, setTitle] = useState('Edycja wpisu do check-listy');
  const [name, setName] = useState('Moja lista');

  useEffect(() => {
    const getData = async () => {
      if (id) {
        try {
          const response = await entry(id);

          setDefaultValues({
            ...response.data.entry,
          });

          setTitle(`Edycja wpisu dla listy ${response.data.list.name} dla działki nr ${response.data.entry.gardenId}`);
          setName(response.data.list.name);
        } catch (e) {
          toast.error('Nie udało się pobrać danych');
        }
      }
    };

    getData();
  }, [id]);

  const backLink = `/checklists/entries/${(defaultValues || {}).checklistId}`;

  return (
    <Page
      back={backLink}
      title={title}
      breadcrumbs={[
        [`/checklists`, 'Check-listy'],
        [backLink, name],
      ]}
    >
      {defaultValues && <Form isEdit={true} defaultValues={defaultValues} />}
    </Page>
  );
};

export default Entry;
