import Page from '../../Page';
import { list } from '../../httpClient/checklists';
import styles from './List.module.scss';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const List = () => {
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    const get = async () => {
      try {
        const response = await list();

        setDataList(response.data);
      } catch (e) {
        toast.error('Nie można pobrać danych');
      }
    };

    get();
  }, []);

  return (
    <Page
      title="Check-listy"
      menu={
        <Link to="add" className="button-primary">
          Dodaj listę
        </Link>
      }
    >
      <div className={styles.list}>
        <div className="rod-count">Ilość elementów: {dataList.length}</div>

        <table className="rod-table">
          <tbody>
            {dataList.map((item) => {
              return (
                <tr key={item.id}>
                  <td>
                    <Link to={`entries/${item.id}`}>{item.name}</Link>
                  </td>
                  <td className="rod-table-right">
                    <Link to={`edit/${item.id}`} className="rod-table-button-primary">
                      Edytuj
                    </Link>
                    <Link to={`remove/${item.id}`} className="rod-table-button-secondary">
                      Usuń
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Page>
  );
};

export default List;
