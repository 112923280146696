import Page from '../../Page';
import Form from '../Form';

const Add = () => {
  return (
    <Page back="/users" title="Dodawanie nowego działkowicza" breadcrumbs={[['/users', 'Działkowcy']]}>
      <Form />
    </Page>
  );
};

export default Add;
