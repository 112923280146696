import styles from './Page.module.scss';
import cn from 'classnames';
import { ChevronLeft, Menu } from 'lucide-react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Header = ({ headerRef, back, setOpenMenu, title, noBreadcrumbs, breadcrumbs }) => {
  return (
    <div ref={headerRef} className={cn(styles.headerWrapper, headerRef && styles.fixed)}>
      {back && (
        <Link to={back} className={styles.mainButton}>
          <ChevronLeft size={30} color="#1a1a1a" />
        </Link>
      )}

      {!back && (
        <div
          className={styles.mainButton}
          onClick={() => {
            setOpenMenu(true);
          }}
        >
          <Menu size={30} color="#1a1a1a" />
        </div>
      )}

      <div className={styles.header}>
        <div className={styles.title}>{title}</div>

        {noBreadcrumbs && (
          <div className={styles.breadcrumbs}>
            <Link to="/">w Szczecinie</Link>
          </div>
        )}

        {!noBreadcrumbs && (
          <div className={styles.breadcrumbs}>
            <Link to="/">ROD im. 1 Maja</Link>
            {breadcrumbs.map((breadcrumb, index) => (
              <Fragment key={index}>
                <span>/</span>
                <Link to={breadcrumb[0]}>{breadcrumb[1]}</Link>
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
