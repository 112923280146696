/* eslint-disable react-hooks/exhaustive-deps */
import styles from '../Bank.module.scss';
import menuSvg from '../assets/menu.svg';
import { useCallback, useEffect, useState } from 'react';

const prevent = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const Menu = ({ setOpen, children }) => {
  const listener = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('click', listener, false);
    }, 0);

    return () => {
      window.removeEventListener('click', listener, false);
    };
  }, []);

  return (
    <div className={styles.options} onClick={prevent}>
      {children}
    </div>
  );
};

const Options = ({ children }) => {
  const [open, setOpen] = useState();

  return (
    <>
      <button className={styles.optionButton}>
        <img
          src={menuSvg}
          alt="Options"
          onClick={(e) => {
            setOpen(true);
          }}
        />
      </button>

      {open && <Menu setOpen={setOpen} children={children} />}
    </>
  );
};

export default Options;
