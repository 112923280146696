/* eslint-disable react-hooks/exhaustive-deps */
import LoraBlackFont from '../../assets/Lora-Bold.ttf';
import LoraFont from '../../assets/Lora-Regular.ttf';
import logoUrl from '../../fees/assets/logofull.png';
import { list } from '../../httpClient/users';
import { UsersListState } from '../../store/users';
import codeUrl from './assets/barcode.png';
import polakUrl from './assets/polak.png';
import smolarekUrl from './assets/smolarek.png';
import { Document, Font, Image, PDFDownloadLink, PDFViewer, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';

// Register Font
Font.register({
  family: 'Lora',
  fonts: [
    { src: LoraFont }, // font-style: normal, font-weight: normal
    { src: LoraBlackFont, fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 9,
    fontFamily: 'Lora',
  },
  header: {
    borderBottom: '3px solid #292929',
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'row',
  },
  dotted: {
    borderBottom: '1px dotted #292929',
  },
  title: { padding: 5, alignItems: 'center' },
  gosc: {
    fontSize: 13,
    fontWeight: 700,
    justifyContent: 'center',
    paddingTop: 10,
    flexDirection: 'row',
  },
  zapro: {},
  wiersz: {
    padding: '2 0',
  },
  wiersze: {
    alignItems: 'center',
    padding: '10 0',
  },
  bold: {
    fontWeight: 700,
  },
  remeber: {
    paddingTop: 10,
    fontWeight: 700,
  },
  details: {
    paddingTop: 5,
    paddingBottom: 10,
  },
  detail: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 15,
    paddingBottom: 3,
    paddingTop: 3,
  },
  dot: {
    width: 4,
    height: 4,
    backgroundColor: 'black',
    borderRadius: 100,
    marginRight: 5,
  },
  info: {
    fontSize: 8,
    textAlign: 'justify',
  },
  materialy: {
    paddingTop: 5,
    textAlign: 'center',
  },
  porzadek: {
    fontWeight: 700,
    paddingTop: 20,
    paddingBottom: 10,
  },
  porzadekWiersz: {
    fontSize: 7,
    textAlign: 'justify',
    paddingBottom: 1,
    paddingTop: 1,
  },
  uchwalyWiersz: {
    fontSize: 7,
    textAlign: 'justify',
    paddingBottom: 1,
    paddingTop: 1,
    paddingLeft: 20,
  },
  podpisy: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  podpisItem: {
    marginHorizontal: 30,
    alignItems: 'center',
  },
  kropki: {
    marginTop: -13,
  },
  podpisName: {
    fontSize: '7',
  },
});

const proportion = ([w, h], height) => {
  return {
    height,
    width: (w / h) * height,
  };
};

const details = [
  'Zgodnie z § 62 ust.1 Statutu PZD uchwały walnych zebrań odbytych w drugim terminie, co najmniej pół godziny po wyznaczonej godzinie rozpoczęcia w pierwszym terminie, są ważne bez względu na liczbę członków zwyczajnych PZD obecnych na tym zebraniu;',
  'Zgodnie z § 56 ust 2 Statutu PZD prawo udziału w walnych zebraniach ma wyłącznie członek zwyczajny PZD w danym ROD i wykluczone jest zastępstwo lub działanie przez pełnomocników;',
  'Każdy członek PZD zaproszony na walne zebranie musi mieć ze sobą dowód tożsamości, który może być sprawdzony przy podpisywaniu listy obecności;',
];

const porzadek = [
  'Otwarcie zebrania.',
  'Wybór Przewodniczącego i Prezydium zebrania. Wyznaczenie protokolanta.',
  'Zatwierdzenie regulaminu zebrania.',
  'Zatwierdzenie porządku obrad.',
  'Wybór Komisji Mandatowej, Komisji Uchwał i Wniosków oraz Komisji Wyborczej.',
  'Sprawozdanie Zarządu ROD z działalności za 2023 r. (merytoryczne i finansowe) oraz za kadencję.',
  'Sprawozdanie Komisji Rewizyjnej ROD za 2023 r. i za kadencję.',
  'Ocena działalności Zarządu ROD za 2023 r. i za kadencję przez Komisję Rewizyjną ROD wraz z wnioskami.',
  'Sprawozdanie Komisji Mandatowej.',
  'Projekt programu działania na kadencję 2024-2028 i projekt planu pracy na 2024 r.',
  'Projekty opłat ogrodowych na 2024 r.',
  'Projekt preliminarza finansowego ROD na 2024 r. i ocena przez Komisję Rewizyjną ROD.',
  'Dyskusja.',
  'Sprawozdanie Komisji Uchwał i Wniosków, głosowania nad uchwałami w sprawach:',
  'Ustalenie składów liczbowych Zarządu ROD i Komisji Rewizyjnej ROD.',
  'Sprawozdanie Komisji Wyborczej przedstawienie kandydatów do organów ROD i na delegatów na Okręgowy Zjazd Delegatów PZD.',
  'Wybory członków Zarządu ROD, Komisji Rewizyjnej PZD i delegatów na Okręgowy Zjazd Delegatów. Ogłoszenie wyniku wyborów.',
  'Przerwa w obradach. Pierwsze posiedzenia organów ROD.',
  'Sprawy różne.',
  'Zakończenie obrad.',
];

const uchwaly = [
  'Zatwierdzenia sprawozdania z działalności Zarządu ROD za 2023 r. i za okres kadencji,',
  'Zatwierdzenia sprawozdania finansowego za 2023 r.,',
  'Udzielenia absolutorium ustępującemu zarządowi ROD,',
  'Zatwierdzenia sprawozdania z działalności Komisji Rewizyjnej ROD za 2023 r. i za okres kadencji,',
  'Uchwalenia programu działania na kadencję i planu pracy na 2024 r.,',
  'Ustalenia kwoty przeznaczonej na świadczenia pieniężne i nagrody wypłacane z tytułu pracy społecznej w ROD,',
  'Uchwalenia opłat ogrodowych i terminu ich wnoszenia w 2024 r.,',
  'Uchwalenie realizacji zadania inwestycyjnego (remontowego), w tym partycypacji finansowej działkowców,',
  'Uchwalenia innych uchwał dotyczących działalności ogrodu,',
  'Uchwalenia preliminarza finansowego na 2024 r.',
];

const ShowRow = ({ index }) => (
  <Text style={styles.porzadekWiersz}>
    {index + 1}. {porzadek[index]}
  </Text>
);

const ShowChwaly = ({ index }) => (
  <Text style={styles.uchwalyWiersz}>
    {index + 1}
    {')'} {uchwaly[index]}
  </Text>
);

const ShowPodpis = ({ name, url, size, imie }) => (
  <View style={styles.podpisItem}>
    <View>
      <Image src={url} style={{ ...proportion(size, 30) }} />
    </View>
    <Text style={styles.kropki}>.......................................................</Text>
    <Text style={styles.podpisName}>
      {imie} - {name} Zarządu
    </Text>
  </View>
);

const MyHtml = ({ data }) => {
  const user = data[0];

  return (
    <Document>
      <Page style={styles.body} wrap={true} size="A4">
        <View style={styles.header}>
          <View style={{ width: '50%' }}>
            <Image src={logoUrl} style={{ ...proportion([647, 307], 50), marginTop: 12 }} />
          </View>
          <View style={{ width: '50%', textAlign: 'right' }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                marginTop: 10,
                marginRight: -4,
              }}
            >
              <Image src={codeUrl} style={{ ...proportion([315, 87], 36) }} />
            </View>
          </View>
        </View>

        <View style={[styles.dotted]}>
          <View style={styles.gosc}>
            <Text>Sz. P. {user.showName}</Text>
          </View>

          <View style={styles.wiersze}>
            <Text style={[styles.wiersz, styles.bold]}>
              Zarząd ROD im. 1 Maja w Szczecinie zaprasza na Walne Zebranie Sprawozdawczo-Wyborcze,
            </Text>
            <Text style={styles.wiersz}>
              które odbędzie się w <Text style={[styles.bold]}>4 kwietnia 2024</Text> r. w Szkole Podstawowej nr 11, przy ul. Emilii Plater
              20, 71-763 Szczecin.
            </Text>
            <Text style={styles.wiersz}>
              I termin obrad: <Text style={[styles.bold]}>16:30</Text>; II termin obrad: <Text style={[styles.bold]}>17:00</Text>
            </Text>
          </View>
        </View>

        <View style={styles.remeber}>
          <Text>Przypominamy:</Text>
        </View>

        <View style={[styles.details, styles.dotted]}>
          {details.map((d) => (
            <View key={d} style={styles.detail}>
              <View style={styles.dot}></View>
              <Text style={styles.info}>{d}</Text>
            </View>
          ))}
        </View>

        <View>
          <Text style={styles.materialy}>
            <Text style={[styles.bold]}>Materiały sprawozdawcze</Text> dostępne są do wglądu w biurze Zarządu w terminach:
          </Text>

          <Text style={styles.materialy}>
            I termin: <Text style={[styles.bold]}>23.03.2024, 12:00- 14:00</Text>; II termin{' '}
            <Text style={[styles.bold]}>26.03.2024, 12:00 - 14:00</Text>
          </Text>
        </View>

        <View style={styles.porzadek}>
          <Text>Porządek Obrad Walnego Zebrania:</Text>
        </View>

        <View>
          <ShowRow index={0}></ShowRow>
          <ShowRow index={1}></ShowRow>
          <ShowRow index={2}></ShowRow>
          <ShowRow index={3}></ShowRow>
          <ShowRow index={4}></ShowRow>
          <ShowRow index={5}></ShowRow>
          <ShowRow index={6}></ShowRow>
          <ShowRow index={7}></ShowRow>
          <ShowRow index={8}></ShowRow>
          <ShowRow index={9}></ShowRow>
          <ShowRow index={10}></ShowRow>
          <ShowRow index={11}></ShowRow>
          <ShowRow index={12}></ShowRow>
          <ShowRow index={13}></ShowRow>

          <ShowChwaly index={0} />
          <ShowChwaly index={1} />
          <ShowChwaly index={2} />
          <ShowChwaly index={3} />
          <ShowChwaly index={4} />
          <ShowChwaly index={5} />
          <ShowChwaly index={6} />
          <ShowChwaly index={7} />
          <ShowChwaly index={8} />
          <ShowChwaly index={9} />

          <ShowRow index={14}></ShowRow>
          <ShowRow index={15}></ShowRow>
          <ShowRow index={16}></ShowRow>
          <ShowRow index={17}></ShowRow>
          <ShowRow index={18}></ShowRow>
          <ShowRow index={19}></ShowRow>
        </View>

        <View style={styles.podpisy}>
          <ShowPodpis imie="Joanna Smolarek" name="Członek" url={smolarekUrl} size={[381, 316]} />
          <ShowPodpis imie="Iwona Polak" name="Prezes" url={polakUrl} size={[669, 336]} />
        </View>
      </Page>
    </Document>
  );
};

const Modal = ({ handleClose }) => {
  const [users, setUsers] = useRecoilState(UsersListState);
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await list();

        setUsers(
          response.data.map((u) => ({
            ...u,
            showName: [u.names, u.subOwnerNames].filter(Boolean).join(', '),
          }))
        );
      } catch (e) {
        handleClose();
      }
    };

    getData();
  }, [setUsers]);

  const data = useMemo(() => {
    if (!users || users.length < 1) {
      return null;
    }

    const arr = [users[selectedUser]];

    return arr;
  }, [users, selectedUser]);

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        setShow(true);
      }, 2000);
    }
  }, [data]);

  return (
    <div className="rod-modal">
      {data && (
        <div className="rod-modal-hidden ">
          <PDFViewer>
            <MyHtml data={data} />
          </PDFViewer>
        </div>
      )}
      <div className="rod-modal-container">
        {!show && <div className="rod-modal-loading">Generowanie PDF...</div>}

        {show && data && (
          <div>
            <div>
              <div className="rod-form-row">
                <select
                  onChange={(e) => {
                    setSelectedUser(e.target.value);
                  }}
                >
                  {users &&
                    users.map((u, index) => (
                      <option key={index} value={index}>
                        {u.id}. {u.showName}
                      </option>
                    ))}
                </select>
              </div>

              <PDFDownloadLink
                className="button-primary rod-modal-button"
                document={<MyHtml data={data} />}
                fileName={`invitation-${data[0].id}.pdf`}
              >
                {({ blob, url, loading, error }) => (loading ? 'Tworzenie łącza...' : 'Pobierz PDF')}
              </PDFDownloadLink>
            </div>
            <div className="rod-modal-close">
              <button onClick={handleClose}>Zamknij okno</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Invitation = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className="rod-table-button-special"
        onClick={() => {
          setOpen(true);
        }}
      >
        PDF
      </button>

      {open && (
        <Modal
          handleClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Invitation;
