import instance from './instance';

const TIMEOUT = 1000 * 30;

export const list = () => {
  return instance.get(`files/list`);
};

export const remove = (id) => {
  return instance.delete(`files/delete?file=${id}`);
};

export const add = (data) => {
  return instance.post('/files/add', data, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
    timeout: TIMEOUT,
  });
};

export const directories = () => {
  return instance.get(`files/directories`);
};
