/* eslint-disable react-hooks/exhaustive-deps */
import font from '../../assets/Roboto.ttf';
import { list } from '../../httpClient/users';
import { UsersListState } from '../../store/users';
import { Document, Font, PDFDownloadLink, PDFViewer, Page, Text } from '@react-pdf/renderer';
import { Fragment, useEffect, useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import Html from 'react-pdf-html';
import { useRecoilState } from 'recoil';

function createGroups(arr, perGroup) {
  const numGroups = Math.ceil(arr.length / perGroup);
  return new Array(numGroups).fill('').map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup));
}

// Register Font
Font.register({
  family: 'Roboto',
  format: 'truetype',
  src: font,
});

const element = (data) => (
  <html>
    <body>
      <style>
        {`
body {
    padding: 0;
    font-family: Roboto;
}

table {
    width: 100%;
    font-size: 10px;
    padding: 0;
}

td {
    padding: 15px 15px;
    margin: 0;
    height: 102px;
}

td:first-child {
  padding-left: 5px;
}
        `}
      </style>
      <table cellPadding={0} cellSpacing={0}>
        <tbody>
          {data.map((row, index) => {
            return (
              <tr key={index}>
                {row.map((item, indexRow) => (
                  <td key={indexRow}>
                    {item && (
                      <>
                        {item.names || `Działka ${item.id}`}
                        <br />
                        ul. {item.street || ''} {item.building || ''} {item.flat ? `/ ${item.flat}` : ''}
                        <br />
                        {item.postcode} {item.city}
                      </>
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </body>
  </html>
);

const MyHtml = ({ data }) => {
  const rows = createGroups(data, 8);

  return (
    <Document>
      <Page>
        {rows.map((row, index) => (
          <Fragment key={index}>
            <Html>{ReactDOMServer.renderToStaticMarkup(element(row))}</Html>
            {index < rows.length - 1 && <Text break />}
          </Fragment>
        ))}
      </Page>
    </Document>
  );
};

const Modal = ({ handleClose }) => {
  const [users, setUsers] = useRecoilState(UsersListState);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await list();

        setUsers(response.data);
      } catch (e) {
        handleClose();
      }
    };

    getData();
  }, [setUsers]);

  const data = useMemo(() => {
    if (!users) {
      return [];
    }

    const arr = [];

    for (let i = 0; i < users.length; i++) {
      if (i % 3 === 0) {
        arr.push([]);
      }

      arr[arr.length - 1].push(users[i]);
    }

    let k = users.length % 3;

    if (k > 0) {
      for (; k < 3; k++) {
        arr[arr.length - 1].push(null);
      }
    }

    return arr;
  }, [users]);

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        setShow(true);
      }, 2000);
    }
  }, [data]);

  return (
    <div className="rod-modal">
      {data && (
        <div className="rod-modal-hidden ">
          <PDFViewer>
            <MyHtml data={data} />
          </PDFViewer>
        </div>
      )}

      <div className="rod-modal-container">
        {!show && <div className="rod-modal-loading">Generowanie PDF...</div>}

        {show && (
          <div>
            <div>
              <PDFDownloadLink className="button-primary rod-modal-button" document={<MyHtml data={data} />} fileName="labels.pdf">
                {({ blob, url, loading, error }) => (loading ? 'Tworzenie łącza...' : 'Pobierz PDF')}
              </PDFDownloadLink>
            </div>
            <div className="rod-modal-close">
              <button onClick={handleClose}>Zamknij okno</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Label = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className="rod-table-button-special"
        onClick={() => {
          setOpen(true);
        }}
      >
        PDF
      </button>

      {open && (
        <Modal
          handleClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Label;
