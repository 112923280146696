const mergePayments = (data) => {
  const dictionary = {};

  data.forEach((row) => {
    const gardenId = row.gardenId;

    if (!gardenId) {
      return;
    }

    if (!dictionary[gardenId]) {
      dictionary[gardenId] = [];
    }

    dictionary[gardenId].push(row);
  });

  const keys = Object.keys(dictionary);

  keys.forEach((key) => {
    dictionary[key] = dictionary[key].reduce((acc, curr) => acc + curr.value, 0);
  });

  return dictionary;
};

export default mergePayments;
