import Number from '../../Number';
import Page from '../../Page';
import Select from '../../Select';
import { list } from '../../httpClient/payments';
import Raport from '../Raport';
import styles from './List.module.scss';
import { format } from 'date-fns';
import { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const types = ['Bieżący - podmiot gosp.', 'Rach. Dobry Zysk dla Firmy'];

const paymentTypes = ['', 'Opłata ogrodowa', 'Opłata inwestycyjna - prąd', 'Media'];

const List = () => {
  const [data, setData] = useState();
  const [year, setYear] = useState(localStorage.getItem('payments-year') || 2024);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await list(year);

        setData(response.data);
      } catch (e) {
        toast.error('Nie można pobrać danych');
      }
    };

    getData();
  }, [setData, year]);

  return (
    <>
      <Page
        menu={
          <Link to="add" className="button-primary">
            Dodaj płatność
          </Link>
        }
        title="Kasa"
      >
        <Select
          onChange={(v) => {
            setData([]);

            localStorage.setItem('payments-year', v);

            setYear(v);
          }}
          value={year}
          from={2021}
        />

        <div className="rod-count">Ilość elementów: {data?.length}</div>

        <div className={styles.list}>
          {data?.map((row, index) => {
            return (
              <Fragment key={index}>
                {((index > 0 && data[index].time !== data[index - 1].time) || index === 0) && (
                  <div className={styles.headerDate}>{format(new Date(row.time), 'dd.MM.yyyy')}</div>
                )}

                <div className={styles.payment}>
                  <div className={styles.header}>
                    <div className={styles.top}>
                      <div className={styles.user}>
                        <Link to={`edit/${row.id}`}>
                          {row.names ? `${row.names} (dz. ${row.gardenId})` : row.gardenId === 0 ? 'Zarząd ROD' : '-'}
                        </Link>
                      </div>
                    </div>
                    <div className={styles.value}>
                      <Number value={row.value * row.inOut} />
                    </div>
                  </div>
                  <div>
                    <div className={styles.ref}>{row.refNr}</div>
                    <div className={styles.account}>
                      {types[row.accountId]} / {paymentTypes[row.paymentType]}
                    </div>
                    {row.description && <div className={styles.desc}>{row.description}</div>}
                  </div>
                </div>
              </Fragment>
            );
          })}
        </div>
      </Page>

      <div className={styles.generate}>
        <Raport data={data} />
      </div>
    </>
  );
};

export default List;
