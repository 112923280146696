/* eslint-disable react-hooks/exhaustive-deps */
import font from '../../assets/Roboto.ttf';
import logoUrl from '../../fees/assets/logofull.png';
import { list } from '../../httpClient/users';
import { UsersListState } from '../../store/users';
import { Document, Font, Image, PDFDownloadLink, PDFViewer, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';

// Register Font
Font.register({
  family: 'Roboto',
  format: 'truetype',
  src: font,
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  return: {
    marginTop: 20,
    paddingLeft: 160,
  },
  bold: {
    fontWeight: 700,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 11,
    justifyContent: 'flex-end',
    marginTop: 100,
  },
  address: {
    width: 200,
  },
});

const proportion = ([w, h], height) => {
  return {
    height,
    width: (w / h) * height,
  };
};

const MyHtml = ({ data }) => {
  const user = data[0];

  const name = user.names;
  const names = name.split(',').map((f) => f.trim());

  return (
    <Document>
      <Page style={styles.body} wrap={true} orientation="landscape" size="LETTER">
        <View style={styles.logo}>
          <Image src={logoUrl} style={{ ...proportion([647, 307], 50), marginTop: 80, marginLeft: 160 }} />
        </View>

        <View style={styles.return}>
          <Text style={[styles.bold]}>ROD im. 1 Maja</Text>
          <Text>Skr. pocztowa nr 56</Text>
          <Text>ul. Stanisława Dubois 9</Text>
          <Text>71-611 Szczecin</Text>
          <Text>UP Szczecin 8</Text>
        </View>

        <View style={styles.header}>
          <View style={styles.address}>
            {names.map((n) => (
              <Text key={n} style={[styles.bold]}>
                {n}
              </Text>
            ))}

            <Text>
              ul. {user.street} {user.building}
              {user.flat ? `/${user.flat}` : ''}
            </Text>
            <Text>
              {user.postcode} {user.city}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const Modal = ({ handleClose }) => {
  const [users, setUsers] = useRecoilState(UsersListState);
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await list();

        setUsers(response.data);
      } catch (e) {
        handleClose();
      }
    };

    getData();
  }, [setUsers]);

  const data = useMemo(() => {
    if (!users || users.length < 1) {
      return null;
    }

    const arr = [users[selectedUser]];

    return arr;
  }, [users, selectedUser]);

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        setShow(true);
      }, 2000);
    }
  }, [data]);

  return (
    <div className="rod-modal">
      {data && (
        <div className="rod-modal-hidden ">
          <PDFViewer>
            <MyHtml data={data} />
          </PDFViewer>
        </div>
      )}
      <div className="rod-modal-container">
        {!show && <div className="rod-modal-loading">Generowanie PDF...</div>}

        {show && data && (
          <div>
            <div>
              <div className="rod-form-row">
                <select
                  onChange={(e) => {
                    setSelectedUser(e.target.value);
                  }}
                >
                  {users &&
                    users.map((u, index) => (
                      <option key={index} value={index}>
                        {u.names}
                      </option>
                    ))}
                </select>
              </div>

              <PDFDownloadLink
                className="button-primary rod-modal-button"
                document={<MyHtml data={data} />}
                fileName={`envelope-${data[0].id}.pdf`}
              >
                {({ blob, url, loading, error }) => (loading ? 'Tworzenie łącza...' : 'Pobierz PDF')}
              </PDFDownloadLink>
            </div>
            <div className="rod-modal-close">
              <button onClick={handleClose}>Zamknij okno</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Envelope = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className="rod-table-button-special"
        onClick={() => {
          setOpen(true);
        }}
      >
        PDF
      </button>

      {open && (
        <Modal
          handleClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Envelope;
