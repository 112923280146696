import Page from '../../Page';
import Removing from '../../Removing';
import { remove } from '../../httpClient/news';
import { useParams } from 'react-router-dom';

const Remove = () => {
  const { id } = useParams();

  return (
    <Page back="/news" breadcrumbs={[['/news', 'Newsy']]} title="Usuwanie newsa">
      <Removing url="/news" title="Czy na pewno chcesz usunąć newsa?" action={() => remove(id)} />
    </Page>
  );
};

export default Remove;
