import Page from '../../Page';
import { details } from '../../httpClient/users';
import replaceNullByUndefined from '../../utils/replaceNullByUndefined';
import Form from '../Form';
import { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

const Edit = () => {
  const { id } = useParams();
  const [defaultValues, setDefaultValues] = useState();

  useEffect(() => {
    const getData = async () => {
      if (id) {
        try {
          const response = await details(id);

          setDefaultValues(
            replaceNullByUndefined({
              ...response.data,
              gardenId: response.data.id,
              waterMeter: Boolean(response.data.waterMeter),
            })
          );
        } catch (e) {
          toast.error('Nie udało się pobrać danych');
        }
      }
    };

    getData();
  }, [id]);

  return (
    <Page back="/users" title="Edycja profilu działkowca" breadcrumbs={[['/users', 'Działkowcy']]}>
      {defaultValues && <Form isEdit={true} defaultValues={defaultValues} />}
    </Page>
  );
};

export default Edit;
