export const REQUIRED = 'Pole wymagane';
export const NOT_NUMBER = 'Pole musi być liczbą';
export const NOT_POSITIVE_NUMBER = 'Wartość musi być dodatnia';
export const NOT_INTEGER_NUMBER = 'Wartość musi być całkowita';
export const NOT_EMAIL = 'Pole musi być poprawym adresem e-mail';
export const NOT_PHONE = 'Pole musi być poprawym numerem telefonu';
export const NOT_DATE = 'Wartość w formacie mm/dd/yyyy';
export const TOO_BIG = 'Wartość jest za duża';
export const TOO_SHORT = 'Wartość jest za krótka';
export const NOT_RE_PASSWORD = 'Hasła się różnią';
export const NOT_POSTCODE = 'Pole musi byc w formacie XX-XXX';
