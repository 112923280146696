import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  body: {
    paddingTop: 115,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 9,
    fontFamily: 'Roboto',
  },

  header: {
    position: 'absolute',
    top: 15,
    left: 35,
    right: 35,
    borderBottom: '3px solid #292929',
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'row',
  },

  headerChild: {},
  dotted: {
    borderBottom: '1px dotted #292929',
  },
  bold: {
    fontWeight: 700,
  },
  cell: {
    fontSize: 7,
  },
  table: {},
  mono: {
    fontFamily: 'RobotoMono',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '3px',
  },

  greetings: {
    padding: '15px 0',
    borderBottom: '3px solid #292929',
    textAlign: 'center',
    fontSize: 12,
  },

  pageNumber: {
    position: 'absolute',
    borderTop: '3px solid #292929',
    bottom: 30,
    left: 35,
    right: 35,
  },
  pageNumberWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 4,
  },
  pageNumberRight: {
    textAlign: 'right',
    fontSize: 7,
    fontFamily: 'Roboto',
  },
  pageNumberLeft: {
    fontSize: 7,
    fontFamily: 'Roboto',
  },
  pageNumberLefDate: {
    fontSize: 5,
    fontFamily: 'Roboto',
  },
  payment: {
    backgroundColor: '#292929',
    width: '170px',
    margin: '10px 0',
    padding: '3px 10px',
    color: 'white',
    fontWeight: 700,
    fontSize: 13,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    padding: '10px 0',
    marginTop: -13,
  },
  more: {
    marginBottom: 3,
    fontSize: 8,
  },
  waterHeader: {
    marginBottom: 3,
    width: 88,
    backgroundColor: '#f1f1f1',
    padding: '1px 3px',
  },
  watermark: {
    position: 'absolute',
    top: 20,
    left: 0,
    right: 0,
    bottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
