import { useMemo } from 'react';

const useCsvData = (users) => {
  return useMemo(() => {
    if (users) {
      const csvData = [];

      users.forEach((u) => {
        csvData.push([u.phone || '', `Dzialka nr ${u.id}`, u.names || '', u.names || '', u.email || '']);

        if (Boolean(u.hasTwoOwners)) {
          csvData.push([u.subOwnerPhone || '', `Dzialka nr ${u.id}`, u.subOwnerNames || '', u.subOwnerNames || '', u.subOwnerEmail || '']);
        }
      });

      return csvData;
    } else {
      return null;
    }
  }, [users]);
};

export default useCsvData;
